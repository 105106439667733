import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import CharacterView2Page from './character-v2-page.jsx';
import { useState } from 'react';

const CharacterView2 = ({ View2CharacterData, fontSize, fontStyle, handleBookmark, jumpToPage, darkSide, open }) => {

    const [currentIndex, setCurrentIndex] = useState(0);
    const [progress, setProgress] = useState(0);
    const totalSlides = View2CharacterData.length;

    const handleOnChange = (index) => {
        setCurrentIndex(index);
        setProgress((index / (totalSlides - 1)) * 100);
    };

    return (
        <div>

            <div>
                <Carousel className='mb-0'
                    onChange={handleOnChange}
                    showIndicators={false}
                    showStatus={false}
                    renderArrowPrev={(clickHandler, hasPrev) => {
                        return (
                            <div
                                className={`${hasPrev ? "absolute" : "hidden"
                                    } top-0 bottom-0 left-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                                onClick={clickHandler}
                            >
                                <img className="w-3 h-3" src={darkSide ? "white_leftarrow.svg" : "leftarrow.svg"} />
                            </div>
                        );
                    }}
                    renderArrowNext={(clickHandler, hasNext) => {
                        return (
                            <div
                                className={`${hasNext ? "absolute" : "hidden"
                                    } top-0 bottom-0 right-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-20`}
                                onClick={clickHandler}
                            >
                                <img className="w-3 h-3" src={darkSide ? "white_rightarrow.svg" : "rightarrow.svg"} />
                            </div>
                        );
                    }}>
                    {
                        View2CharacterData.map((View2CharacterData, index) => {
                            return (
                                <div className='grid grid-cols-1 lg:h-[86vh] xl:h-[87.1vh]'>
                                    {/* <Page data={data} content={data.content.substring(0, data.content.length / 2)} chno={data.chapter_number} fontSize={fontSize} fontStyle={fontStyle}
                                        handleBookmark={handleBookmark} jumpToPage={jumpToPage} />
                                        <Page data={data}
                                        pg={data.page_number} chno={data.chapter_number} content={data.content.substring(data.content.length / 2, data.content.length)} fontSize={fontSize}
                                        fontStyle={fontStyle} handleBookmark={handleBookmark} jumpToPage={jumpToPage} /> */}

                                    <CharacterView2Page key={index} View2CharacterData={View2CharacterData} pg={View2CharacterData.page_number} left_content={View2CharacterData.left_content} right_content={View2CharacterData.right_content} chno={View2CharacterData.chapter_number} fontSize={fontSize} fontStyle={fontStyle}
                                        handleBookmark={handleBookmark} jumpToPage={jumpToPage} darkSide={darkSide} open={open} />
                                </div>
                            )
                        }
                        )
                    }

                </Carousel>

                <div className="w-full h-1 bg-[#CBCAC7] rounded mt-[-20px] mb-[20px] dark:bg-[#303030]">
                    <div className="h-1 bg-[#242424] rounded dark:bg-[#FAF8F1]" style={{ width: `${progress}%` }} />
                </div>

            </div>

        </div>
    );
};


export default CharacterView2;