import React from 'react';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { Link } from 'react-router-dom';

const MNavbar = ({ toggleExploreDrawer }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  return (
    <div
      className='mb-8 flex items-center justify-between sticky top-0 z-[102] bg-[#FAF8F1] border-[#e6e6e6] border-[1px] px-8 py-2 lg:mb-12'
      style={{ backgroundImage: "url('main-bg-img.svg')" }}
    >
      <div>
        <Link to='/'>
          <img className='h-[50px] w-[50px]' src='chimp-logo.png' alt='Chimpers' />
        </Link>
      </div>

      <div className='hidden flex items-center lg:flex'>
        <div className='mr-5'>
          <button className='flex items-center' type='button' onClick={handleClick}>
            {/* <p className='mr-2 font-dm_mono font-medium text-[14px] text-[#242424] hover:underline transition duration-1000'>EXPLORE</p> */}
            <p className='relative group'>
              <span className='mr-2 font-dm_mono font-medium text-[14px] text-[#242424]'>EXPLORE</span>
              <span className='absolute -bottom-1 left-0 w-0 h-[1px] bg-[#242424] group-hover:w-full group-hover:transition-all group-hover:ease-linear group-hover:duration-150'></span>
            </p>
            {/* <span class="block max-w-0 group-hover:max-w-full transition-all duration-500 h-0.5 bg-sky-600"></span> */}
            {/* <span className="absolute inset-0 bg-gray-900 transform scale-x-0 origin-left transition ease-out duration-300 hover:scale-x-100"></span> */}
            <img src='dropdown.svg' />
          </button>

          <Popper id={id} open={open} anchorEl={anchorEl}>
            <Box className='grid grid-cols-2 gap-5 bg-[#FAF8F1] border-[1px] border-[#242424] p-7 mt-8 mr-5'>
              <a href='https://chimpers.com/news' target='_blank' rel='noreferrer'>
                <button className='flex w-[325px] bg-white border-[1px] border-[#cbcbcb] text-left rounded-md overflow-hidden hover:border-[#242424] focus:border-[#242424]'>
                  <img className='h-[85px] p-3 mt-1' src='news.webp' />
                  <div className='pl-5 h-full py-6 border-l-[1px] border-[#cbcbcb] hover:border-l-[1px] hover:border-[#242424] focus:border-l-[1px] focus:border-[#242424]'>
                    <p className='font-dm_mono font-semibold text-[14px] text-[#242424] mb-1'>NEWS</p>
                    <p className='pr-4 font-dm_mono font-normal text-[12px] text-[#77777c] leading-4'>
                      Find out the latest Chimpers News
                    </p>
                  </div>
                </button>
              </a>
              <a href='https://marketplace.chimpers.com/' target='_blank' rel='noreferrer'>
                <button className='flex w-[325px] bg-white border-[1px] border-[#cbcbcb] text-left rounded-md overflow-hidden hover:border-[#242424] focus:border-[#242424]'>
                  <img className='h-[85px] p-3 mt-1' src='marketplace.webp' />
                  <div className='pl-5 h-full py-6 border-l-[1px] border-[#cbcbcb] hover:border-l-[1px] hover:border-[#242424] focus:border-l-[1px] focus:border-[#242424]'>
                    <p className='font-dm_mono font-semibold text-[14px] text-[#242424] mb-1'>MARKETPLACE</p>
                    <p className='pr-4 font-dm_mono font-normal text-[12px] text-[#77777c] leading-4'>
                      Shop the Chimpers Collections
                    </p>
                  </div>
                </button>
              </a>
              <a href='https://dojo.chimpers.xyz/' target='_blank' rel='noreferrer'>
                <button className='flex w-[325px] bg-white border-[1px] border-[#cbcbcb] text-left rounded-md overflow-hidden hover:border-[#242424] focus:border-[#242424]'>
                  <img className='h-[85px] p-3 mt-1' src='dojo.webp' />
                  <div className='pl-5 h-full py-6 border-l-[1px] border-[#cbcbcb] hover:border-l-[1px] hover:border-[#242424] focus:border-l-[1px] focus:border-[#242424]'>
                    <p className='font-dm_mono font-semibold text-[14px] text-[#242424] mb-1'>DOJO</p>
                    <p className='pr-4 font-dm_mono font-normal text-[12px] text-[#77777c] leading-4'>
                      Head back to the Chimpers Dojo!
                    </p>
                  </div>
                </button>
              </a>
              <a href='https://chimpers.com/arcade' target='_blank' rel='noreferrer'>
                <button className='flex w-[325px] bg-white border-[1px] border-[#cbcbcb] text-left rounded-md overflow-hidden hover:border-[#242424] focus:border-[#242424]'>
                  <img className='h-[85px] p-3 mt-1' src='arcade.webp' />
                  <div className='pl-5 h-full py-6 border-l-[1px] border-[#cbcbcb] hover:border-l-[1px] hover:border-[#242424] focus:border-l-[1px] focus:border-[#242424]'>
                    <p className='font-dm_mono font-semibold text-[14px] text-[#242424] mb-1'>GAMES</p>
                    <p className='pr-4 font-dm_mono font-normal text-[12px] text-[#77777c] leading-4'>
                      Play the latest Chimpers Arcade Games
                    </p>
                  </div>
                </button>
              </a>
              <a href='https://chimpers.myshopify.com' target='_blank' rel='noreferrer'>
                <button className='flex w-[325px] bg-white border-[1px] border-[#cbcbcb] text-left rounded-md overflow-hidden hover:border-[#242424] focus:border-[#242424]'>
                  <img className='h-[85px] p-3 mt-1' src='shop.webp' />
                  <div className='pl-5 h-full py-6 border-l-[1px] border-[#cbcbcb] hover:border-l-[1px] hover:border-[#242424] focus:border-l-[1px] focus:border-[#242424]'>
                    <p className='font-dm_mono font-semibold text-[14px] text-[#242424] mb-1'>SHOP</p>
                    <p className='pr-4 font-dm_mono font-normal text-[12px] text-[#77777c] leading-4'>
                      Chimpers Shop to elevate your chimpsthetic game
                    </p>
                  </div>
                </button>
              </a>
              <a href='https://chimpers.com/community' target='_blank' rel='noreferrer'>
                <button className='flex w-[325px] bg-white border-[1px] border-[#cbcbcb] text-left rounded-md overflow-hidden hover:border-[#242424] focus:border-[#242424]'>
                  <img className='h-[85px] p-3 mt-1' src='community.webp' />
                  <div className='pl-5 h-full py-6 border-l-[1px] border-[#cbcbcb] hover:border-l-[1px] hover:border-[#242424] focus:border-l-[1px] focus:border-[#242424]'>
                    <p className='font-dm_mono font-semibold text-[14px] text-[#242424] mb-1'>COMMUNITY</p>
                    <p className='pr-4 font-dm_mono font-normal text-[12px] text-[#77777c] leading-4'>
                      Find info about our vibrant community, events & artists
                    </p>
                  </div>
                </button>
              </a>
            </Box>
          </Popper>

          {/* {open && (
            <div
                style={{
                position: "fixed",
                backgroundColor: "black",
                opacity: 0.3,
                top: 80,
                left: 0,
                right: 0,
                bottom: 0,
                }}
            />
            )} */}
        </div>

        <a href='https://opensea.io/collection/chimpersnft' target='_blank' rel='noreferrer'>
          <button className='hover:-translate-y-0.5 transition-all duration-150 ease-linear'>
            <img src='opensea.svg' />
          </button>
        </a>
        <a href='https://discord.com/invite/chimpers' target='_blank' rel='noreferrer'>
          <button className='hover:-translate-y-0.5 transition-all duration-150 ease-linear'>
            <img className='ml-4' src='discord.svg' />
          </button>
        </a>
        <a href='https://twitter.com/ChimpersNFT' target='_blank' rel='noreferrer'>
          <button className='hover:-translate-y-0.5 transition-all duration-150 ease-linear'>
            <img className='ml-4' src='twitter.svg' />
          </button>
        </a>
      </div>

      <button onClick={toggleExploreDrawer} className='lg:hidden'>
        <img src='9-dots.svg' />
      </button>
    </div>
  );
};

export default MNavbar;
