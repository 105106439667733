import React from 'react'

const Preview = ({ fontSize, mobFontSize, fontStyle }) => {
    
    return (
        <div className='bg-[#FBFBFB] border-[1px] border-[#e6e6e6] p-6 lg:p-12 lg:h-[75vh] dark:bg-[#242424]'>
            <p className='mb-2 font-dm_mono font-medium text-[15px] text-[#D6D6D6] dark:text-[#656565] lg:mb-8'>PREVIEW</p>
            <p className='hidden font-dm_mono font-medium text-[#242424] lg:block dark:text-[#FAF8F1]' style={{ fontSize, fontFamily: fontStyle }} >The apes could not believe their eyes as the mutants, once recited in folklore, now stood an arms length away, just beyond the walls, with their assertive presence beaming down. A mutant dressed in a cloak more colorful than any others and decorated with shiny ornaments bellowed from above.</p>
            <p className='font-dm_mono font-medium text-[#242424] lg:hidden dark:text-[#FAF8F1] ' style={{ fontSize: mobFontSize, fontFamily: fontStyle }} >The apes could not believe their eyes as the mutants, once recited in folklore...</p>
        </div>
    );
};

export default Preview;