import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'

const Loginside = () => {

    const [data, setData] = useState({
        name: ""
    })

    const [error, setError] = useState("")

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const url = '/api/auth'
            const { data: res } = await axios.post(url, data);
            localStorage.setItem("token", res.data);
            window.location = '/reader';
        } catch (error) {
            if (error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message)
            }

        }

    }

    return (
        <div className='flex flex-col h-full justify-between'>
            <div className='lg:pt-16 lg:ml-16'>
                <img className='hidden absolute -mt-16 -ml-12 lg:block' src="back-btn.svg" />
                <p className='hidden mb-4 text-[32px] text-[#242424] lg:block lg:mb-28'>EXPLORE CHIMPVERSE IN A NEVER BEFORE EXPERIENCE</p>

                <div>
                    <form onSubmit={handleSubmit} >
                        <div className='border-[1px] border-[#e6e6e6] bg-[#fbfbfb] grow mb-3'>
                            <input
                                type="text"
                                placeholder='username'
                                name='name'
                                onChange={handleChange}
                                value={data.name}
                                required
                                className='p-3 w-full font-dm_mono font-medium text-[16px] text-[#242424] text-center placeholder:text-center focus:outline-none'></input>
                        </div>
                
                        {error && <div className="mb-6 p-2 bg-red-400 text-white font-dm_mono font-normal text-[14px]">{error}</div>}
                        <button type="submit"
                            className='p-3 mb-1 bg-[#242424] font-dm_mono font-medium text-[15px] text-white text-center w-full'>LOGIN AS GUEST</button>
                    </form>
                </div>
            </div>

            <div className='hidden mt-20 flex items-end gap-2 md:mt-10 lg:ml-16 lg:items-start lg:flex 2xl:mb-10'>
                <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
            </div>

        </div>
    );
};

export default Loginside;