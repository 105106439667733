import React from 'react';
import MNavbar from './components/setting/main-navbar.jsx';
import GuestLoginside from './components/loginPage/guest-login'
import ExplorerDrawer from './components/mobile/explorer-drawer.jsx';

const GuestLoginPage = () => {

    const [isChapterOpen, setIsChapterOpen] = React.useState(false)

    const toggleChapterDrawer = () => {
        setIsChapterOpen((prevState) => !prevState)
    }

    return (
        <div className='bg-[#faf8f1] h-[100vh] bg-cover' style={ { backgroundImage: "url('main-bg-img.svg')" } }>
            <div className='h-[100vh] flex flex-col justify-between lg:pt-4 lg:w-[97%] lg:mx-auto'>
                <div>
                    <MNavbar toggleChapterDrawer={ toggleChapterDrawer } />
                    <ExplorerDrawer open={ isChapterOpen } onClose={ toggleChapterDrawer } />

                    {/* <Drawer open={ isChapterOpen } onClose={ toggleChapterDrawer } direction='top' size='600' className='z-[11] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'>
                        <div className='w-full h-[1px] bg-[#E0DED8] mt-16'></div>
                        <Box className="grid grid-cols-2 gap-5 bg-[#FAF8F1] px-4 py-8">
                            <div className="flex bg-white border-[1px] border-[#C8C8C8] rounded-md" >
                                <img className="h-[56px]" src="character2.svg" />
                                <div className="pl-5 pt-5 border-l-[1px] border-[#C8C8C8]" >
                                    <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >NEWS/INFO</p>

                                </div>
                            </div>
                            <div className="flex bg-white border-[1px] border-[#C8C8C8] rounded-md" >
                                <img className="h-[56px]" src="character2.svg" />
                                <div className="pl-5 pt-5 border-l-[1px] border-[#C8C8C8]" >
                                    <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >MARKETPLACE</p>

                                </div>
                            </div>
                            <div className="flex bg-white border-[1px] border-[#C8C8C8] rounded-md" >
                                <img className="h-[56px]" src="character2.svg" />
                                <div className="pl-5 pt-5 border-l-[1px] border-[#C8C8C8]" >
                                    <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >DOJO</p>

                                </div>
                            </div>
                            <div className="flex bg-white border-[1px] border-[#C8C8C8] rounded-md" >
                                <img className="h-[56px]" src="character2.svg" />
                                <div className="pl-5 pt-5 border-l-[1px] border-[#C8C8C8]" >
                                    <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >LEADERBOARDS</p>

                                </div>
                            </div>
                            <div className="flex bg-white border-[1px] border-[#C8C8C8] rounded-md" >
                                <img className="h-[56px]" src="character2.svg" />
                                <div className="pl-5 pt-5 border-l-[1px] border-[#C8C8C8]" >
                                    <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >GAMES</p>

                                </div>
                            </div>
                        </Box>
                    </Drawer> */}

                    <div className='hidden w-[96%] mx-auto grid grid-cols-2 gap-28 lg:grid'>
                        <div>
                            <GuestLoginside />
                        </div>
                        <div>
                            <img className='object-cover h-full w-full 2xl:h-[95%]' src='login-img.png' />
                        </div>
                    </div>


                    {/* mobile */ }
                    <div className='ml-3 mr-3 flex flex-col md:ml-5 md:mr-5 lg:hidden'>
                        <div>
                            <img className='object-cover h-full w-full mb-12 md:h-[60vh] lg:h-full 2xl:h-[95%]' src='login-img.png' />
                        </div>
                        <div>
                            <GuestLoginside />
                        </div>
                    </div>
                </div>

                <div className='w-[97%] mx-auto mb-6 flex items-end gap-2 md:mt-10 lg:hidden'>
                    <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
                </div>
            </div>
        </div>
    );
};

export default GuestLoginPage;