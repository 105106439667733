import React from 'react'
import Note from '../note';
import { useState, useEffect } from 'react';
import axios from 'axios'
import jwt_decode from 'jwt-decode';
import { Link } from 'react-router-dom'


const MobileNotelist = ({ notesDirectory, onNoteDelete, notes, handleAddNote, handleDeleteNote, darkSide, user }) => {

    // const [notesDirectory, setNotesDirectory] = useState([])
    const [error, setError] = useState("")
    // const [userId, setUserId] = useState('');

    // useEffect(() => {
    //     const token = window.localStorage.getItem('token');
    //     if (!token) return;

    //     try {
    //     const decodedToken = jwt_decode(token);
    //     const userId = decodedToken._id;
    //     setUserId(userId);
    //     console.log(userId);
    //     } catch (err) {
    //     console.error(err);
    //     }
    // }, []);

    // useEffect(() => {
    //     const fetchData = async () => {
    //     let tokenStr = window.localStorage.getItem('token');
    //     try {
    //         const response = await axios.get(`/api/create-note/${userId}`, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${tokenStr}`
    //         }
    //         });
    //         setNotesDirectory(response.data);
    //         console.log(notesDirectory);
    //     } catch (error) {
    //         if (error.response &&
    //         error.response.status >= 400 &&
    //         error.response.status <= 500
    //         ) {
    //         setError(error.response.data.message)
    //         }
    //     }
    //     };
    //     fetchData();
    // }, [userId])



  // const handleDelete = async (id) => {
  //   console.log(id);
  //   let tokenStr = window.localStorage.getItem('token');
  //   try {
  //     const response = await axios.delete(`/api/create-note/${id}`, {
  //       headers: {
  //         "Content-Type": "application/json",
  //         "Authorization": `Bearer ${tokenStr}`
  //       }
  //     });
  //     // setNotesDirectory(response.data);
  //     console.log(response);
  //   } catch (error) {
  //     if (error.response &&
  //       error.response.status >= 400 &&
  //       error.response.status <= 500
  //     ) {
  //       setError(error.response.data.message)
  //     }
  //   }
  //   window.location.reload();
  // };


  function handleDelete(id) {
    onNoteDelete(id);
  }


    return (
        <div className="relative flex-auto">
            <div className=''>
                {/* {notes.map((note) => <Note id={note.id} pg={note.pagenumber} text={note.text} handleDeleteNote={handleDeleteNote} darkSide={darkSide} />)} */}
                {/* <AddNote handleAddNote={handleAddNote} /> */}

                <p className='font-dm_mono font-medium text-[18px] text-[#242424] mt-2 mb-6 dark:text-[#FAF8F1]'>Notes Directory</p>

                {user ?
                <div>
                    {notesDirectory.length === 0 ? (
                    <p className='px-6 font-dm_mono font-normal text-[14px] text-[#242424] dark:text-[#FAF8F1]'>You have not taken any note yet!</p>
                    ) : (
                    <div className="relative flex-auto">
                        <div className=''>
                        {notesDirectory.map((note, i) => (
                            // <p key={note._id}>{note.note}</p>
                            <div className='p-4 rounded-lg mb-4 border-[1px] border-[#C8C9C3] lg:bg-[#EEEEEE] lg:border-none lg:rounded lg:w-[300px] lg:mb-0 whitespace-pre-wrap dark:bg-[#303030] dark:border-[#303030]'>
                            <div className='flex items-start justify-between'>
                                <div className='mb-4 bg-[#242424] px-3 py-[1px] rounded dark:bg-[#515151]'>
                                  <p className='font-dm_mono font-medium text-[11px] text-[#eeeeee] dark:text-[#FAF8F1]'>Note {i + 1}</p>
                                </div>

                                <button onClick={() => handleDelete(note._id)}>
                                <img className='cursor-pointer' src={darkSide ? "white_delete-icon.svg" : "delete.svg"} />
                                </button>
                            </div>
                              <p key={note._id} className='font-dm_mono font-normal text-[14px] text-[#242424] dark:text-[#FAF8F1]'>{note.note}</p>
                            </div>
                        ))}
                        </div>
                    </div>
                    )}
                </div>
                : 
                <div>
                    <div>
                        {notes.length === 0 ? (
                        <p className='mb-8 ml-4 font-dm_mono font-normal text-[14px] text-[#242424] dark:text-[#FAF8F1]'>You have not taken any note yet!</p>
                        ) : <div className='relative flex-auto'>
                        {notes.map((note) => <Note key={note.id} id={note.id} pg={note.pagenumber} text={note.text} handleDeleteNote={handleDeleteNote} darkSide={darkSide} />)} </div>
                        }
                    </div>
                    <p className='mt-12 font-dm_mono font-normal text-center text-[14px] text-[#242424] dark:text-[#FAF8F1]'><Link to="/login"><button className='font-bold underline'>LOGIN</button> to save your notes permanently!</Link></p>
                </div>
                }

            </div>
        </div>
    )
}

export default MobileNotelist;