import React, { useState, memo } from "react";
import { useMemo } from "react";
import Data from '../data.json';


const Search = ({ darkSide, placeholder, Data }) => {

    const [search, setSearch] = useState('');

    const onSearchChange = (event) => {
        const searchWord = event.target.value;
        setSearch(searchWord);
    };


    const onItemClick = (value) => {
        const element = document.getElementById(`main-page-${value.page_number}`);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
            setSearch('');
        }
    }

    // just for performance reasons
    const [filteredChapData, filteredConData] = useMemo(() => {
        const arr1 = search ? Data.filter((value) => {
            return value.chapter_name?.toLowerCase().includes(search?.toLowerCase());
        }) : [];
        const arr2 = search ? Data.filter((value) => {
            return value.content?.toLowerCase().includes(search?.toLowerCase());
        }) : [];
        return [arr1, arr2];
    }, [search, Data]);

    return (
        <div className="flex flex-col gap-4">
            <div className="flex items-center justify-between border-[1px] rounded-md border-[#CBCAC7] mx-8 p-3 py-4 placeholder:text-[18px] lg:p-2 lg:mx-0 lg:w-64 lg:rounded-none sticky top-4 dark:border-[#303030] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1] ease-linear transition-all duration-150">
                <input className='ml-4 font-dm_mono font-medium text-[18px] text-[#ACADB9] focus: outline-none bg-[#FAF8F1] dark:bg-[#242424] dark:placeholder-[#909090] dark:text-[#FAF8F1] lg:text-[14px]'
                    placeholder={placeholder}
                    onChange={onSearchChange}
                    value={search}
                >
                </input>
                <img className='mr-4' src={darkSide ? "dark_search.svg" : "search.svg"} alt="" />

            </div>


            {(filteredChapData.length !== 0 || filteredConData.length !== 0) && (
                <div className="h-[500px] lg:h-[375px] lg:w-[350px] lg:bg-white lg:absolute lg:top-[70px] lg:left-[55px] lg:pb-8 lg:border-[1px] lg:border-[#242424] rounded-md overflow-hidden overflow-y-auto dark:bg-[#242424] dark:border-[#515151] lg:dark:bg-[#303030] ">

                    <div className="mt-4 border-b-[1px] border-[#e6e6e6] dark:border-b-[#515151] lg:hidden" ></div>
                    <div className="rounded-3xl bg-[#242424] mt-8 mx-12 p-1 px-4 text-white font-dm_mono text-[11px] font-bold mb-2 inline-block lg:mx-8 dark:bg-[#FAF8F1] dark:text-[#242424]" >CHAPTERS</div>

                    {filteredChapData.map((value) => {
                        return (
                            <div onClick={() => onItemClick(value)} className="ml-4 mr-4 px-4 rounded-lg cursor-pointer hover:bg-[#F0EFE4] lg:hover:bg-[#f4f4f4] lg:px-0 dark:hover:bg-[#424242]" >
                                <p className="text-[#242424] font-dm_mono font-normal text-[18px] ml-4 pt-3 lg:text-[14px] dark:text-white" >{value.chapter_name} </p>
                                <div className="border-[1px] border-[#e6e6e6] rounded-3xl px-3 bg-white mb-3 inline-block ml-4 dark:border-[#515151] dark:bg-[#303030]" >
                                    <p className="text-[#909090] font-dm_mono text-[11px] font-medium" >CHAPTER {value.chapter_number}</p>
                                </div>
                            </div>
                        );
                    })}

                    <div className="mt-4 border-b-[1px] border-[#e6e6e6] dark:border-b-[#515151]" ></div>

                    <div className="rounded-3xl bg-[#242424] mt-8 mx-12 p-1 px-4 text-white font-dm_mono text-[11px] font-bold mb-2 inline-block lg:mx-8 dark:bg-[#FAF8F1] dark:text-[#242424]" >CONTENT</div>

                    {filteredConData.map((value) => {
                        return (
                            <div onClick={() => onItemClick(value)} className="ml-4 mr-4 rounded-lg px-4 cursor-pointer hover:bg-[#F0EFE4] lg:hover:bg-[#f4f4f4] lg:px-0 dark:hover:bg-[#424242]">
                                <p className="hidden text-[#242424] font-dm_mono font-normal text-[18px] mx-4 mb-1 pt-3 lg:block lg:text-[14px] dark:text-white" >{value.content.slice(0, 30)}... </p>
                                <p className="text-[#242424] font-dm_mono font-normal text-[18px] mx-4 mb-1 pt-3 lg:hidden lg:text-[14px] dark:text-white" >{value.content.slice(0, 26)}... </p>
                                <div className="flex flex-wrap" >
                                    <div className="border-[1px] border-[#e6e6e6] rounded-3xl px-3 bg-white mb-3 inline-block ml-4 mr-2 dark:border-[#515151] dark:bg-[#303030]" >
                                        <p className="text-[#909090] font-dm_mono text-[11px] font-medium" >CHAPTER {value.chapter_number}</p>
                                    </div>
                                    <div className="border-[1px] border-[#e6e6e6] rounded-3xl px-3 bg-white mb-3 inline-block dark:border-[#515151] dark:bg-[#303030]" >
                                        <p className="text-[#909090] font-dm_mono text-[11px] font-medium" >PAGE {value.page_number}</p>
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                </div>
            )}

        </div>
    );
};

export default Search;