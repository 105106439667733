// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import styles from "./index.css"
// import App from './App';
// import Setting from './Setting';
// import LoginPage from './loginPage';
// import Stories from './Stories';
// import Characters from './Characters';

// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";

// const router = createBrowserRouter([
//   {
//     path: '/',
//     element: <LoginPage />
//   },
//   {
//     path: '/setting',
//     element: <Setting />,
//   },
//   {
//     path: '/stories',
//     element: <Stories />
//   },
//   {
//     path: "/reader",
//     element: <App />
//   },
//   {
//     path: "/characters",
//     element: <Characters />
//   },

// ]);

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     {/* <App /> */}
//     <RouterProvider router={router} />
//   </React.StrictMode>
// );



import React from "react";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import "./index.css";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// ReactDOM.render(
//   <React.StrictMode>
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   </React.StrictMode>,
//   document.getElementById("root")
// );
