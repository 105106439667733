import React, {useState} from "react";
import V2CharVideoModal from './v2-char-videomodal';

export default function V2CharAudioBook({View2CharacterData, darkSide}) {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <button
        // className="underline mb-2 font-dm_mono text-[#b1b1b1] text-[16px] font-medium dark:text-[#818181] hover:text-[#242424] hover:dark:text-[#FAF8F1] ease-linear transition-all duration-150"
        type="button"
        onClick={() => setShowModal(true)}
      >
        <div className="px-6 py-2 rounded-md bg-[#242424] text-[#FAF8F1] dark:bg-[#FAF8F1] dark:hover:bg-[#515151] hover:bg-[#eee] transition-all ease-linear duration-150" >
          <p className="relative group">
              <span className='underline font-dm_mono text-[#FAF8F1] text-[14px] font-medium dark:text-[#242424] group-hover:text-[#242424] dark:group-hover:text-[#FAF8F1] group-hover:transition-all group-hover:duration-150 group-hover:ease-linear lg:no-underline'>Audio Book</span>
              <span className="absolute bottom-1 left-0 w-0 h-[1px] bg-[#242424] group-hover:w-full group-hover:bg-[#242424] dark:group-hover:bg-[#FAF8F1] group-hover:transition-all group-hover:duration-150 group-hover:ease-linear"></span>
          </p>
        </div>
      </button>
      {showModal ? (
        <>
          <div className="absolute items-end flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative m-auto">
              {/*content*/}
              <div className="w-[520px] rounded-lg shadow-lg relative block bg-white border-[1px] border-[#242424] outline-none focus:outline-none dark:bg-[#282828] dark:border-[#FAF8F1]">
                {/*body*/}
                <div className="rounded-b flex justify-end">
                  <button
                    className="background-transparent px-2 py-2 text-sm outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    <img className="h-4" src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                  </button>
                </div>

                <div className="relative px-5 pb-5">
                    <V2CharVideoModal videoId="waalP-aNOtA" View2CharacterData={View2CharacterData}/>
                </div>
                {/*footer*/}
                
              </div>
            </div>
          </div>
          {/* <div className="opacity-25 fixed inset-0 bg-black"></div> */}
        </>
      ) : null}
    </>
  );
}