import React from "react";
import {useState, useEffect} from 'react';
import useSound from 'use-sound';
import boopSfx from '../lofi.mp3';

const CharVideoModal = ({ videoId, characterdata, darkSide }) => {

  const [play, { stop }] = useSound(boopSfx);
  const [paused, setPaused] = React.useState(true);

  const handlePlay = () => {
    play();
  }

  const handlePause = () => {
    stop();
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* <iframe
          title="YouTube video player"
          src={`https://www.youtube.com/embed/${videoId}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        /> */}
        <div className='pb-4 -mt-4 font-dm_mono font-normal text-[16px] text-[#242424] dark:text-[#FAF8F1]'>Play Audio Book</div>
        <iframe className="hidden lg:block"
          height="250px"
          width="450px"
          frameborder="no" 
          allow="autoplay" 
          src={characterdata.audio_book}>
        </iframe>

        <iframe className="lg:hidden"
          // height="250px"
          width="100%"
          frameborder="no" 
          allow="autoplay" 
          src={characterdata.audio_book}>
        </iframe>

        <div className="flex items-center justify-between mt-7 mb-2 bg-[#eee] p-3 rounded-md dark:bg-[#515151]">
          <div className='font-dm_mono font-normal text-[16px] text-[#242424] dark:text-[#FAF8F1]'>Add Background Music</div>
          <button className='flex items-center justify-center border-[1px] border-[#212121] rounded-md dark:py-3 dark:px-5 dark:border-[1px] dark:border-[#FAF8F1] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[2px] hover:border-[#515151] hover:dark:border-[2px] hover:dark:border-[#FAF8F1]'
            aria-label={paused ? 'play' : 'pause'}
            onClick={() => setPaused(!paused)}
          >
            {paused ? (
              <div onClick={handlePlay} className='cursor-pointer' >
                <img src={darkSide ? "white_music_pause.svg" : "music_pause.svg"} />
              </div>
            ) : (
              // <PauseRounded onClick={handlePause} sx={{ fontSize: '1.5rem' }} htmlColor={mainIconColor} />
              <div onClick={handlePause} className='cursor-pointer' >
                <img src={darkSide ? "white_music_play.svg" : "music_play.svg"} />
              </div>
            )}
          </button>
        </div>
        
      </div>
    </div>
  );
};

export default CharVideoModal;
