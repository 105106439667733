import React, { useEffect } from 'react';
import ReactDOMServer from "react-dom/server"
import { useState } from 'react';
import axios from 'axios'
import Character1Popper from '../character-popup/character1popper';
import reactStringReplace from 'react-string-replace';
import jwt_decode from 'jwt-decode'
import V2AudioBook from '../v2-audio-book'
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom'


// const characters = ['CHIMP', 'Andeddo'];
// const charactersMap = {
//     CHIMP: CharacterPopup,
//     Andeddo: Character1Popper
// }

const View2Page = ({ v2data, pg, chno, left_content, right_content, fontSize, mobFontSize, fontStyle, handleBookmark, jumpToPage, darkSide, open, user, onBookmarkAdd, onBookmarkDelete, bookmarks, setBookmarks }) => {

    let left_replacedText = reactStringReplace(left_content, 'Yasuke', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Kyoju', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Oda', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Michi', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Tedi', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Mokuzai', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Kuki', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Washi', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Majishan', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Fuhai', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Ten'no", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Bushi", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Isha", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Ryu", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Aki", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Horaguramu", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Namazu", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Karasu", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Tantei", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, "Inferuno", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, '<br>', () => <br />)
    // left_replacedText = reactStringReplace(left_replacedText, '<i>', (match,i) => <i>{match}</i>)




    let right_replacedText = reactStringReplace(right_content, 'Yasuke', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Kyoju', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Oda', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Michi', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Teddi', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Mokuzai', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Kuki', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Washi', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Majishan', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Fuhai', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Ten'no", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Bushi", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Isha", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Ryu", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Aki", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Horaguramu", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Namazu", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Karasu", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Tantei", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, "Inferuno", (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, '<br>', () => <br />)
    // right_replacedText = reactStringReplace(right_replacedText, '<i>', (match,i) => <i>{match}</i>)




    const handleClickScroll = (pg) => {
        const element = document.getElementById(`main-page-${pg}`);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => { if (pg === jumpToPage) { handleClickScroll(jumpToPage) } }, [jumpToPage])

    const [isActiveBookmark, setIsActiveBookmark] = useState(false);

    // const toggleBookmark = () => {
    //     handleBookmark(pg, chno);
    //     setIsActiveBookmark(!isActiveBookmark);
    // }

    const [error, setError] = useState("")
    // const [bookmarks, setBookmarks] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let tokenStr = window.localStorage.getItem('token');
            try {
                const decodedToken = jwt_decode(tokenStr);
                const userId = decodedToken._id;
                const response = await axios.get(`/api/s2-bookmark/${userId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${tokenStr}`
                    }
                });
                const existingBookmark = response.data.forEach((bookmark) => { if (bookmark.pg == pg) setIsActiveBookmark(true) });

                setBookmarks(response.data);
            } catch (error) {
                if (error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    setError(error.response.data.message)
                }
            }
        };
        fetchData();
    }, [])


    const handleBookmarkClick = async (pg, chno) => {
        let tokenStr = window.localStorage.getItem('token');
        const existingBookmark = bookmarks.filter((bookmark) => bookmark.pg == pg);
        console.log(existingBookmark);
        existingBookmark.forEach(async (bookmarks) => {
            if (bookmarks) {
                console.log(bookmarks);
                try {
                    const response = await axios.delete(`/api/s2-bookmark/${bookmarks._id}`, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${tokenStr}`
                        }
                    });
                    // setBookmarks(bookmarks.filter((bookmark) => bookmark._id !== existingBookmark._id));
                    onBookmarkDelete(response.data);
                    setIsActiveBookmark(!isActiveBookmark);
                    console.log(response);
                } catch (error) {
                    console.error(error);
                }
            }
        })

        if (existingBookmark.length === 0) {
            try {
                const response = await axios.post('/api/s2-bookmark', { pg, chno }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${tokenStr}`
                    }
                })
                console.log(response.data);
                onBookmarkAdd(response.data);
                setBookmarks([...bookmarks, response.data]);
                setIsActiveBookmark(!isActiveBookmark);
            } catch (error) {
                if (error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    setError(error.response.data.message)
                }
            }
        }
    }

    // it would crash, if bookmarks array not passed
    // const isActiveBookmark = bookmarks.some(bookmark => bookmark.page_number === pg);

    return (
        <div className={`lg:grid lg:grid-cols-2 lg:gap-4 lg:mt-1 ${chno}`} id={`main-page-${pg}`}>
            <div className={open ? 'lg:p-[75px] border-[1px] border-[#CBCAC7] dark:border-[#303030] 2xl:p-[100px]' : 'lg:py-[76px] lg:px-[135px] border-[1px] border-[#CBCAC7] dark:border-[#303030] 2xl:py-[110px] 2xl:px-[165px]'}>

                <div className='hidden lg:flex'>
                    {v2data.img ? (
                        <V2AudioBook v2data={v2data} darkSide={darkSide} />
                    ) : null }
                </div>

                {v2data.img ? (
                    <div className='text-left'>
                        {/* <V2AudioBook v2data={v2data} darkSide={darkSide} /> */}
                        <p className='hidden mb-1 font-dm_mono text-left text-black opacity-30 text-[13px] font-medium lg:block lg:mt-10 dark:text-[#FAF8F1]'>CHAPTER {v2data.chapter_number}</p>
                        <p className='hidden mb-12 font-pusab uppercase text-left leading-9 text-[#242424] text-[18px] font-medium lg:block dark:text-[#FAF8F1]'>{v2data.chapter_name}</p>
                        <div className='border-[1px] border-[#242424]'>
                            <img src={v2data.img} />
                        </div>
                    </div>

                ) :
                    <p className='hidden text-justify font-dm_mono leading-9 text-[#242424] text-[18px] font-normal lg:block dark:text-[#FAF8F1]'>
                        {left_replacedText}
                    </p>
                }


            </div >

            <div className={open ? 'lg:p-[75px] border-[1px] border-[#CBCAC7] dark:border-[#303030] 2xl:p-[100px]' : 'lg:py-[76px] lg:px-[135px] border-[1px] border-[#CBCAC7] dark:border-[#303030] 2xl:py-[110px] 2xl:px-[165px]'}>

                <div className='relative flex items-center justify-between'>
                    <div>
                        {user ? 
                        <button onClick={() => handleBookmarkClick(pg, chno)} className='z-2 top-[-17px] right-0 lg:top-[-76px] lg:right-[-50px] lg:block absolute 2xl:top-[-100px]'>
                            {/* <img src={isActiveBookmark ? darkSide ? "dark_bookmark_selected.svg" : "bookmark_selected.svg" : darkSide ? "dark_bookmark_outline.svg" : "bookmark_outline.svg"} /> */}
                            <img src={darkSide ? isActiveBookmark ? "dark_bookmark_selected.svg" : "dark_bookmark_outline.svg" : isActiveBookmark ? "bookmark_selected.svg" : "bookmark_outline.svg"} />
                        </button> 
                        :
                        <Link to="/login">
                            <Tooltip title="Login to Bookmark this page" placement="top-end">
                                <button className='z-2 top-[-17px] right-0 lg:top-[-76px] lg:right-[-50px] lg:block absolute 2xl:top-[-100px]'>
                                    {/* <img src={isActiveBookmark ? darkSide ? "dark_bookmark_selected.svg" : "bookmark_selected.svg" : darkSide ? "dark_bookmark_outline.svg" : "bookmark_outline.svg"} /> */}
                                    <img src={darkSide ? isActiveBookmark ? "dark_bookmark_selected.svg" : "dark_bookmark_outline.svg" : isActiveBookmark ? "bookmark_selected.svg" : "bookmark_outline.svg"} />
                                </button>
                            </Tooltip>
                        </Link>
                        }
                    </div>
                </div>

                <p className='hidden text-justify font-dm_mono leading-9 text-[#242424] text-[18px] font-normal lg:block dark:text-[#FAF8F1]'>
                    {right_replacedText}
                </p>
                <p className='hidden font-dm_mono font-normal text-[14px] text-[#242424] absolute right-14 bottom-10 lg:block dark:text-[#FAF8F1]'>{pg}</p>

            </div >
        </div>
    );
};

export default View2Page;