import React from 'react';
import { useState, useEffect } from 'react';
import MNavbar from './components/setting/main-navbar.jsx';
import Preview from './components/setting/preview';
import Settings from './components/setting/settings';
import useDarkSide from './components/usedarkSide';
import ExplorerDrawer from './components/mobile/explorer-drawer.jsx';

const Setting = () => {

    const [fontSize, setFontSize] = useState(
        localStorage.getItem('fontSize') || '24px'
    );

    useEffect(() => {
        localStorage.setItem('fontSize', fontSize);
    }, [fontSize]);

    const handleClick = (size) => {
        setFontSize(size);
        window.location.reload()
    };



    const [fontStyle, setFontStyle] = useState(
        localStorage.getItem('fontStyle') || 'DM Mono'
    );

    useEffect(() => {
        localStorage.setItem('fontStyle', fontStyle);
    }, [fontStyle]);

    const handleStyleClick = (style) => {
        setFontStyle(style);
        window.location.reload()
    };


    const [mobFontSize, setMobFontSize] = useState(
        localStorage.getItem('mobile-fontSize') || '20px'
    );

    useEffect(() => {
        localStorage.setItem('mobile-fontSize', mobFontSize);
    }, [mobFontSize]);

    const handleMobSizeClick = (size) => {
        setMobFontSize(size);
        window.location.reload()
    };




    // const [fontSize, setFontSize] = useState(24);
    const [lineHeight, setLineHeight] = useState(50);
    // const [mobFontSize, setMobFontSize] = useState(18);
    // const [fontStyle, setFontStyle] = useState("DM Mono");

    const handleFSizeS = () => {
        setFontSize(20)
        setLineHeight(30)
    }

    const handleFSizeM = () => {
        setFontSize(24)
        setLineHeight(50)
    }

    const handleFSizeL = () => {
        setFontSize(28)
        setLineHeight(70)
    }

    const handleMFSizeS = () => {
        setMobFontSize(16)
    }

    const handleMFSizeM = () => {
        setMobFontSize(18)
    }

    const handleMFSizeL = () => {
        setMobFontSize(20)
    }

    const handleFStyle = (newFont) => {
        setFontStyle(newFont)
    }


    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(
        colorTheme === "light" ? true : false
    );

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme);
        setDarkSide(checked);
    };


    const [selectedClass, setSelectedClass] = useState("class1");

    const handleButtonClick = (className) => {
        setSelectedClass(className);
    };


    const [isExploreOpen, setIsExploreOpen] = React.useState(false)

    const toggleExploreDrawer = () => {
        setIsExploreOpen((prevState) => !prevState)
    }

    return (
        <div className='bg-[#faf8f1] h-full bg-cover lg:h-[100vh]' style={ { backgroundImage: "url('main-bg-img2.png')" } }>
            <div className='h-full flex flex-col justify-between max-w-[1600px] lg:h-[100vh] lg:pt-4 lg:w-[97%] lg:mx-auto'>
                <div>
                    <MNavbar toggleExploreDrawer={ toggleExploreDrawer } />
                    <ExplorerDrawer open={ isExploreOpen } onClose={ toggleExploreDrawer } />

                    {/* <Drawer open={ isExploreOpen } onClose={ toggleExploreDrawer } direction='top' size='600' className='z-[11] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'>
                        <div className='w-full h-[1px] bg-[#E0DED8] mt-16'></div>
                        <Box className="grid grid-cols-2 gap-4 bg-[#FAF8F1] px-4 py-8">
                            <a href='https://medium.com/@ChimpersNFT' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="news.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424]" >NEWS/INFO</p>
                                    </div>
                                </button>
                            </a>
                            <a href='https://marketplace.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="marketplace.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >MARKETPLACE</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://dojo.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="dojo.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >DOJO</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://www.chimpers.xyz/leaderboard' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="leaderboard.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >LEADERBOARD</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://arcade.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="game.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >GAMES</p>

                                    </div>
                                </button>
                            </a>
                        </Box>

                        <div className='grid grid-cols-3 border-t-[1px] border-[#E0DED8]' >
                            <a className='mx-auto' href='https://opensea.io/collection/chimpersnft' target='_blank'>
                                <button className='py-4 mx-auto'>
                                    <img className='' src="char_opensea.svg" />
                                </button>
                            </a>
                            <a href='https://discord.com/invite/chimpers' target='_blank'>
                                <button className='h-full w-full py-4 border-l-[1px] border-r-[1px] border-[#E0DED8]'>
                                    <img className='mx-auto' src="char_discord.svg" />
                                </button>
                            </a>
                            <a className='mx-auto' href='https://twitter.com/ChimpersNFT' target='_blank'>
                                <button className='py-4 mx-auto'>
                                    <img className='' src="char_twitter.svg" />
                                </button>
                            </a>
                        </div>
                    </Drawer> */}

                    <div className='hidden w-[96%] mx-auto grid grid-cols-5 gap-4 lg:grid'>
                        <div className='col-span-3'>
                            <Settings handleClick={ handleClick } handleStyleClick={ handleStyleClick } handleFSizeS={ handleFSizeS } handleFSizeM={ handleFSizeM } handleFSizeL={ handleFSizeL } handleFStyle={ handleFStyle } toggleDarkMode={ toggleDarkMode } darkSide={ darkSide } handleButtonClick={ handleButtonClick } />
                        </div>
                        <div className='col-span-2'>
                            <Preview fontSize={ fontSize } fontStyle={ fontStyle } selectedClass={ selectedClass } darkSide={ darkSide } />
                        </div>
                    </div>


                    {/* mobile */ }
                    <div className='ml-3 mr-3 flex flex-col md:ml-5 md:mr-5 lg:hidden'>
                        <div>
                            <p className='mt-2 font-pusab mb-10 text-[#242424] text-[20px]' >SET YOUR VIBE</p>
                            <div className='col-span-2'>
                                <Preview mobFontSize={ mobFontSize } fontStyle={ fontStyle } selectedClass={ selectedClass } />
                            </div>
                            <div className='col-span-3'>
                                <Settings handleMobSizeClick={ handleMobSizeClick } handleStyleClick={ handleStyleClick } handleFSizeS={ handleFSizeS } handleFSizeM={ handleFSizeM } handleFSizeL={ handleFSizeL } handleFStyle={ handleFStyle } toggleDarkMode={ toggleDarkMode } darkSide={ darkSide } handleButtonClick={ handleButtonClick } />
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-[97%] pt-8 mx-auto pb-6 flex items-end gap-2 md:mt-10 lg:hidden'>
                    <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
                </div>
            </div>

        </div>
    );
};

export default Setting;