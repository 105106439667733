import React from 'react';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';


const CharacterMobileNavbar = ({ toggleChapterDrawer, toggleSearchDrawer, toggleNotelistDrawer, toggleSettingsDrawer, darkSide }) => {

    // const [visibleA, setVisibleA] = useState(false)

    // const SettingsDrawer = () => {
    //     toggleSettingsDrawer();
    //     setVisibleA(!visibleA)
    // }

    // const navigate = useNavigate();

    return (
        <>
            <div className='flex items-center justify-between p-4 pt-7 sticky top-0 z-[102] bg-[#FAF8F1] dark:bg-[#242424]'>

                <div className='flex items-center justify-between'>
                    <Link to='/stories'>
                        <button>
                            <img className='mr-5 h-4' src={darkSide ? "white_left-arrow.svg" : "leftarrow.svg"} />
                        </button>
                    </Link>
                    <button onClick={toggleChapterDrawer}>
                        <img className='mr-5 h-4' src={darkSide ? "white_chaptersicon.svg" : "chaptersicon.svg"} />
                    </button>
                </div>

                <div className='flex items-center justify-between'>
                    <button onClick={toggleSearchDrawer} >
                        <img className='h-5' src={darkSide ? "white_msearch.png" : "msearch.png"} />

                    </button>
                    <button className='ml-5' onClick={toggleSettingsDrawer}>
                        <img src={darkSide ? "dark_union.svg" : "Union.svg"} />
                    </button>
                </div>
            </div>

        </>
    )
}

export default CharacterMobileNavbar;