import React from 'react';
import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
// import Collapse from 'react-bootstrap/Collapse';
// import Button from 'react-bootstrap/Button';
import Collapsible from 'react-collapsible';
import useSound from 'use-sound';
import boopSfx from '../../music.mp3';
import { useTheme } from '@mui/material/styles';
import { DarkModeSwitch } from "react-toggle-dark-mode";
import jwt_decode from 'jwt-decode';
import axios from 'axios'
import { Link } from 'react-router-dom'



const SettingsDrawer = ({ user, handleMFSizeS, handleMFSizeM, handleMFSizeL, handleStyleClick, handleMobSizeClick, handleFStyle, toggleDarkMode, darkSide }) => {

    const [mobFontSize, setMobFontSize] = useState(
        localStorage.getItem('mobile-FontSize') || '20px'
    );

    const [fontStyle, setFontStyle] = useState(
    localStorage.getItem('fontStyle') || 'DM Mono'
    );

    const [musicOpen, setMusicOpen] = useState(false);
    const [styleOpen, setStyleOpen] = useState(false);
    const [textOpen, setTextOpen] = useState(false);


    const [play, { stop }] = useSound(boopSfx);
    const [volume, setVolume] = useState(30);

    const handlePlay = () => {
        play();
    }

    const handlePause = () => {
        stop();
    }

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
    };

    const theme = useTheme();
    const duration = 200; // seconds
    const [position, setPosition] = React.useState(32);
    const [paused, setPaused] = React.useState(true);
    // function formatDuration(volume) {
    //     const minute = Math.floor(value / 60);
    //     const secondLeft = value - minute * 60;
    //     return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
    // }
    // const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';
    const mainIconColor = '#fff';
    const lightIconColor =
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';



    const [error, setError] = useState("")
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const token = window.localStorage.getItem('token');
        if (!token) return;
    
        try {
        const decodedToken = jwt_decode(token);
        const userId = decodedToken._id;
        setUserId(userId);
        console.log(userId);
        } catch (err) {
        console.error(err);
        }
    }, []);


    const [userData, setUserData] = useState([])

    useEffect(() => {
        if(userId) {
        const fetchData = async () => {
            let tokenStr = window.localStorage.getItem('token');
            try {
            console.log(userId);
            const response = await axios.get(`/api/users/${userId}`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenStr}`
                }
            });
            console.log(response.data);
            setUserData(response.data);
            } catch (error) {
            if (error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message)
            }
            }
        };
        fetchData();
        }
    }, [userId])


    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.reload();
      }


    return (
        <main >
            <div className="flex flex-col justify-between">

                <div>

                    {/* functionality icons  */}
                    <div className="mt-2 grid grid-cols-4 gap-2 px-2">

                        <div className="flex items-center justify-center border-[1px] border-[#C9C9C7] rounded-lg dark:border-[#303030]">
                            {/* <img className="h-5" src="dark-mode.svg" /> */}
                            <DarkModeSwitch
                                checked={darkSide}
                                onChange={toggleDarkMode}
                            >
                                {/* <img className="h-4" src="dark-mode.svg" /> */}
                            </DarkModeSwitch>
                        </div>

                        {/* <button className="border-[1px] border-[#C9C9C7] rounded-lg"
                            onClick={() => setMusicOpen(!musicOpen)}
                            aria-controls="example-collapse-text"
                            aria-expanded={musicOpen}
                        >
                            <img className="px-6 py-4 h-12" src="music.svg" />
                        </button> */}

                        <button className='flex items-center justify-center h-full border-[1px] border-[#C9C9C7] rounded-lg dark:border-[#303030]'
                            aria-label={paused ? 'play' : 'pause'}
                            onClick={() => setPaused(!paused)}
                        >
                            {paused ? (
                                <div onClick={handlePlay} className='cursor-pointer' >
                                    <img className="h-5" src={darkSide ? "white_mobmusic_pause.svg" : "mobmusic_pause.svg"} />
                                </div>
                            ) : (
                                // <PauseRounded onClick={handlePause} sx={{ fontSize: '1.5rem' }} htmlColor={mainIconColor} />
                                <div onClick={handlePause} className='cursor-pointer' >
                                    <img className="h-5" src={darkSide ? "white_mobmusic_play.svg" : "mobmusic_play.svg"} />
                                </div>
                            )}
                        </button>

                        <button className="flex items-center justify-center border-[1px] border-[#C9C9C7] rounded-lg dark:border-[#303030]"
                            onClick={() => setStyleOpen(!styleOpen)}
                            aria-controls="example-collapse-text"
                            aria-expanded={styleOpen}
                        >
                            <img className="px-6 py-4 h-12" src={darkSide ? "white_mfontstyle.svg" : "font-style.svg"} />
                        </button>

                        <button className="flex items-center justify-center border-[1px] border-[#C9C9C7] rounded-lg dark:border-[#303030]"
                            onClick={() => setTextOpen(!textOpen)}
                            aria-controls="example-collapse-text"
                            aria-expanded={textOpen}
                        >
                            <img className="px-6 py-4 h-12" src={darkSide ? "white_mfontsize.svg" : "font-size.svg"} />
                        </button>

                    </div>

                    {/* <Collapsible open={musicOpen}>

                        <div className="relative p-6 mx-2 mt-4 flex-auto bg-[#242424] rounded-lg">
                            <p className='font-dm_mono font-medium text-[18px] text-white pb-3' >Change Font Style</p>
                            <div className='flex items-center justify-between gap-3'>
                                <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#909090] text-center rounded py-2 grow focus:bg-[#fff] focus:text-[#242424]' onClick={handleMFSizeS}>FS 1</button>
                                <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#909090] text-center rounded py-2 grow focus:bg-[#fff] focus:text-[#242424]' onClick={handleMFSizeM}>FS 2</button>
                                <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#909090] text-center rounded py-2 grow focus:bg-[#fff] focus:text-[#242424]' onClick={handleMFSizeL}>FS 3</button>
                            </div>
                        </div>

                    </Collapsible> */}

                    <Collapsible open={styleOpen}>

                        <div className="relative p-6 mx-2 mt-4 flex-auto bg-[#242424] rounded-lg dark:bg-[#fff]">
                            <p className='font-dm_mono font-medium text-[18px] text-white pb-3 dark:text-[#242424]' >Change Font Style</p>
                            <div className='flex items-center justify-between gap-3'>
                                <button
                                    onClick={() => handleStyleClick('DM Mono')}
                                    // className={classnames('bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md', fontSize==='DM Mono' && 'bg-[#242424] text-[#eee]' )}
                                    className='font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow focus:text-[#242424] focus:bg-[#fff] dark:focus:bg-[#242424] dark:focus:text-[#FAF8F1]'
                                    >
                                FS 1
                                </button>
                                <button
                                    onClick={() => handleStyleClick('Merriweather')}
                                    className='font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow focus:text-[#242424] focus:bg-[#fff] dark:focus:bg-[#242424] dark:focus:text-[#FAF8F1]'
                                    >
                                    FS 2
                                </button>
                                <button
                                    onClick={() => handleStyleClick('Work Sans')}
                                    className='font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow focus:text-[#242424] focus:bg-[#fff] dark:focus:bg-[#242424] dark:focus:text-[#FAF8F1]'
                                    >
                                    FS 3
                                </button>
                            </div>
                        </div>

                    </Collapsible>

                    <Collapsible open={textOpen}>

                        <div className="relative p-6 mx-2 mt-4 flex-auto bg-[#242424] rounded-lg dark:bg-[#fff]">
                            <p className='font-dm_mono font-medium text-[18px] text-white pb-3 dark:text-[#242424]' >Change Font Size</p>
                            <div className='flex items-center justify-between gap-3'>

                            <button
                                onClick={() => handleMobSizeClick('16px')}
                                // className={classnames('bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md', fontSize==='20px' && 'bg-[#242424] text-[#eee]' )}
                                // className={classNames('font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow', mobFontSize==='16px' && 'text-[#242424] bg-[#fff] dark:bg-[#242424] dark:text-[#FAF8F1]')}
                                className='font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow focus:text-[#242424] focus:bg-[#fff] dark:focus:bg-[#242424] dark:focus:text-[#FAF8F1]'
                            >
                                S
                            </button>
                            <button
                                onClick={() => handleMobSizeClick('20px')}
                                className='font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow focus:text-[#242424] focus:bg-[#fff] dark:focus:bg-[#242424] dark:focus:text-[#FAF8F1]'
                            >
                                M
                            </button>
                            <button
                                onClick={() => handleMobSizeClick('24px')}
                                className='font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow focus:text-[#242424] focus:bg-[#fff] dark:focus:bg-[#242424] dark:focus:text-[#FAF8F1]'
                            >
                                L
                            </button>

                                {/* <button className={classNames('font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow', mobFontSize===16 && 'text-[#242424] bg-[#fff] dark:bg-[#242424] dark:text-[#FAF8F1]')} onClick={handleMFSizeS}>S</button>
                                <button className={classNames('font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow', mobFontSize===18 && 'text-[#242424] bg-[#fff] dark:bg-[#242424] dark:text-[#FAF8F1]')} onClick={handleMFSizeM}>M</button>
                                <button className={classNames('font-dm_mono text-[16px] text-[#000] font-medium bg-[#909090] text-center rounded py-2 grow', mobFontSize===20 && 'text-[#242424] bg-[#fff] dark:bg-[#242424] dark:text-[#FAF8F1]')} onClick={handleMFSizeL}>L</button> */}
                            </div>
                        </div>

                    </Collapsible>

                    <div className='h-[1px] w-full bg-[#E0DED8] mt-10 dark:bg-[#202020]'></div>


                    {/* user profile */}
                    <div className="mt-8 px-2">

                        
                        {/* <p className="font-medium font-dm_mono text-[#242424] text-[24px] text-center ml-4 dark:text-[#FAF8F1]">Hi Chimp69</p> */}
                        {user ? 
                        <div>
                            <div className="mb-2 rounded-full">
                                <img className='h-16 mx-auto' src='mob-userprofile.png' />
                            </div>
                            <p className="capitalize font-medium font-dm_mono text-[#242424] text-[22px] text-center dark:text-[#FAF8F1]">{userData.name}</p>
                        </div>
                        :   
                        <div>
                            <div className="mb-2 rounded-full">
                                <img className='h-16 mx-auto' src='chimp-logo.png' />
                            </div>
                            <p className="capitalize font-medium font-dm_mono text-[#242424] text-[22px] text-center dark:text-[#FAF8F1]">Guest User</p> 
                        </div>
                        }

                    
                        {/* <img src={darkSide ? "white_msignout.svg" : "msignout.svg"} /> */}
                        {/* <p className='font-dm_mono font-medium text-[18px] text-[#242424] ml-6 dark:text-[#FAF8F1]' >Sign Out</p> */}

                        {user ?
                            <div className='flex items-center px-10 py-3 mt-8 border-[1px] border-[#C9C9C7] rounded-lg dark:border-[#303030]' >
                                <img src={darkSide ? "white_msignout.svg" : "msignout.svg"} />
                                <button onClick={handleLogout} className='font-dm_mono font-medium text-[18px] text-[#242424] ml-10 dark:text-[#FAF8F1]' >Sign Out</button>
                            </div> :
                            <Link to='/login'>
                                <div className='flex items-center px-10 py-3 mt-8 border-[1px] border-[#C9C9C7] rounded-lg dark:border-[#303030]' >
                                <img src={darkSide ? "white_mlogin.svg" : "mlogin.svg"} />
                                    <button className='font-dm_mono font-medium text-[18px] text-[#242424] ml-10 dark:text-[#FAF8F1]' >Login with email</button>
                                </div>
                            </Link>
                        }
                        <div className='text-center'>
                            <button className='font-dm_mono font-medium text-[11px] text-[#909090] underline text-center mt-4' >Terms and Privacy</button>
                        </div>  
                    </div>

                </div>

            </div>
        </main>
    );
};

export default SettingsDrawer;