import { useMediaQuery, useTheme } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import CharacterPage from './character-page.jsx';
import ScrollIndicator from './scroll-indicator.jsx';

const CharacterView1 = ({ CharacterData, fontSize, mobFontSize, fontStyle, lineHeight, handleBookmark, jumpToPage, darkSide }) => {
    const theme = useTheme();

    const [scrollTop, setSCrollTop] = useState(0);
    const matches = useMediaQuery(theme.breakpoints.up('lg'));
    const isDesktop = !!matches;

    const onScroll = () => {
        const winScroll = document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        setSCrollTop(scrolled);
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const download = async (src) => {
        const link = document.createElement('a');
        link.href = src;
        link.download = 'Download.svg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div>

            {isDesktop ? (
                <div className="flex items-start flex-row gap-4">
                    <p className="leading-relaxed">
                        <div>
                            {
                                CharacterData.map(characterdata => {
                                    return (
                                        <Fragment>

                                            <div>

                                                {/* {characterdata.image ? (
                                                    <div className='relative grid grid-cols-7 gap-1 border-[1px] border-[#CBCAC7] lg:mt-0 lg:mb-4 lg:p-4 lg:gap-12 dark:border-[#303030]'>
                                                        <div className='col-span-2' >
                                                            <img className='h-full border-[1px] border-[#242424]' src={characterdata.image} />
                                                        </div>
                                                        <div className='col-span-5 mt-6' >
                                                            <p className='font-pusab font-normal uppercase text-[24px] text-[#242424] dark:text-[#FAF8F1]' >{characterdata.character_name}</p>
                                                            <p className='font-dm_mono font-medium text-[16px] text-[#242424] mb-5 dark:text-[#FAF8F1]' >Chimpers Genesis #{characterdata.number}</p>
                                                            <p className='font-light text-[16px] text-[#242424] leading-6 dark:text-[#FAF8F1]' style={{ fontFamily: fontStyle }} >{characterdata.description}</p>
                                                        </div>
                                                        <div className='flex items-center gap-5 absolute bottom-3 right-3'>
                                                            <button>
                                                                <img className='hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:-translate-y-0.5 ease-linear transition-all duration-150' src={darkSide ? "white_char_opensea.svg" : "char_opensea.svg"} />
                                                            </button>
                                                            <button>
                                                                <img className='hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:-translate-y-0.5 ease-linear transition-all duration-150' src={darkSide ? "white_char_discord.svg" : "char_discord.svg"} />
                                                            </button>
                                                            <button>
                                                                <img className='hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:-translate-y-0.5 ease-linear transition-all duration-150' src={darkSide ? "white_char_twitter.svg" : "char_twitter.svg"} />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : null
                                                } */}

                                                <CharacterPage characterdata={characterdata} pg={characterdata.page_number} fontSize={fontSize}
                                                    fontStyle={fontStyle} mobFontSize={mobFontSize} lineHeight={lineHeight} handleBookmark={handleBookmark} jumpToPage={jumpToPage} darkSide={darkSide} />

                                            </div>
                                        </Fragment>
                                    )
                                }
                                )
                            }
                        </div>

                    </p>

                    <ScrollIndicator scrollTop={scrollTop} />

                </div>

            ) :
                <div className="mt-0 dark:bg-[#242424]">
                    <p className="leading-relaxed">
                        <ScrollIndicator scrollTop={scrollTop} />
                        <div>
                            {
                                CharacterData?.map(characterdata => {
                                    return (
                                        <Fragment>

                                            <div>

                                                {/* {characterdata.image ? (
                                                    <div className='relative m-5 border-[1px] border-[#CBCAC7] lg:mt-0 lg:mb-4 lg:p-3'>
                                                        <div className='flex items-center gap-4 px-7 pt-6' >
                                                            <div>
                                                                <img className='h-[80px] w-[80px] border-[1px] border-[#242424] rounded-md' src={characterdata.image} />
                                                            </div>
                                                            <div>
                                                                <p className='font-pusab font-normal uppercase text-[18px] text-[#242424] dark:text-[#FAF8F1]' >{characterdata.character_name}</p>
                                                                <p className='font-dm_mono font-medium text-[16px] text-[#242424] dark:text-[#FAF8F1]' >Chimpers Genesis #{characterdata.number}</p>
                                                            </div>
                                                        </div>
                                                        <div className='border-b-[1px] border-[#CBCAC7] mt-5 mb-6' ></div>
                                                        <div className='px-7' style={{ fontSize: mobFontSize, fontFamily: fontStyle }} >
                                                            <p className='font-light text-[#242424] leading-7 dark:text-[#FAF8F1]' >{characterdata.description}</p>
                                                        </div>
                                                        <div className='border-b-[1px] border-[#CBCAC7] mt-6 mb-4' ></div>
                                                        <div className='flex items-center justify-between px-7 mb-4' >
                                                            <img className='' src={darkSide ? "white_char_opensea.svg" : "char_opensea.svg"} />
                                                            <img className='' src={darkSide ? "white_char_discord.svg" : "char_discord.svg"} />
                                                            <img className='' src={darkSide ? "white_char_twitter.svg" : "char_twitter.svg"} />
                                                        </div>

                                                    </div>
                                                ) : null
                                                } */}

                                                <CharacterPage characterdata={characterdata} pg={characterdata.page_number} fontSize={fontSize}
                                                    fontStyle={fontStyle} mobFontSize={mobFontSize} handleBookmark={handleBookmark} jumpToPage={jumpToPage} darkSide={darkSide} />

                                            </div>
                                        </Fragment>
                                    )
                                }
                                )
                            }
                        </div>

                    </p>
                </div>}




        </div>

    );
};

export default CharacterView1;