import React from 'react';

const Note = ({ id, pg, text, handleDeleteNote, darkSide }) => {
    return (
        <div className='p-4 border-[1px] border-[#C9C9C7] rounded-lg mb-4 lg:bg-[#EEEEEE] lg:border-none lg:rounded lg:w-[300px] lg:mb-0 whitespace-pre-wrap dark:bg-[#303030] dark:border-[1px] dark:border-[#303030]'>
            <div className='flex items-start justify-between'>
                <div className='mb-4 bg-[#242424] px-2 py-1 rounded dark:bg-[#515151]'>
                    <p className='font-dm_mono font-medium text-[11px] text-[#eeeeee] dark:text-[#FAAF8F1]'>Note</p>
                </div>

                <img className='cursor-pointer' onClick={() => handleDeleteNote(id)} src={darkSide ? "white_delete-icon.svg" : "delete.svg"} />
            </div>
            <p className='font-dm_mono font-normal text-[14px] text-[#242424] dark:text-[#FAF8F1]'>{text}</p>
        </div>
    );
};

export default Note;