import React, { useEffect } from 'react';
import ReactDOMServer from "react-dom/server"
import { useState } from 'react';
import axios from 'axios'
import CharacterPopup from './character-popup';
import Character1Popper from './character-popup/character1popper';
import reactStringReplace from 'react-string-replace';
import jwt_decode from 'jwt-decode';
import AudioBook from './audio-book';
import Tooltip from '@mui/material/Tooltip';
import { Link } from 'react-router-dom'


// import { useSpeechSynthesis } from 'react-speech-kit';


/**
 * /books/:bookId/vertical/:123
 */
// const download = async (src) => {
//     const link = document.createElement('a');
//     link.href = src;
//     link.download = 'Download.svg';
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// }


const Page = ({ data, pg, chno, content, fontSize, lineHeight, mobFontSize, fontStyle, handleBookmark, jumpToPage, darkSide, videoId, user, onBookmarkAdd, onBookmarkDelete, bookmarks, setBookmarks }) => {
    useEffect(() => {
        console.log('page props: ', data, pg);
    }, [])
    // const JsonContent = { content }
    // const givenWord = JSON.stringify(JsonContent);


    let replacedText = reactStringReplace(content, 'Odoriko', (match, i) => (
        i===0 ? <Character1Popper match={match} darkSide={darkSide}/> : match
    ));

    replacedText = reactStringReplace(replacedText, 'Himitsu', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    replacedText = reactStringReplace(replacedText, 'Midori', (match, i) => (
        <Character1Popper match={match} darkSide={darkSide} />
    ));

    replacedText = reactStringReplace(replacedText, '<br>', () => <br />)

    // replacedText = reactStringReplace(replacedText, 'PART 1', (match, i) => <p className='underline'>{match}</p>)
    // replacedText = reactStringReplace(replacedText, 'PART 2', (match, i) => <p className='underline'>{match}</p>)
    // replacedText = reactStringReplace(replacedText, 'PART 3', (match, i) => <p className='underline'>{match}</p>)


    // CURRENT PAGE
    // const [currentPage, setCurrentPage] = useState(1);

    // const pageHeight = 100;
    // const numberOfPages = Math.ceil(trialData.length / pageHeight);

    // const handlePageChange = (newPage) => {
    //     setCurrentPage(newPage);
    // }

    // useEffect(() => {{handlePageChange(currentPage)}}, [currentPage])




    // const toSearch = "Andeddo"
    // const target = givenWord.indexOf(toSearch);
    // const firstPart = givenWord.substring(12,target);
    // const secondPart = givenWord.substring(target + toSearch.length, givenWord.length-2)

    const handleClickScroll = (pg) => {
        const element = document.getElementById(`main-page-${pg}`);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (pg === jumpToPage) {
            handleClickScroll(jumpToPage)
        }
    }, [jumpToPage])


    const [isActiveBookmark, setIsActiveBookmark] = useState(false);

    // const toggleBookmark = () => {
    //     handleBookmark(pg, chno);
    //     setIsActiveBookmark(!isActiveBookmark);
    // }

    const [error, setError] = useState("")
    // const [bookmarks, setBookmarks] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            let tokenStr = window.localStorage.getItem('token');
            try {
                const decodedToken = jwt_decode(tokenStr);
                const userId = decodedToken._id;
                const response = await axios.get(`/api/bookmark/${userId}`, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${tokenStr}`
                    }
                });
                const existingBookmark = response.data.forEach((bookmark) => { if (bookmark.pg == pg) setIsActiveBookmark(true) });
                setBookmarks(response.data);
            } catch (error) {
                if (error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    setError(error.response.data.message)
                }
            }
        };
        fetchData();
    }, [])


    const handleBookmarkClick = async (pg, chno) => {
        let tokenStr = window.localStorage.getItem('token');
        const existingBookmark = bookmarks.filter((bookmark) => bookmark.pg == pg);
        console.log(existingBookmark);
        existingBookmark.forEach(async (bookmarks) => {
            if (bookmarks) {
                console.log(bookmarks);
                try {
                    const response = await axios.delete(`/api/bookmark/${bookmarks._id}`, {
                        headers: {
                            "Content-Type": "application/json",
                            "Authorization": `Bearer ${tokenStr}`
                        }
                    });
                    // setBookmarks(bookmarks.filter((bookmark) => bookmark._id !== existingBookmark._id));
                    console.log(response.data);
                    onBookmarkDelete(response.data);
                    setIsActiveBookmark(!isActiveBookmark);
                    console.log(response);
                    // window.location.reload();
                } catch (error) {
                    console.error(error);
                }
            }
        })

        if (existingBookmark.length === 0) {
            try {
                const response = await axios.post('/api/bookmark', { pg, chno }, {
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${tokenStr}`
                    }
                })
                console.log(response.data);
                onBookmarkAdd(response.data);
                setBookmarks([...bookmarks, response.data]);
                setIsActiveBookmark(!isActiveBookmark);
            } catch (error) {
                if (error.response &&
                    error.response.status >= 400 &&
                    error.response.status <= 500
                ) {
                    setError(error.response.data.message)
                }
            }
        }
    }

    const [text, setText] = useState(replacedText);
    const [status, setStatus] = useState("stopped");
    const [utterance, setUtterance] = useState(null);

    const handlePlayClick = () => {
        // Create a new SpeechSynthesisUtterance object
        const utterance = new SpeechSynthesisUtterance(text);
        setUtterance(utterance);
        // Start speaking the text
        window.speechSynthesis.speak(utterance);
        setStatus("playing");
    };

    const handlePauseClick = () => {
        // Pause the speech
        window.speechSynthesis.pause();
        setStatus("paused");
    };

    const handleReplayClick = () => {
        // Stop the speech
        window.speechSynthesis.cancel();
        setStatus("stopped");
        // Start speaking the text again
        handlePlayClick();
    };

    const handleContinueClick = () => {
        // Continue the speech
        window.speechSynthesis.resume();
        setStatus("playing");
    };

    // const { speak } = useSpeechSynthesis()
    // const text = replacedText;

    const download = async (src) => {
        const link = document.createElement('a');
        link.href = src;
        link.download = 'Download.svg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    return (

        <div id={`main-page-${pg}`}>

            {data.img ? (
                <div className='relative w-full h-full mt-8 mb-0 px-4 lg:mt-0 lg:mb-4 lg:px-0'>
                    <img className='w-full h-full border-[1px] border-[#242424]' src={data.img} />
                    <div className='absolute bottom-0 right-5'>
                        <div className='flex items-center gap-2 mb-2 lg:gap-3 lg:mb-4'>
                            <a href={data.os_link} target='_blank'>
                                <div className='border-[2px] border-[#818181] rounded-full'>
                                    <img className='h-[25px]' src='img-opensea.png' />
                                </div>
                            </a>
                            <button className='border-[2px] border-[#818181] rounded-full' onClick={() => download(data.img)}>
                                <img src='download.svg' />
                            </button>
                        </div>
                    </div>
                </div>
            ) : null
            }


            <div className={`pb-16 p-5 border-b-[1px] border-[#CBCAC7] lg:mt-0 lg:mb-12 lg:p-24 lg:pt-16 lg:border-[1px] lg:border-[#CBCAC7] dark:border-[#303030] ${chno}`}>

                <div className='relative flex items-center justify-start gap-2'>

                    {/* {status !== "playing" ?
                        <button onClick={handlePlayClick} disabled={status === "playing"}
                            className=""
                        >
                            <img src="text-to-speech.svg" />
                        </button> : <button onClick={handlePauseClick} disabled={status !== "playing"}
                            className=""
                        >
                            <img src="text-to-speech_pause.svg" />
                        </button>}

                    <button onClick={handleContinueClick} disabled={status !== "paused"}
                        className=""
                    >
                        <img src="text-to-speech.svg" />
                    </button>

                    <button onClick={handleReplayClick} disabled={status === "stopped"}
                        className=""
                    >
                        <img src="text-to-speech_replay.svg" />
                    </button> */}


                    {/* <button onClick={() => speak({ text: text })}>Speak</button> */}

                    {/* <p>Current Page: {currentPage}</p> */}
                    <div>
                        {user ? 
                        <button onClick={() => handleBookmarkClick(pg, chno)} className='z-2 top-[-17px] right-0 lg:top-[-70px] lg:right-[-70px] lg:block absolute '>
                            {/* <img src={isActiveBookmark ? darkSide ? "dark_bookmark_selected.svg" : "bookmark_selected.svg" : darkSide ? "dark_bookmark_outline.svg" : "bookmark_outline.svg"} /> */}
                            <img src={darkSide ? isActiveBookmark ? "dark_bookmark_selected.svg" : "dark_bookmark_outline.svg" : isActiveBookmark ? "bookmark_selected.svg" : "bookmark_outline.svg"} />
                        </button> 
                        :
                        <Link to="/login">
                            <Tooltip title="Login to Bookmark this page" placement="top-end">
                                <button className='z-2 top-[-17px] right-0 lg:top-[-70px] lg:right-[-70px] lg:block absolute '>
                                    {/* <img src={isActiveBookmark ? darkSide ? "dark_bookmark_selected.svg" : "bookmark_selected.svg" : darkSide ? "dark_bookmark_outline.svg" : "bookmark_outline.svg"} /> */}
                                    <img src={darkSide ? isActiveBookmark ? "dark_bookmark_selected.svg" : "dark_bookmark_outline.svg" : isActiveBookmark ? "bookmark_selected.svg" : "bookmark_outline.svg"} />
                                </button>
                            </Tooltip>
                        </Link>
                        }
                    </div>
                </div>
                {/* <p style={{fontSize, fontStyle}}>{firstPart}<CharacterPopup />{secondPart}</p> */}
                {/* <p style={{fontSize, fontStyle}} dangerouslySetInnerHTML={{__html:[content]}}></p> */}
                
                <div className='lg:hidden'>
                    {data.img ? (
                        <AudioBook data={data} darkSide={darkSide} />
                    ) : null }
                </div>
            
                <div className='flex items-center justify-between'>
                        <div>
                            <p className='hidden mb-1 font-dm_mono leading-7 text-black opacity-30 text-[13px] font-medium lg:block lg:mt-10 dark:text-[#FAF8F1]'>CHAPTER {data.chapter_number}</p>
                            <p className='hidden mb-12 font-pusab uppercase leading-7 text-[#242424] text-[18px] font-medium lg:block dark:text-[#FAF8F1]'>{data.chapter_name}</p>
                        </div>

                        <div className='hidden lg:flex'>
                            {data.img ? (
                                <AudioBook data={data} darkSide={darkSide} />
                            ) : null }
                        </div>
                </div>
            
                

                <p className='hidden text-justify font-dm_mono text-[#242424] font-normal lg:block dark:text-[#FAF8F1]' style={{ fontSize, lineHeight: '1.8', fontFamily: fontStyle }}>
                    {replacedText}
                </p>

                <p className='hidden font-dm_mono font-normal text-[14px] text-[#242424] absolute right-32 lg:block dark:text-[#FAF8F1] 2xl:right-72'>{pg}</p>


                {/* mobile */}
                
                <p className='mt-8 font-dm_mono leading-7 text-black opacity-30 text-[14px] font-medium lg:hidden dark:text-[#FAF8F1]'>CHAPTER {data.chapter_number}</p>
                <p className='mb-10 font-pusab uppercase leading-7 text-[#242424] text-[16px] font-medium lg:hidden dark:text-[#FAF8F1]'>{data.chapter_name}</p>

                <p className='block text-justify font-dm_mono leading-7 text-[#242424] font-normal lg:hidden dark:text-[#FAF8F1]' style={{ fontSize: mobFontSize, lineHeight: '1.8', fontFamily: fontStyle }}>
                    {replacedText}
                </p>

                <p className='font-dm_mono font-normal text-[14px] text-[#242424] absolute right-8 lg:hidden dark:text-[#FAF8F1]'>{pg}</p>


            </div >

        </div>
    );
};

export default Page;