import React, { useState } from "react";
// import SignUp from './signup.jsx'
// import LogIn from './login.jsx'
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'

const Loginside = () => {
    // const [selectedTab, setSelectedTab] = useState("tab1");

    // const handleTabClick = (tab) => {
    //     setSelectedTab(tab);
    // };


    const [data, setData] = useState({
        email: "",
        password: ""
    })

    const [error, setError] = useState("")

    const handleChange = ({ currentTarget: input }) => {
        setData({ ...data, [input.name]: input.value })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const url = '/api/auth'
            const { data: res } = await axios.post(url, data);
            localStorage.setItem("token", res.data);
            window.location = '/setting';
        } catch (error) {
            if (error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message)
            }

        }

    }

    return (
        <div className='flex flex-col h-full justify-between'>
            <div className='lg:pt-16 lg:ml-16'>
                <Link to='/welcome'>
                    <div className="hover:-translate-x-2 transition-all duration-150 ease-linear">
                        <button>
                            <img className='hidden absolute -mt-16 -ml-12 lg:block' src="back-btn.svg" />
                        </button>
                    </div>
                </Link>   

                <p className='hidden font-pusab mb-4 text-[32px] text-[#242424] lg:block'>EXPLORE CHIMPVERSE IN A NEVER BEFORE EXPERIENCE</p>

                {/* <div className='flex items-center'>
                        <div className='flex items-center border-[1px] border-black mr-2 grow'>
                            <img className='p-3 bg-black' src='google.svg' />
                            <p className='pl-8 pr-16'>GOOGLE LOGIN</p>
                        </div>
                        <div className='flex items-center border-[1px] border-black grow'>
                            <img className='p-3 bg-black' src='guest.svg' />
                            <p className='pl-8 pr-16'>GUEST LOGIN</p>
                        </div>
                    </div> */}

                {/* <p className='mt-8 mb-8 text-center'>or</p> */}

                <div className="mt-6 mb-6 text-center lg:mt-20 lg:mb-12 lg:text-left">
                    <Link to="/login">
                        <button type="button" className="px-4 py-1 border-[1px] border-[#242424] rounded-md mr-3 bg-[#242424] text-[#fbfbfb] lg:py-2 lg:px-3 hover:-translate-y-0.5 ease-linear transition-all duration-150">
                            <div className="font-dm_mono font-medium text-[12px]">LOGIN</div>
                        </button>
                    </Link>

                    <Link to="/signup">
                        <button type="button" className="px-4 py-1 text-[#242424] border-[1px] border-[#242424] rounded-md lg:py-2 lg:px-3 hover:bg-[#fff] hover:-translate-y-0.5 ease-linear transition-all duration-150">
                            <div className="font-dm_mono font-medium text-[12px]">SIGNUP</div>
                        </button>
                    </Link>

                </div>

                <div>
                    <form onSubmit={handleSubmit} >
                        <div className='border-[1px] border-[#e6e6e6] bg-[#fbfbfb] grow mb-3'>
                            <input
                                type="email"
                                placeholder='email'
                                name='email'
                                onChange={handleChange}
                                value={data.email}
                                required
                                className='p-3 w-full font-dm_mono font-medium text-[16px] text-[#242424] text-center placeholder:text-center focus:outline-none shadow-sm'></input>
                        </div>
                        <div className='mb-3 border-[1px] border-[#e6e6e6] bg-[#fbfbfb] grow lg:mb-6'>
                            <input
                                type="password"
                                placeholder='password'
                                name='password'
                                onChange={handleChange}
                                value={data.password}
                                required
                                className='p-3 w-full font-dm_mono font-medium text-[16px] text-[#242424] text-center placeholder:text-center focus:outline-none shadow-sm'></input>
                        </div>
                        {error && <div className="mb-6 p-2 bg-red-400 text-white font-dm_mono font-normal text-[14px]">{error}</div>}
                        <button type="submit"
                            className='p-3 mb-1 bg-[#242424] font-dm_mono font-medium text-[15px] text-white text-center w-full shadow-sm hover:scale-105 ease-linear transition-all duration-150'>LOGIN</button>
                        <Link to='/forgot-password'>
                            {/* <p className='mt-2 mb-12 text-center font-dm_mono font-medium text-[12px] text-[#242424] lg:mt-4'>Forgot Password?</p> */}
                        </Link>
                    </form>
                </div>
            </div>

            <div className='hidden mt-20 flex items-end gap-2 md:mt-10 lg:ml-16 lg:items-start lg:flex'>
                <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
            </div>

        </div>
    );
};

export default Loginside;