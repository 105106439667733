import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import { nanoid } from 'nanoid';
import { Link } from 'react-router-dom'
import axios from 'axios'
import classnames from "classnames";
import AddNote from './addnote';
import FontSizeBtn from './font-size';
import FontStyleBtn from './font-style';
// import NoteList from './notelist';
import Note from './note'
import Data from '../data.json';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { VIEWS } from './navbar';
import MusicPlayerSlider from './music-player';
import MusicPopup from './music-popup';
import useSound from 'use-sound';
import boopSfx from '../music.mp3';
import { useTheme } from '@mui/material/styles';
import { DarkModeSwitch } from "react-toggle-dark-mode";
import Tooltip from '@mui/material/Tooltip';
import jwt_decode from 'jwt-decode';



const Sidebar = ({ activeView, handleFStyle, handleFSizeS, handleFSizeM, handleFSizeL, bookmark, handleChapterClick, jumpToPage, toggleDarkMode, darkSide }) => {

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    const { currentTarget } = event;
    setAnchorEl(prevAnchor => prevAnchor ? null : currentTarget);
    console.log(anchorEl);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const notelistClose = () => {
    setAnchorEl(open => !open)
  }


  const [profileEl, setProfileEl] = React.useState(null);

  const handleProfileClick = (event) => {
    const { currentTarget } = event;
    setProfileEl(prevProfile => prevProfile ? null : currentTarget);
    console.log(profileEl);
  };

  const openProfile = Boolean(profileEl);
  const Profile_id = openProfile ? 'simple-popper' : undefined;

  const profileClose = () => {
    setProfileEl(openProfilel => !openProfile)
  }


  const [addNoteEl, setAddNoteEl] = React.useState(null);

  const handleAddNoteClick = (event) => {
    setAddNoteEl(addNoteEl ? null : event.currentTarget);
  };

  const openAddNote = Boolean(addNoteEl);

  // const [noteText, setNoteText] = useState('');

  // const [input, setInput] = useState({
  //   note_content: '',
  // })

  // const handleNoteChange = (event) => {
  //   setNoteText(event.target.value);
  // }

  // const handleNoteChange = (event) => {
  //   const { name, value } = event.target;
  //   setInput(prevInput => {
  //     return {
  //       ...prevInput,
  //       [name]: value,
  //     }
  //   })
  // }

  // const handleSaveClick = () => {
  //   if (noteText.trim().length > 0) {
  //     addNote(noteText);
  //     setNoteText('');
  //   }
  // }

  // const handleSaveClick = (event) => {
  //   event.preventDefault();
  //   const newNote = {
  //     note_content: input.note_content,
  //   }

  //   axios.post('http://localhost:4000/api/create-note', newNote)
  // }


  const [error, setError] = useState("")
  const [note, setNote] = useState('');


  const handleSaveClick = async (event) => {
    event.preventDefault()
    let tokenStr = window.localStorage.getItem('token');
    try {
      const response = await axios.post('http://localhost:4000/api/create-note', { note }, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenStr}`
        }
      })
      setNote('');
      console.log(response.message);
    } catch (error) {
      if (error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message)
      }

    }
  }


  const [notesDirectory, setNotesDirectory] = useState([])

  const [userId, setUserId] = useState('');

  useEffect(() => {
    const token = window.localStorage.getItem('token');
    if (!token) return;

    try {
      const decodedToken = jwt_decode(token);
      const userId = decodedToken._id;
      setUserId(userId);
      console.log(userId);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let tokenStr = window.localStorage.getItem('token');
      try {
        const response = await axios.get(`/api/create-note/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${tokenStr}`
          }
        });
        setNotesDirectory(response.data);
        console.log(notesDirectory);
      } catch (error) {
        if (error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message)
        }
      }
    };
    fetchData();
  }, [userId])



  const handleDelete = async (id) => {
    console.log(id);
    let tokenStr = window.localStorage.getItem('token');
    try {
      const response = await axios.delete(`/api/create-note/${id}`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${tokenStr}`
        }
      });
      // setNotesDirectory(response.data);
      console.log(response);
    } catch (error) {
      if (error.response &&
        error.response.status >= 400 &&
        error.response.status <= 500
      ) {
        setError(error.response.data.message)
      }
    }
    window.location.reload();
  };


  const addNoteClose = () => {
    setAddNoteEl(openAddNote => !openAddNote)
  }


  const [bookmarkList, setBookmarkList] = useState([])

  useEffect(() => {
    const fetchData = async () => {
      let tokenStr = window.localStorage.getItem('token');
      try {
        const response = await axios.get(`/api/bookmark/${userId}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${tokenStr}`
          }
        });
        setBookmarkList(response.data);
        console.log(bookmarkList);
      } catch (error) {
        if (error.response &&
          error.response.status >= 400 &&
          error.response.status <= 500
        ) {
          setError(error.response.data.message)
        }
      }
    };
    fetchData();
  }, [userId])










  const [fontStyleEl, setFontStyleEl] = React.useState(null);

  const handleFontStyleClick = (event) => {

    setFontStyleEl(fontStyleEl ? null : event.currentTarget);
  };

  const openFontStyle = Boolean(fontStyleEl);

  const fontStyleClose = () => {
    setFontStyleEl(openAddNote => !openAddNote)
  }


  const [fontSizeEl, setFontSizeEl] = React.useState(null);

  const handleFontSizeClick = (event) => {

    setFontSizeEl(fontSizeEl ? null : event.currentTarget);
  };

  const openFontSize = Boolean(fontSizeEl);

  const fontSizeClose = () => {
    setFontSizeEl(openAddNote => !openAddNote)
  }



  // const [musicEl, setMusicEl] = React.useState(null);

  // const handleMusicClick = (event) => {
  //   setMusicEl(musicEl ? null : event.currentTarget);
  // };

  // const openMusic = Boolean(musicEl);
  // const musicId = openMusic ? 'simple-popper' : undefined;


  const [notes, setNotes] = useState([
    {
      id: nanoid(),
      pagenumber: 'CHI/P1',
      text: 'Lorem ipsum dolor sit amet consectetur. Viverra cursus semper pulvinar at est.',
    },
    {
      id: nanoid(),
      pagenumber: 'CHI/P2',
      text: 'Lorem ipsum consectetur. Viverra cursus semper pulvinar at est.',
    },
    {
      id: nanoid(),
      pagenumber: 'CHI/P3',
      text: 'Lorem ipsum dolor sit amet consectetur. semper pulvinar at est.',
    },
    {
      id: nanoid(),
      pagenumber: 'CHI/P4',
      text: 'Lorem ipsum dolor sit amet consectetur. Viverra cursus semper pulvinar at est.',
    },
    {
      id: nanoid(),
      pagenumber: 'CHI/P5',
      text: 'Lorem ipsum dolor sit amet consectetur. Viverra cursus semper pulvinar at est.',
    },
  ])

  const addNote = (text) => {
    const newNote = {
      id: nanoid(),
      pagenumber: 'CHI/P6',
      text: text
    }
    // const newNotes = [...notes, newNote];
    setNotes(prevNotes => [...prevNotes, newNote]);
  }

  const deleteNote = (id) => {
    // const newNotes = notes.filter((note) => note.id !== id);
    setNotes(prevNotes => prevNotes.filter((note) => note.id !== id));
  }

  const chapDetails = []
  const chapterSet = new Set()
  Data.forEach(
    (o, i) => {
      if (!chapterSet.has(o.chapter_number)) {
        chapterSet.add(o.chapter_number)
        chapDetails.push(
          {
            chapter_name: o.chapter_name,
            chapter_number: o.chapter_number,
            chapter_start_page: o.page_number,
            id: i
          }
        )
      }
    }
  );

  const buttonClasses = classNames({
    "flex items-center justify-center bg-[#EEEEEE] active:bg-[#242424] rounded-md hover:shadow-lg ease-linear transition-all duration-150 dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]": true,
    "bg-black dark:bg-[#FAF8F1]": open,
  })

  const addNoteClasses = classNames({
    "flex items-center justify-center bg-[#EEEEEE] w-full rounded-md hover:shadow-lg ease-linear transition-all duration-150 dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]": true,
    "bg-black dark:bg-[#FAF8F1]": openAddNote,
  })

  const [selectedButton, setSelectedButton] = useState("button1");

  const fontStyleClasses = classNames({
    "flex items-center justify-center bg-[#EEEEEE] rounded-md hover:shadow-lg outline-none ease-linear transition-all duration-150 dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]": true,
    "bg-black dark:bg-[#FAF8F1]": openFontStyle,
  })

  const fontSizeClasses = classNames({
    "flex items-center justify-center bg-[#EEEEEE] rounded-md hover:shadow-lg outline-none ease-linear transition-all duration-150 dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]": true,
    "bg-black dark:bg-[#FAF8F1]": openFontSize,
  })

  const [play, { stop }] = useSound(boopSfx);
  const [volume, setVolume] = useState(30);

  const handlePlay = () => {
    play();
  }

  const handlePause = () => {
    stop();
  }

  const handleVolumeChange = (event, newValue) => {
    setVolume(newValue);
  };

  const theme = useTheme();
  const duration = 200; // seconds
  const [position, setPosition] = React.useState(32);
  const [paused, setPaused] = React.useState(true);
  // function formatDuration(volume) {
  //     const minute = Math.floor(value / 60);
  //     const secondLeft = value - minute * 60;
  //     return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
  // }
  // const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';
  const mainIconColor = '#fff';
  const lightIconColor =
    theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';


  return (
    <main className="lg:border-[1px] lg:border-[#CBCAC7] lg:p-4 lg:w-72 lg:h-[89vh] lg:mt-4 lg:sticky lg:top-20 dark:border-[#303030]" >
      <div className="flex flex-col justify-between lg:h-[85vh]">

        <div>
          <div className="flex items-center justify-between mb-6 mt-4 px-4">
            {/* <img src="vector.svg"/> */}
            <p className='font-pusab font-normal text-[22px] text-[#242424] dark:text-[#FAF8F1]'>CHAPTERS</p>
          </div>

          {/*Chapter Names  */}
          {chapDetails.map((chapter, i) => {
            const isChapterSelected = chapter?.chapter_start_page === jumpToPage;
            return (
              <Fragment key={chapter.chapter_name}>
                <button id={i} className={classNames({
                  "flex mt-4 pt-2 px-4": !isChapterSelected,
                  "flex items-center mt-4 py-2 px-4 bg-[#242424] w-full rounded-md dark:bg-[#FAF8F1]": isChapterSelected
                })} onClick={() => { handleChapterClick(chapter?.chapter_start_page) }}>
                  <p className={classNames({ "text-white mr-4 font-dm_mono font-medium text-[14px] dark:text-[#242424]": isChapterSelected, "text-black opacity-50 mr-4 font-dm_mono font-medium text-[14px] dark:text-[#FAF8F1]": !isChapterSelected })}>{chapter?.chapter_number}</p>
                  <p className={classNames({ "text-white font-dm_mono font-medium text-[14px] dark:text-[#242424]": isChapterSelected, "text-black opacity-50 font-dm_mono font-medium text-[14px] dark:text-[#FAF8F1]": !isChapterSelected })}>{chapter?.chapter_name}</p>
                </button>

                <div className='flex items-center flex-wrap'>
                  {isChapterSelected && bookmarkList.map((b) => {
                    if (b.chno === chapter.chapter_number) {
                      return (
                        <div className='flex items-center border-[1px] border-[#CBCAC7] rounded p-1 pr-2 mt-2 mr-2 dark:border-[#303030]'>
                          <img className='ml-2' src={darkSide ? "white_bookmark.svg" : "bookmark.svg"} />
                          <p key={b._id} className='ml-2 text-[11px] font-normal font-dm_mono text-[#242424] dark:text-[#FAF8F1]'>Page {b.pg}</p>
                        </div>
                      )
                    }
                    return null;
                  })}
                </div>
              </Fragment>
            )
          })}

        </div>

        <div className='hidden lg:grid'>

          <div className='grid grid-cols-4 gap-2 px-2'>
            <div className='col-span-3 flex items-center justify-between grow'>
              {/* <AddNote handleAddNote={addNote} darkSide={darkSide} /> */}
              <button
                className={addNoteClasses}
                type="button"
                onClick={handleAddNoteClick}
              >
                <p className="font-dm_mono font-medium text-[14px] text-[#909090] py-[8px]">Write a note</p>
              </button>

              <Popper open={openAddNote} anchorEl={addNoteEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute', left: '30px !important', width: 'auto' }}>
                <Box className='px-4 py-4 mb-5 rounded-lg shadow-lg relative bg-white border-[1px] border-[#242424] dark:bg-[#282828] dark:border-[#FAF8F1]' >
                  <div >
                    <form>
                      <textarea
                        placeholder="Take a note..."
                        name="note_content"
                        // value={noteText}
                        // value={input.note_content}
                        value={note}
                        onChange={(e) => setNote(e.target.value)}
                        className="font-dm_mono text-[#242424] text-[14px] font-normal w-[225px] h-[120px] focus:outline-none resize-none mb-2 dark:bg-[#282828] dark:text-[#FAF8F1]"
                      >
                      </textarea>
                      <div className="flex justify-end mb-1 absolute bottom-0 right-1">
                        <Tooltip title="Save" placement="top-end">
                          <button onClick={handleSaveClick} >
                            <img src={darkSide ? "white_save-icon.svg" : "save-icon.svg"} />
                          </button>
                        </Tooltip>
                      </div>
                    </form>


                    <button
                      className="absolute top-2 right-2 ease-linear transition-all duration-150"
                      type="button"
                      onClick={addNoteClose}
                    >
                      <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                    </button>

                  </div>
                </Box>
              </Popper>
            </div>


            {/* <NoteList notes={notes} handleAddNote={addNote} handleDeleteNote={deleteNote} /> */}


            <button
              className={buttonClasses}
              type="button" onClick={handleClick}
            >
              <img className="h-5" src={open ? darkSide ? "search-note.svg" : "white_notelist.svg" : darkSide ? "white_notelist.svg" : "search-note.svg"} />
            </button>


            <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute', left: '0 !important', right: '0 !important', marginLeft: 'auto !important', marginRight: 'auto !important', width: '988px' }}>
              {/* <ClickAwayListener onClickAway={() => setAnchorEl(false)}> */}
              <Box className='pt-8 pb-10 mb-5 rounded-lg shadow-lg relative w-full bg-white border-[1px] border-[#242424] dark:bg-[#282828] dark:border-[#FAF8F1]' >
                <p className="font-dm_mono text-[16px] text-[#242424] font-medium px-6 mb-4 dark:text-[#FAF8F1]">
                  Notes Directory
                </p>

                {/* {notes.map((note) => <Note key={note.id} id={note.id} pg={note.pagenumber} text={note.text} handleDeleteNote={deleteNote} darkSide={darkSide} />)} */}
                {/* <AddNote handleAddNote={handleAddNote} /> */}

                {notesDirectory.length === 0 ? (
                  <p className='px-6 font-dm_mono font-normal text-[14px] text-[#242424] dark:text-[#FAF8F1]'>You have not taken any note yet!</p>
                ) : (
                  <div className="relative px-6 flex-auto">
                    <div className='grid grid-cols-3 gap-4'>
                      {notesDirectory.map((note, i) => (
                        // <p key={note._id}>{note.note}</p>
                        <div className='p-4 border-[1px] border-[#C9C9C7] rounded-lg mb-4 lg:bg-[#EEEEEE] lg:border-none lg:rounded lg:w-[300px] lg:mb-0 whitespace-pre-wrap dark:bg-[#101010] dark:border-[1px] dark:border-[#FAF8F1]'>
                          <div className='flex items-start justify-between'>
                            <div className='mb-4 bg-[#242424] px-2 py-1 rounded dark:bg-[#FAF8F1]'>
                              <p className='font-dm_mono font-medium text-[11px] text-[#eeeeee] dark:text-[#242424]'>Note {i + 1}</p>
                            </div>

                            <button onClick={() => handleDelete(note._id)}>
                              <img className='cursor-pointer' src={darkSide ? "white_delete-icon.svg" : "delete.svg"} />
                            </button>
                          </div>
                          <p key={note._id} className='font-dm_mono font-normal text-[14px] text-[#242424] dark:text-[#FAF8F1]'>{note.note}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <button
                  className="absolute top-2 right-2 ease-linear transition-all duration-150"
                  type="button"
                  onClick={notelistClose}
                >
                  <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                </button>
              </Box>
              {/* </ClickAwayListener> */}
            </Popper>
          </div>

          {/* functionality icons  */}
          <div className="mt-4 grid grid-cols-4 gap-2 px-2">
            <div className='flex items-center justify-center bg-[#eee] p-2 rounded-md dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]' >
              <DarkModeSwitch
                checked={darkSide}
                onChange={toggleDarkMode}
              >
              </DarkModeSwitch>
            </div>



            {/* <img className="px-4 py-3" src="music.svg" alt="" /> */}
            {/* <MusicPopup /> */}

            <button className='flex items-center justify-center bg-[#eeeeee] rounded-md dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]'
              aria-label={paused ? 'play' : 'pause'}
              onClick={() => setPaused(!paused)}
            >
              {paused ? (
                <div onClick={handlePlay} className='cursor-pointer' >
                  <img src={darkSide ? "white_music_pause.svg" : "music_pause.svg"} />
                </div>
              ) : (
                // <PauseRounded onClick={handlePause} sx={{ fontSize: '1.5rem' }} htmlColor={mainIconColor} />
                <div onClick={handlePause} className='cursor-pointer' >
                  <img src={darkSide ? "white_music_play.svg" : "music_play.svg"} />
                </div>
              )}
            </button>

            <button
              className={fontStyleClasses}
              type="button"
              disabled={activeView === VIEWS.horizontal}
              onClick={handleFontStyleClick}
            >
              {activeView === VIEWS.horizontal ? <img src={darkSide ? "black_blocked_fontstyle.svg" : "blocked_fontstyle.svg"} /> :
                <img className="h-[13px]" src={openFontStyle ? darkSide ? "fontstyle.svg" : "white_fontstyle.svg" : darkSide ? "white_fontstyle.svg" : "fontstyle.svg"} />
              }
            </button>

            <Popper open={openFontStyle} anchorEl={fontStyleEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute', left: '-40px !important', width: 'auto' }}>
              <Box >
                <div className="relative mb-20 w-[265px] lg:ml-8 lg:mr-4 lg:w-[265px] xl:ml-8 xl:mr-4">
                  {/*content*/}
                  <div className="rounded-lg shadow-lg relative w-full bg-white border-[1px] border-[#242424] outline-none focus:outline-none dark:bg-[#282828] dark:border-[#FAF8F1]">
                    {/*header*/}
                    <div className="px-5 pt-5 pb-3 rounded-t">
                      <p className="font-dm_mono text-[14px] text-[#242424] font-medium dark:text-[#FAF8F1]">
                        Change Font Style
                      </p>
                    </div>
                    {/*body*/}
                    <div className="relative px-5 pb-5 flex-auto">
                      <div className='flex items-center justify-between bg-red-600'>
                        <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center mr-2 rounded py-1 grow focus:bg-[#242424] focus:text-[#eee] dark:bg-[#101010] dark:border-[#303030] dark:text-[#FAF8F1] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1]' onClick={() => handleFStyle("DM Mono")}>FS 1</button>
                        <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center mr-2 rounded py-1 grow focus:bg-[#242424] focus:text-[#eee] dark:bg-[#101010] dark:border-[#303030] dark:text-[#FAF8F1] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1]' onClick={() => handleFStyle("Merriweather")}>FS 2</button>
                        <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center rounded py-1 grow focus:bg-[#242424] focus:text-[#eee] dark:bg-[#101010] dark:border-[#303030] dark:text-[#FAF8F1] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1]' onClick={() => handleFStyle("Work Sans")}>FS 3</button>
                      </div>
                    </div>
                    {/*footer*/}
                    <button
                      className="absolute top-2 right-2 ease-linear transition-all duration-150"
                      type="button"
                      onClick={fontStyleClose}
                    >
                      <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                    </button>
                  </div>
                </div>
              </Box>
            </Popper>


            <button
              className={fontSizeClasses}
              type="button"
              disabled={activeView === VIEWS.horizontal}
              onClick={handleFontSizeClick}
            >
              {activeView === VIEWS.horizontal ? <img src={darkSide ? "black_blocked_fontsize.svg" : "blocked_fontsize.svg"} /> :
                <img className="h-[13px]" src={openFontSize ? darkSide ? "font-size.svg" : "white_fontsize.svg" : darkSide ? "white_fontsize.svg" : "font-size.svg"} />
              }
            </button>

            <Popper open={openFontSize} anchorEl={fontSizeEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute', left: '-100px !important', width: 'auto' }}>
              <Box >
                <div className="relative mb-20 w-[265px] lg:ml-8 lg:mr-4 lg:w-[265px] xl:ml-8 xl:mr-4">
                  {/*content*/}
                  <div className="rounded-lg shadow-lg relative w-full bg-white border-[1px] border-[#242424] outline-none focus:outline-none dark:bg-[#282828] dark:border-[#FAF8F1]">
                    {/*header*/}
                    <div className="px-5 pt-5 pb-3 rounded-t">
                      <p className="font-dm_mono text-[14px] text-[#242424] font-medium dark:text-[#FAF8F1]">
                        Change Font Size
                      </p>
                    </div>
                    {/*body*/}
                    <div className="relative px-5 pb-5 flex-auto">
                      <div className='flex items-center justify-between'>
                        <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center mr-2 rounded py-1 grow focus:bg-[#242424] focus:text-[#eee] dark:bg-[#101010] dark:border-[#303030] dark:text-[#FAF8F1] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1]' onClick={handleFSizeS}>S</button>
                        <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center mr-2 rounded py-1 grow focus:bg-[#242424] focus:text-[#eee] dark:bg-[#101010] dark:border-[#303030] dark:text-[#FAF8F1] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1]' onClick={handleFSizeM}>M</button>
                        <button className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center rounded py-1 grow focus:bg-[#242424] focus:text-[#eee] dark:bg-[#101010] dark:border-[#303030] dark:text-[#FAF8F1] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1]' onClick={handleFSizeL}>L</button>
                      </div>
                    </div>
                    {/*footer*/}
                    <button
                      className="absolute top-2 right-2 ease-linear transition-all duration-150"
                      type="button"
                      onClick={fontSizeClose}
                    >
                      <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                    </button>
                  </div>
                </div>
              </Box>
            </Popper>


            {/* <FontStyleBtn handleFStyle={handleFStyle} disabled={activeView === VIEWS.horizontal} darkSide={darkSide} /> */}

            {/* <FontSizeBtn handleFSizeS={handleFSizeS} handleFSizeM={handleFSizeM} handleFSizeL={handleFSizeL} disabled={activeView === VIEWS.horizontal} darkSide={darkSide} /> */}
          </div>


          {/* user profile */}
          <div className="flex items-center justify-between mt-4 px-2">
            <div className="flex items-center">
              <div className="rounded-full">
                <img src='userprofile.svg' />
              </div>
              <p className="font-medium font-dm_mono text-[#242424] text-[15px] ml-4 dark:text-[#FAF8F1]">Chimp69</p>
            </div>

            <div>
              <button type="button" onClick={handleProfileClick}>
                <img src={darkSide ? "dark_union.svg" : "Union.svg"} />
              </button>

              <Popper open={openProfile} anchorEl={profileEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, left: '-113px !important', marginBottom: '14px !important', width: 'auto' }}>
                {/* <ClickAwayListener onClickAway={() => setAnchorEl(false)}> */}
                <Box className='px-6 pt-8 pb-4 mb-5 rounded-lg shadow-lg relative w-full bg-white border-[1px] border-[#242424] dark:bg-[#282828] dark:border-[#FAF8F1]' >
                  <div className='flex items-center px-6 py-2 bg-[#eeeeee] rounded-md' >
                    <img src="signout.svg" />
                    <p className='font-dm_mono font-medium text-[14px] text-[#242424] ml-6' >Sign Out</p>
                  </div>
                  <div className='flex items-center px-6 py-2 bg-[#eeeeee] rounded-md mt-2' >
                    <img src="login.svg" />
                    <p className='font-dm_mono font-medium text-[14px] text-[#242424] ml-6' >Login with email</p>
                  </div>
                  <p className='font-dm_mono font-medium text-[11px] text-[#909090] underline text-center mt-4' >Terms and Privacy</p>
                  <button
                    className="absolute top-2 right-2 ease-linear transition-all duration-150"
                    type="button"
                    onClick={profileClose}
                  >
                    <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                  </button>
                </Box>
                {/* </ClickAwayListener> */}
              </Popper>
            </div>
          </div>
        </div>

      </div>
    </main>
  );
};

export default Sidebar;