import React from "react";
import characterData from '../characterdata.json';

export default function CharacterPopup({ match }) {
  const [showModal, setShowModal] = React.useState(false);

  const characterName = 'Chimp'
  const charData = characterData.filter(el => characterName === el.character_name)

  return (
    <>
      <button className='font-medium underline'
        type="button"
        onClick={() => setShowModal(true)}
      >
        <span className="text-medium underline">{match}</span>
      </button>
      {showModal ? (
        <>
          <div
            className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-[500] outline-none focus:outline-none"
          >
            <div className="relative w-auto my-24 mx-12 lg:w-[650px]">
              {/*content*/}
              <div className="rounded-lg shadow-lg relative w-full bg-white outline-none focus:outline-none">

                {/*header*/}
                {/* <div className="p-3 pb-3 rounded-t"> */}
                {/* <p className="text-sm font-semibold">
                    Change Font Size
                  </p> */}
                {/* <button
                    className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button> */}
                {/* </div> */}


                {/*body*/}
                {
                  charData.map(cd => {
                    return (
                      <div className="relative py-6 px-6 flex-auto">
                        <div className='grid grid-cols-7'>
                          <div className="col-span-3">
                            <img className="h-full" src={cd.image} />
                          </div>
                          <div className="pl-4 col-span-4 flex flex-col justify-between">
                            <div>
                              <p className="text-[#242424] text-2xl">{cd.character_name}</p>
                              <p>Chimpers Genesis #{cd.number}</p>
                              <p className="mt-4 text-sm">{cd.description}</p>
                            </div>
                            <div className="flex items-end">
                              <a href=''><img className="w-[24px] h-[24px] mr-4" src="opensea.svg" /></a>
                              <a href=''><img className="w-[25px] h-[25px] mr-4" src="discord.svg" /></a>
                              <a href=''><img className="w-[25px] h-[25px]" src="twitter.svg" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }


                {/*footer*/}
                <div className="flex items-center justify-end rounded-b">
                  <button
                    className="absolute right-4 top-2 px-6 py-2 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    <img className="absolute top-0 w-[18px] h-[18px] " src="close-btn.svg" />
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
    </>
  );
}