import './style.css';
import React, { useEffect } from 'react';
import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'
import Sidebar from './components/series2/s2-chapters-sidebar';
import Search from './components/search.jsx';
import Navbar, { VIEWS } from './components/navbar.jsx';
import View1 from './components/series2/s2-view1';
import View2 from './components/series2/s2-view2'
import Data from './s2-data.json';
import View2Data from './s2-v2data.json'
import MobileNavbar from './components/mobile-navbar';
import ScrollIndicator from './components/scroll-indicator.jsx';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import SettingsDrawer from './components/mobile/settings-drawer.jsx'
import { nanoid } from 'nanoid';
import MobileAddNote from './components/mobile/s2-mobile-addnote';
import MobileNotelist from './components/mobile/s2-mobile-notelist'
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import { useMediaQuery } from '@mui/material';
import useDarkSide from './components/usedarkSide';
import Tooltip from '@mui/material/Tooltip';
import jwt_decode from 'jwt-decode';



const drawerWidth = 304;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: 0,
        ...(open ? {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: `${drawerWidth}px`,
        } : {}),
    }),
);

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    '@media (min-width: 1600px)': {
        right: 'auto',
        left: 'auto',
      },
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),

    ...(open && {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: `${drawerWidth}px`,
        '@media (min-width: 1600px)': {
            width: `calc(1600px - ${drawerWidth}px)`,
            right: 'auto',
          },
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));


const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));


const fontSizes = {
    small: { value: 20, title: 'Small' },
    medium: { value: 24, title: 'Medium' },
    large: { value: 28, title: 'Larget' },
}

// Object.values(fontSizes).map(option =>
//   <button key={option.value} onClick={() => handleFontChange(option.value)}>{option.title}</button>)

// const monekys = [{ id: 1, name: 'one', descripton: 'asfd' }, { id: 2, name: 'two', description: '' }];

// const [activeMoneky, setActiveMonkey] = useState(monkeys[0]);
// monekys.map(monkey => <div onClick={() => {
//   setActiveMonkey(monkey);
// }}>{monkey.name}</div>);

// { activeMoneky ? <MonkeyPage monkey={activeMonkey} /> : <div>empty</div> }

const S2Reader = ({user}) => {

    const theme = useTheme();
    const [open, setOpen] = React.useState(true);

    const handleDrawerOpen = () => {
        setOpen(true);
    };

    const handleDrawerClose = () => {
        setOpen(false);
    };


    const [fontSize, setFontSize] = useState(
        localStorage.getItem('fontSize') || '24px'
      );
    
      useEffect(() => {
        localStorage.setItem('fontSize', fontSize);
      }, [fontSize]);
    
      const handleSizeClick = (size) => {
        setFontSize(size);
      };



      const [fontStyle, setFontStyle] = useState(
        localStorage.getItem('fontStyle') || 'DM Mono'
      );
    
      useEffect(() => {
        localStorage.setItem('fontStyle', fontStyle);
      }, [fontStyle]);
    
      const handleStyleClick = (style) => {
        setFontStyle(style);
      };


      const [mobFontSize, setMobFontSize] = useState(
        localStorage.getItem('mobile-fontSize') || '20px'
      );
    
      useEffect(() => {
        localStorage.setItem('mobile-fontSize', mobFontSize);
      }, [mobFontSize]);
    
      const handleMobSizeClick = (size) => {
        setMobFontSize(size);
      };




    // const [fontSize, setFontSize] = useState(24);
    const [lineHeight, setLineHeight] = useState(60);
    // const [mobFontSize, setMobFontSize] = useState(18);
    // const [fontStyle, setFontStyle] = useState("DM Mono");
    const [active, setActive] = useState(VIEWS.vertical);
    const [bookmark, setBookmark] = useState([]);
    const [jumpToPage, setJumpToPage] = useState(1);
    const [scrollTop, setSCrollTop] = useState(0);
    const [isChapterOpen, setIsChapterOpen] = React.useState(false)
    const [isSettingsOpen, setIsSettingsOpen] = React.useState(false)
    const [isNotelistOpen, setIsNotelistOpen] = React.useState(false)
    const [isSearchOpen, setIsSearchOpen] = React.useState(false)

    const matches = useMediaQuery(theme.breakpoints.up('lg'));
    const isDesktop = !!matches;

    // const updateFontSize = (nextValue) => {
    //   setFontSize(nextValue);
    // }

    const handleFSizeS = () => {
        setFontSize(20)
        // setLineHeight(40)
    }

    const handleFSizeM = () => {
        setFontSize(24)
        // setLineHeight(60)
    }

    const handleFSizeL = () => {
        setFontSize(28)
        // setLineHeight(80)
    }

    const handleMFSizeS = () => {
        setMobFontSize(16)
        setLineHeight(30)
    }

    const handleMFSizeM = () => {
        setMobFontSize(18)
        setLineHeight(60)
    }

    const handleMFSizeL = () => {
        setMobFontSize(20)
        setLineHeight(90)
    }

    const handleFStyle = (newFont) => {
        setFontStyle(newFont)
    }


    // nextTab either be vertical or horizontal
    const handleView = (nextTab) => {
        setActive(nextTab);
    }

    const handleBookmark = (pg, chapter_no) => {
        const newBookmark = {
            page_number: pg,
            chapter_number: chapter_no
        }
        const exists = bookmark.find((b) => b.page_number === pg)
        if (exists) return handleDeleteBookmark(pg);
        const newBookmarks = [...bookmark, newBookmark]
        setBookmark(newBookmarks)
    }

    const handleDeleteBookmark = (pg) => {
        const newBookmarks = bookmark.filter((b) => b.page_number !== pg)
        setBookmark(newBookmarks)
        console.log(setBookmark)
    }

    const handleChapterClick = (value) => {
        setJumpToPage(value);
    }


    const onScroll = () => {
        const winScroll = document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        setSCrollTop(scrolled);
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const toggleChapterDrawer = () => {
        setIsChapterOpen((prevState) => !prevState)
    }

    const toggleSettingsDrawer = () => {
        setIsSettingsOpen((prevState) => !prevState)
    }

    const toggleNotelistDrawer = () => {
        setIsNotelistOpen((prevState) => !prevState)
    }

    const toggleSearchDrawer = () => {
        setIsSearchOpen((prevState) => !prevState)
    }

    const [notes, setNotes] = useState([])

    const addNote = (text) => {
        const newNote = {
            id: nanoid(),
            pagenumber: 'CHI/P6',
            text: text
        }
        const newNotes = [...notes, newNote];
        setNotes(newNotes);
    }

    const deleteNote = (id) => {
        const newNotes = notes.filter((note) => note.id !== id);
        setNotes(newNotes);
    }


    const [colorTheme, setTheme] = useDarkSide();
    const [darkSide, setDarkSide] = useState(
        colorTheme === "light" ? true : false
    );

    const toggleDarkMode = (checked) => {
        setTheme(colorTheme);
        setDarkSide(checked);
    };


    // const Appstyle = {
    //     boxShadow: 'none',
    //     // Adding media query..
    //     '@media (min-width: 1600px)': {
    //       right: 'auto',
    //     },
    //   };


    const [notesDirectory, setNotesDirectory] = useState([])
    const [error, setError] = useState("")
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const token = window.localStorage.getItem('token');
        if (!token) return;

        try {
        const decodedToken = jwt_decode(token);
        const userId = decodedToken._id;
        setUserId(userId);
        console.log(userId);
        } catch (err) {
        console.error(err);
        }
    }, []);

    useEffect(() => {
        const fetchData = async () => {
        let tokenStr = window.localStorage.getItem('token');
        try {
            const response = await axios.get(`/api/s2-create-note/${userId}`, {
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenStr}`
            }
            });        
            // setNotesDirectory([...notesDirectory, newNote]);
            setNotesDirectory(response.data);
            console.log(notesDirectory);
        } catch (error) {
            if (error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
            ) {
            setError(error.response.data.message)
            }
        }
        };
        fetchData();
    }, [userId])


    function handleNoteAdd(newNote) {
        setNotesDirectory([...notesDirectory, newNote]);
    }


    const handleBackendNoteDelete = async (id) => {
          console.log(id);
          let tokenStr = window.localStorage.getItem('token');
          try {
            const response = await axios.delete(`/api/s2-create-note/${id}`, {
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenStr}`
              }
            });
            setNotesDirectory(notesDirectory.filter((note) => note._id !== id));
            // setNotesDirectory(response.data);
            console.log(response);
          } catch (error) {
            if (error.response &&
              error.response.status >= 400 &&
              error.response.status <= 500
            ) {
              setError(error.response.data.message)
            }
          }
        //   window.location.reload();
        };



        const [isActiveBookmark, setIsActiveBookmark] = useState(false);
        const [bookmarkList, setBookmarkList] = useState([])

        useEffect(() => {
            const fetchData = async () => {
            let tokenStr = window.localStorage.getItem('token');
            try {
                const response = await axios.get(`/api/s2-bookmark/${userId}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${tokenStr}`
                }
                });
                setBookmarkList(response.data);
                console.log(bookmarkList);
            } catch (error) {
                if (error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
                ) {
                setError(error.response.data.message)
                }
            }
            };
            fetchData();
        }, [userId])


        function handleBookmarkAdd(newBookmark) {
            setBookmarkList([...bookmarkList, newBookmark]);
            setIsActiveBookmark(!isActiveBookmark);
        }

        function handleBookmarkDelete(bookmark) {
            setBookmarkList(bookmarkList.filter((b) => b._id !== bookmark._id));
            setIsActiveBookmark(!isActiveBookmark); 
            console.log(bookmarkList);
        }





    return (
        <>
            {isDesktop && (
                <>
                    <Box className='bg-[#FAF8F1] flex dark:bg-black' style={{ backgroundImage: "url('main-bg-img.svg')" }}>
                        <body className='max-w-[1600px] mx-auto ' >
                            <div className='flex'>

                                <AppBar position="fixed" open={open} style={{ boxShadow: 'none', zIndex: '10 !important' }} className='appbar max-w-[1600px]'>
                                    <div className='flex items-center justify-between pt-5 pb-4 px-4 sticky top-0 bg-[#FAF8F1] dark:bg-black bg-cover' style={{ backgroundImage: "url('appbar-bg-img.svg')", zIndex: '10 !important' }}>
                                        <div className='flex items-center'>
                                            <button
                                                aria-label="open drawer"
                                                onClick={() => setOpen(prev => !prev)}
                                                edge="start"
                                                sx={{ ...(open && { display: 'none' }) }}
                                                className='border-[1px] border-[#CBCAC7] p-[13px] mr-4 dark:border-[#303030] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1] ease-linear transition-all duration-150'>
                                                <img src={open ? darkSide ? "dark_closedrawer.svg" : "closedrawer.svg" : darkSide ? "dark_opendrawer.svg" : 'opendrawer.svg'} />
                                            </button>

                                            <Search darkSide={darkSide} placeholder="Search here.." Data={Data} />
                                        </div>

                                        <Navbar active={active} handleView={handleView} darkSide={darkSide} />
                                    </div>
                                </AppBar>

                                <Drawer
                                    sx={{
                                        width: drawerWidth,
                                        flexShrink: 0,
                                        '& .MuiDrawer-paper': {
                                            width: drawerWidth,
                                            boxSizing: 'border-box',
                                        },
                                    }}
                                    variant={"persistent"}
                                    anchor="left"
                                    open={open}
                                    overlayOpacity='0'
                                    zIndex='-10'
                                >
                                    <div className='bg-[#FAF8F1] border-none pl-4 h-[100vh] dark:bg-black bg-cover' style={{ backgroundImage: darkSide ? "url('sidebar-bg-img.svg')" : "url('sidebar-bg-img.svg')" }}>
                                        <Tooltip title="Go to Home Page" placement="right">
                                            <Link to='/stories'>
                                                <button className='mt-[14px] hover:-translate-y-0.5 ease-linear transition-all duration-150'>
                                                    <img className='sticky top-4 h-[50px] w-[50px]' src="chimp-logo.png" />
                                                </button>
                                            </Link>
                                        </Tooltip>
                                        <Sidebar activeView={active} bookmarkList={bookmarkList} handleSizeClick={handleSizeClick} handleStyleClick={handleStyleClick} handleFStyle={handleFStyle} fontStyle={fontStyle} handleFSizeS={handleFSizeS} handleFSizeM={handleFSizeM} handleFSizeL={handleFSizeL} fontSize={fontSize} bookmark={bookmark} handleChapterClick={handleChapterClick} jumpToPage={jumpToPage} toggleDarkMode={toggleDarkMode} darkSide={darkSide} user={user}/>
                                    </div>
                                </Drawer>

                                <Main className='px-4 pt-20 bg-[#FAF8F1] dark:bg-black' style={{ backgroundImage: "url('main-bg-img.svg')", zIndex: '1'}} open={open} >
                                    <div>
                                        {active === VIEWS.vertical && <View1
                                            fontSize={fontSize}
                                            lineHeight={lineHeight}
                                            fontStyle={fontStyle}
                                            Data={Data}
                                            // Data={handleSearchNote}
                                            handleBookmark={handleBookmark}
                                            jumpToPage={jumpToPage}
                                            darkSide={darkSide}
                                            user={user}
                                            onBookmarkAdd={handleBookmarkAdd}
                                            onBookmarkDelete={handleBookmarkDelete}
                                            isActiveBookmark={isActiveBookmark}
                                            bookmarks={bookmarkList}
                                            setBookmarks={setBookmarkList}
                                        />}
                                    </div>

                                    <div>
                                        {active === VIEWS.horizontal && <View2
                                            fontSize={fontSize}
                                            fontStyle={fontStyle}
                                            lineHeight={lineHeight}
                                            View2Data={View2Data}
                                            handleBookmark={handleBookmark}
                                            jumpToPage={jumpToPage}
                                            darkSide={darkSide}
                                            open={open}
                                            user={user}
                                            onBookmarkAdd={handleBookmarkAdd}
                                            onBookmarkDelete={handleBookmarkDelete}
                                            isActiveBookmark={isActiveBookmark}
                                            bookmarks={bookmarkList}
                                            setBookmarks={setBookmarkList}
                                        />}
                                    </div>
                                </Main>
                            </div>
                        </body>
                    </Box>
                </>
            )}



            <div className='bg-[#FAF8F1]'>

                {/* Mobile View */}
                {!isDesktop && (
                    <div className='block'>

                        <MobileNavbar toggleChapterDrawer={toggleChapterDrawer} toggleSearchDrawer={toggleSearchDrawer} toggleNotelistDrawer={toggleNotelistDrawer} toggleSettingsDrawer={toggleSettingsDrawer} darkSide={darkSide} />

                        <Drawer open={isChapterOpen} onClose={toggleChapterDrawer} direction='top' size='600' className='z-[1101] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'>
                            <div className='w-full h-[1px] bg-[#35383A] mt-16'></div>
                            <div className='p-5 bg-[#FAF8F1] dark:bg-[#242424]'>
                                <Sidebar bookmarkList={bookmarkList} handleFStyle={handleFStyle} handleFSizeS={handleFSizeS} handleFSizeM={handleFSizeM} handleFSizeL={handleFSizeL} bookmark={bookmark} handleChapterClick={handleChapterClick} jumpToPage={jumpToPage} darkSide={darkSide} />
                            </div>
                        </Drawer>

                        <Drawer open={isSearchOpen} onClose={toggleSearchDrawer} direction='top' size='600' className='z-[1101] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'>
                            <div className='w-full h-[1px] bg-[#35383A] mt-16'></div>
                            <div className='py-8 bg-[#FAF8F1] dark:bg-[#242424]'>
                                <Search darkSide={darkSide} placeholder="Search here.." Data={Data} />
                            </div>
                        </Drawer>

                        <Drawer open={isNotelistOpen} onClose={toggleNotelistDrawer} direction='top' size='600' variant="persistent" className='z-[1101] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'>
                            <div className='w-full h-[1px] bg-[#35383A] mt-16'></div>
                            <div className='p-5 bg-[#FAF8F1] dark:bg-[#242424]' style={{ height: `calc(100vh - 65px)`, overflow: 'auto' }}>
                                <MobileNotelist notesDirectory={notesDirectory} onNoteDelete={handleBackendNoteDelete} notes={notes} handleAddNote={addNote} handleDeleteNote={deleteNote} darkSide={darkSide} user={user} />
                            </div>
                        </Drawer>

                        <Drawer open={isSettingsOpen} onClose={toggleSettingsDrawer} direction='top' size='600' className='z-[1101] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'>
                            <div className='w-full h-[1px] bg-[#35383A] mt-16'></div>
                            <div className='p-5 bg-[#FAF8F1] dark:bg-[#242424]'>
                                <SettingsDrawer user={user} handleMobSizeClick={handleMobSizeClick} handleStyleClick={handleStyleClick} handleMFSizeS={handleMFSizeS} handleMFSizeM={handleMFSizeM} handleMFSizeL={handleMFSizeL} mobFontSize={mobFontSize} handleFStyle={handleFStyle} fontStyle={fontStyle} toggleDarkMode={toggleDarkMode} darkSide={darkSide} />
                            </div>
                        </Drawer>

                        <div>
                            {active === VIEWS.vertical && <View1
                                fontSize={fontSize}
                                mobFontSize={mobFontSize}
                                lineHeight={lineHeight}
                                fontStyle={fontStyle}
                                Data={Data}
                                // Data={handleSearchNote}
                                handleBookmark={handleBookmark}
                                jumpToPage={jumpToPage}
                                darkSide={darkSide}
                                user={user}
                                onBookmarkAdd={handleBookmarkAdd}
                                onBookmarkDelete={handleBookmarkDelete}
                                isActiveBookmark={isActiveBookmark}
                                bookmarks={bookmarkList}
                                setBookmarks={setBookmarkList}
                            />}
                        </div>

                        <div className='fixed bottom-3 right-3'>
                            <MobileAddNote handleAddNote={addNote} darkSide={darkSide} user={user} onNoteAdd={handleNoteAdd} />
                        </div>

                    </div>

                )}


            </div>
        </>
    );
};

export default S2Reader;
