import React from 'react';

const ScrollIndicator = ({ scrollTop }) => {
    return (
        <>

            <div className='hidden progressMainWrapper sticky top-20 h-[89vh] z-1 bg-[#CBCAC7] w-1 mb-4 rounded lg:block dark:bg-[#303030]'>
                <div style={{ height: `${scrollTop}%` }} className='progressMainStyle bg-[#242424] w-1 z-2 rounded dark:bg-[#FAF8F1]'></div>
            </div>


            {/* mobile optimization */}
            <div className='block progressMainWrapper sticky top-16 h-[2px] z-1 bg-[#E0DED8] w-full mb-2 rounded lg:hidden dark:bg-[#303030]'>
                <div style={{ width: `${scrollTop}%` }} className='progressMainStyle bg-[#242424] h-1 z-2 rounded dark:bg-[#FAF8F1]'></div>
            </div>

        </>
    );
};

export default ScrollIndicator;