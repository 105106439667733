import React from 'react';
import Drawer from 'react-modern-drawer';
import Box from '@mui/material/Box';

const ExplorerDrawer = ({ open, onClose }) => {
  return (
    <Drawer
      open={open}
      onClose={onClose}
      className='z-[11] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'
      direction='top'
      size='600'
    >
      <div className='w-full h-[1px] bg-[#E0DED8] mt-16'></div>
      <Box className='grid grid-cols-2 gap-4 bg-[#FAF8F1] px-4 py-8'>
        <a href='https://chimpers.com/news' target='_blank'>
          <button className='w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden'>
            <img className='h-[45px] p-2' src='news.webp' alt='news' />
            <div className='pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]'>
              <p className='font-dm_mono font-medium text-[14px] text-[#242424]'>NEWS</p>
            </div>
          </button>
        </a>
        <a href='https://marketplace.chimpers.com' target='_blank'>
          <button className='w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden'>
            <img className='h-[45px] p-2' src='marketplace.webp' alt='news' />
            <div className='pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]'>
              <p className='font-dm_mono font-medium text-[14px] text-[#242424]'>MARKETPLACE</p>
            </div>
          </button>
        </a>
        <a href='https://dojo.chimpers.xyz' target='_blank'>
          <button className='w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden'>
            <img className='h-[45px] p-2' src='dojo.webp' alt='news' />
            <div className='pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]'>
              <p className='font-dm_mono font-medium text-[14px] text-[#242424]'>DOJO</p>
            </div>
          </button>
        </a>
        <a href='https://chimpers.com/arcade' target='_blank'>
          <button className='w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden'>
            <img className='h-[45px] p-2' src='arcade.webp' alt='news' />
            <div className='pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]'>
              <p className='font-dm_mono font-medium text-[14px] text-[#242424]'>GAMES</p>
            </div>
          </button>
        </a>
        <a href='https://chimpers.myshopify.com' target='_blank'>
          <button className='w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden'>
            <img className='h-[45px] p-2' src='shop.webp' alt='news' />
            <div className='pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]'>
              <p className='font-dm_mono font-medium text-[14px] text-[#242424]'>SHOP</p>
            </div>
          </button>
        </a>
        <a href='https://chimpers.com/community' target='_blank'>
          <button className='w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden'>
            <img className='h-[45px] p-2' src='community.webp' alt='news' />
            <div className='pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]'>
              <p className='font-dm_mono font-medium text-[14px] text-[#242424]'>COMMUNITY</p>
            </div>
          </button>
        </a>
      </Box>
      <div className='grid grid-cols-3 border-t-[1px] border-[#E0DED8]'>
        <a
          className='mx-auto'
          href='https://opensea.io/collection/chimpersnft'
          target='_blank'
          rel='noopener noreferrer'
        >
          <button className='py-4'>
            <img className='mx-auto' src='char_opensea.svg' />
          </button>
        </a>
        <a href='https://discord.com/invite/chimpers' target='_blank' rel='noopener noreferrer'>
          <button className='h-full w-full py-4 border-l-[1px] border-r-[1px] border-[#E0DED8]'>
            <img className='mx-auto' src='char_discord.svg' />
          </button>
        </a>
        <a className='mx-auto' href='https://twitter.com/ChimpersNFT' target='_blank' rel='noopener noreferrer'>
          <button className='py-4'>
            <img className='' src='char_twitter.svg' />
          </button>
        </a>
      </div>
    </Drawer>
  );
};

export default ExplorerDrawer;
