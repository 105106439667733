import React from 'react';
import MNavbar from './components/setting/main-navbar.jsx';
import { Link } from 'react-router-dom';
import ExplorerDrawer from './components/mobile/explorer-drawer.jsx';

const Stories = () => {

    const [isExploreOpen, setIsExploreOpen] = React.useState(false)

    const toggleExploreDrawer = () => {
        setIsExploreOpen((prevState) => !prevState)
    }

    return (
        <div className='bg-[#faf8f1] h-full bg-cover lg:h-[100vh]' style={ { backgroundImage: "url('main-bg-img2.png')" } }>
            <div className='h-full flex flex-col justify-between max-w-[1600px] lg:h-[100vh] lg:pt-4 lg:w-[97%] lg:mx-auto'>
                <div>
                    <MNavbar toggleExploreDrawer={ toggleExploreDrawer } />
                    <ExplorerDrawer open={ isExploreOpen } onClose={ toggleExploreDrawer } />

                    {/* <Drawer open={ isExploreOpen } onClose={ toggleExploreDrawer } direction='top' size='600' className='z-[11] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'>
                        <div className='w-full h-[1px] bg-[#E0DED8] mt-16'></div>
                        <Box className="grid grid-cols-2 gap-4 bg-[#FAF8F1] px-4 py-8">
                            <a href='https://medium.com/@ChimpersNFT' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="news.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424]" >NEWS/INFO</p>
                                    </div>
                                </button>
                            </a>
                            <a href='https://marketplace.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="marketplace.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >MARKETPLACE</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://dojo.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="dojo.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >DOJO</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://www.chimpers.xyz/leaderboard' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="leaderboard.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >LEADERBOARD</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://arcade.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="game.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >GAMES</p>

                                    </div>
                                </button>
                            </a>
                        </Box>

                        <div className='grid grid-cols-3 border-t-[1px] border-[#E0DED8]' >
                            <a className='mx-auto' href='https://opensea.io/collection/chimpersnft' target='_blank'>
                                <button className='py-4 mx-auto'>
                                    <img className='' src="char_opensea.svg" />
                                </button>
                            </a>
                            <a href='https://discord.com/invite/chimpers' target='_blank'>
                                <button className='h-full w-full py-4 border-l-[1px] border-r-[1px] border-[#E0DED8]'>
                                    <img className='mx-auto' src="char_discord.svg" />
                                </button>
                            </a>
                            <a className='mx-auto' href='https://twitter.com/ChimpersNFT' target='_blank'>
                                <button className='py-4 mx-auto'>
                                    <img className='' src="char_twitter.svg" />
                                </button>
                            </a>
                        </div>
                    </Drawer> */}

                    <p className='mt-12 ml-4 mb-5 font-pusab text-[#242424] text-[20px] text-left lg:block lg:mt-20 lg:text-center lg:ml-0'>WELCOME TO THE CHIMPVERSE</p>
                    <p className='mb-2 ml-4 font-dm_mono font-medium text-[14px] text-[#242424] text-left lg:block lg:text-center lg:ml-0 lg:mb-4'>A collection of adventure stories about <br />the Bored Apes and Chimpers!</p>
                    <p className='mb-8 ml-4 font-dm_mono font-medium text-[16px] text-[#242424] text-left lg:block lg:text-center lg:ml-0 lg:mb-12'><u>Author</u>: <b>0xmoonvibe</b></p>

                    <div className='w-[96%] mx-auto px-2 mb-6 grid grid-cols-1 lg:grid-cols-3 gap-8 lg:grid lg:px-0 lg:mt-20'>

                        <Link to='/reader'>
                            <div className='w-full h-[80%]'>
                                <div className='relative overflow-hidden border-[1px] border-[#242424]'>
                                    <img className='object-cover h-full w-full shadow-[0_15px_45px_-24px_rgb(0,0,0,0.3)] hover:scale-110 ease-linear transition-all duration-150' src='story2.png' />
                                </div>
                                <div className='w-full border-[1px] border-[#242424] p-6 bg-white'>
                                    <p className='font-dm_mono font-medium text-[13px] text-[#A5A5A5]'>CHIMPERS CHRONICLES SERIES 1</p>
                                    <p className='font-dm_mono font-medium text-[20px] text-[#242424]'>The Quest for the Diamond Fleece</p>
                                </div>
                            </div>
                        </Link>

                        <Link to='/s2-reader'>
                            <div className='relative h-[80%]'>
                                <div className='relative overflow-hidden border-[2px] border-[#242424]'>
                                    <img className='animate-pulse object-cover h-full w-full shadow-[0_15px_45px_-24px_rgb(0,0,0,0.3)] hover:scale-110 ease-linear transition-all duration-150' src='story1.png' />
                                </div>
                                <img className='animate-bounce absolute -top-2 -left-2' src='new-tag.svg' />
                                <div className='w-full border-[2px] border-[#242424] p-6 bg-white'>
                                    <p className='font-dm_mono font-medium text-[13px] text-[#A5A5A5]'>CHIMPERS CHRONICLES SERIES 2</p>
                                    <p className='font-dm_mono font-medium text-[20px] text-[#242424]'>The Ancient Chronicles</p>
                                </div>
                            </div>
                        </Link>

                        <Link to='/characters'>
                            <div className='h-[80%]'>
                                <div className='relative overflow-hidden border-[1px] border-[#242424]'>
                                    <img className='object-cover h-full w-full shadow-[0_15px_45px_-24px_rgb(0,0,0,0.3)] hover:scale-110 ease-linear transition-all duration-150' src='story3.png' />
                                </div>
                                <div className='w-full border-[1px] border-[#242424] p-6 bg-white'>
                                    <p className='font-dm_mono font-medium text-[13px] text-[#A5A5A5]'>BONUS</p>
                                    <p className='font-dm_mono font-medium text-[20px] text-[#242424]'>Chubby Chimpers Chronicles</p>
                                </div>
                            </div>
                        </Link>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default Stories;