import { useMediaQuery, useTheme } from '@mui/material';
import React, { Fragment, useEffect } from 'react';
import { useState } from 'react';
import Page from '../series2/s2-page';
import ScrollIndicator from '..//scroll-indicator.jsx';

const View1 = ({ Data, fontSize, lineHeight, mobFontSize, fontStyle, handleBookmark, jumpToPage, darkSide, user, onBookmarkAdd, onBookmarkDelete, isActiveBookmark, bookmarks, setBookmarks }) => {
    const theme = useTheme();

    const [scrollTop, setSCrollTop] = useState(0);
    const matches = useMediaQuery(theme.breakpoints.up('lg'));
    const isDesktop = !!matches;

    const onScroll = () => {
        const winScroll = document.documentElement.scrollTop;
        const height = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const scrolled = (winScroll / height) * 100;
        setSCrollTop(scrolled);
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);

        return () => window.removeEventListener("scroll", onScroll);
    }, [])

    const download = async (src) => {
        const link = document.createElement('a');
        link.href = src;
        link.download = 'Download.svg';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <div>

            {isDesktop ? (
                <div className="flex items-start flex-row gap-4">
                    <p className="leading-relaxed">
                        <div>
                            {
                                Data.map(data => {
                                    return (
                                        <Fragment>

                                            <div>

                                                {/* {data.img ? (
                                                    <div className='relative w-full h-full mt-8 mb-0 px-4 lg:mt-0 lg:mb-4 lg:px-0 lg:h-[455px]'>
                                                        <img className='w-full h-full border-[1px] border-[#242424]' src={data.img} />
                                                        <div className='absolute bottom-0 right-5'>
                                                            <button onClick={() => download(data.img)}>
                                                                <img src='download.svg' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : null
                                                } */}

                                                <Page data={data} pg={data.page_number} chno={data.chapter_number} content={data.content} fontSize={fontSize}
                                                    fontStyle={fontStyle} mobFontSize={mobFontSize} handleBookmark={handleBookmark} jumpToPage={jumpToPage} 
                                                    darkSide={darkSide} user={user} onBookmarkAdd={onBookmarkAdd} onBookmarkDelete={onBookmarkDelete} 
                                                    isActiveBookmark={isActiveBookmark} bookmarks={bookmarks} setBookmarks={setBookmarks} />

                                            </div>
                                        </Fragment>
                                    )
                                }
                                )
                            }
                        </div>

                    </p>

                    <ScrollIndicator scrollTop={scrollTop} />

                </div>

            ) :
                <div className="mt-0 dark:bg-[#242424]">
                    <p className="leading-relaxed">
                        <ScrollIndicator scrollTop={scrollTop} />
                        <div>
                            {
                                Data?.map(data => {
                                    return (
                                        <Fragment>

                                            <div>

                                                {/* {data.img ? (
                                                    <div className='relative w-full mt-8 mb-0 px-4 lg:mt-0 lg:mb-4 lg:px-0'>
                                                        <img className='border-[1px] border-[#242424]' src={data.img} />
                                                        <div className='absolute bottom-0 right-5'>
                                                            <button onClick={() => download(data.img)}>
                                                                <img src='download.svg' />
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : null
                                                } */}

                                                <Page data={data} pg={data.page_number} chno={data.chapter_number} content={data.content} fontSize={fontSize}
                                                    lineHeight={lineHeight} fontStyle={fontStyle} mobFontSize={mobFontSize} handleBookmark={handleBookmark} jumpToPage={jumpToPage}
                                                    darkSide={darkSide} user={user} onBookmarkAdd={onBookmarkAdd} onBookmarkDelete={onBookmarkDelete} 
                                                    isActiveBookmark={isActiveBookmark} bookmarks={bookmarks} setBookmarks={setBookmarks} />

                                            </div>
                                        </Fragment>
                                    )
                                }
                                )
                            }
                        </div>

                    </p>
                </div>}




        </div>

    );
};

export default View1;