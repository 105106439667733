import { useEffect, useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { Link } from 'react-router-dom';

const PasswordResetSide = () => {
	const [validUrl, setValidUrl] = useState(false);
	const [password, setPassword] = useState("");
	const [msg, setMsg] = useState("");
	const [error, setError] = useState("");
	const param = useParams();
	const url = `/api/password-reset/${param.id}/${param.token}`;

	useEffect(() => {
		const verifyUrl = async () => {
			try {
				await axios.get(url);
				setValidUrl(true);
			} catch (error) {
				setValidUrl(false);
			}
		};
		verifyUrl();
	}, [param, url]);

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const { data } = await axios.post(url, { password });
			setMsg(data.message);
			setError("");
			window.location = "/login";
		} catch (error) {
			if (
				error.response &&
				error.response.status >= 400 &&
				error.response.status <= 500
			) {
				setError(error.response.data.message);
				setMsg("");
			}
		}
	};

	return (
		<Fragment>
			{validUrl ? (
				<div className='flex flex-col h-full justify-between'>
				<div className='lg:pt-16 lg:ml-16'>
					<Link to='/login'>
						<div className="hover:-translate-x-2 transition-all duration-150 ease-linear">
							<button>
								<img className='hidden absolute -mt-16 -ml-12 lg:block' src="back-btn.svg" />
							</button>
						</div>
					</Link>   
	
					<p className='hidden font-pusab mb-4 text-[32px] text-[#242424] lg:block'>EXPLORE CHIMPVERSE IN A NEVER BEFORE EXPERIENCE</p>
	
					<p className="fot-dm_mono font-medium text-[16px] text-[#242424] dark:text-[#FAF8F1]">ADD NEW PASSWORD</p>
	
					<div>
						<form onSubmit={handleSubmit} >
							<div className='border-[1px] border-[#e6e6e6] bg-[#fbfbfb] grow mb-3'>
								<input
									type="password"
									placeholder="Password"
									name="password"
									onChange={(e) => setPassword(e.target.value)}
									value={password}
									required
									className='p-3 w-full font-dm_mono font-medium text-[16px] text-[#242424] text-center placeholder:text-center focus:outline-none shadow-sm'></input>
							</div>
							{error && <div className="mb-6 p-2 bg-red-400 text-white font-dm_mono font-normal text-[14px]">{error}</div>}
							{msg && <div className="mb-6 p-2 bg-red-400 text-white font-dm_mono font-normal text-[14px]">{msg}</div>}
							<button type="submit"
								className='p-3 mb-1 bg-[#242424] font-dm_mono font-medium text-[15px] text-white text-center w-full shadow-sm hover:scale-105 ease-linear transition-all duration-150'>SUBMIT</button>
							{/* <p className='mt-2 mb-12 text-center font-dm_mono font-medium text-[12px] text-[#242424] lg:mt-4'>Forgot Password?</p> */}
						</form>
					</div>
				</div>
	
				<div className='hidden mt-20 flex items-end gap-2 md:mt-10 lg:ml-16 lg:items-start lg:flex'>
					<div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
					<div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
					<div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
					<div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
				</div>
			</div>
			) : (
				<h1>404 Not Found</h1>
			)}
		</Fragment>
	);
};

export default PasswordResetSide;