import React, { useEffect } from 'react';
import ReactDOMServer from "react-dom/server"
import { useState } from 'react';
import CharacterPopup from './character-popup';
import Character1Popper from './character-popup/character1popper';
import reactStringReplace from 'react-string-replace';
import V2CharAudioBook from './v2-char-audio-book'


const CharacterView2Page = ({ View2CharacterData, pg, chno, left_content, right_content, fontSize, mobFontSize, fontStyle, handleBookmark, jumpToPage, darkSide, open }) => {

    let left_replacedText = reactStringReplace(left_content, 'Vyee', (match, i) => (
        <Character1Popper match={match} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Betty', (match, i) => (
        <Character1Popper match={match} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, 'Bitty', (match, i) => (
        <Character1Popper match={match} />
    ));

    left_replacedText = reactStringReplace(left_replacedText, '<br>', () => <br />)



    let right_replacedText = reactStringReplace(right_content, 'Vyee', (match, i) => (
        <CharacterPopup match={match} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Betty', (match, i) => (
        <Character1Popper match={match} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, 'Bitty', (match, i) => (
        <Character1Popper match={match} />
    ));

    right_replacedText = reactStringReplace(right_replacedText, '<br>', () => <br />)



    const handleClickScroll = (pg) => {
        const element = document.getElementById(`main-page-${pg}`);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => { if (pg === jumpToPage) { handleClickScroll(jumpToPage) } }, [jumpToPage])

    const [isActiveBookmark, setIsActiveBookmark] = useState(false);

    const toggleBookmark = () => {
        handleBookmark(pg, chno);
        setIsActiveBookmark(!isActiveBookmark);
    }


    return (
        <div className={`lg:grid lg:grid-cols-2 lg:gap-4 lg:mt-1 ${chno}`} id={`main-page-${pg}`}>
            <div className={open ? 'lg:p-[75px] border-[1px] border-[#CBCAC7] dark:border-[#303030] 2xl:p-[100px]' : 'lg:py-[76px] lg:px-[130px] border-[1px] border-[#CBCAC7] dark:border-[#303030] 2xl:py-[145px] 2xl:px-[165px]'}>

                <div className='hidden lg:flex'>
                    {View2CharacterData.img ? (
                        <V2CharAudioBook View2CharacterData={View2CharacterData} darkSide={darkSide} />
                    ) : null }
                </div>

                {View2CharacterData.img ? (
                    <div className='flex flex-col justify-start' >
                        <div className='flex items-center justify-between'>
                            <p className='hidden mt-10 mb-2 font-pusab uppercase text-left leading-7 text-[#242424] text-[24px] font-medium lg:block dark:text-[#FAF8F1]'>{View2CharacterData.character_name}</p>
                            {/* <a href={View2CharacterData.os_link}>
                                <button className='border-[1px] border-[#CBCAC7] rounded-md px-3 pt-3 pb-2 dark:border-[#515151]'>
                                    <img className='hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:-translate-y-0.5 ease-linear transition-all duration-150' src={darkSide ? "white-opensea.svg" : "opensea.svg"} />
                                </button>
                            </a> */}
                        </div>
                        {/* <p className='hidden font-dm_mono text-left leading-7 text-[#242424] opacity-70 text-[13px] font-medium lg:block dark:text-[#FAF8F1]'>CHIMPERS GENESIS #{View2CharacterData.number}</p> */}
                        <div className='my-10 border-[1px] border-[#242424]'>
                            <img className='h-[360px] float-left' src={View2CharacterData.img} />
                        </div>

                        <a href={View2CharacterData.os_link} target='_blank'>
                            <button className='mt-2 bg-[#eee] float-left text-[#242424] rounded-md py-2 px-14 dark:bg-[#515151] dark:text-[#eee] hover:bg-[#cbcbcb] dark:hover:bg-[#e1e1e1] dark:hover:text-[#242424] ease-linear transition-all duration-150'>
                                <p className='font-dm_mono font-medium text-[18px] text-[#24242]'>Opensea</p>
                            </button>
                        </a>
                        {/* <p className='hidden font-dm_mono text-left text-justify leading-9 text-[#242424] text-[18px] font-normal lg:block dark:text-[#FAF8F1]'>{View2CharacterData.description}</p> */}
                    </div>

                ) :
                    <p className='hidden text-justify font-dm_mono leading-9 text-[#242424] text-[18px] font-normal lg:block dark:text-[#FAF8F1]'>
                        {left_replacedText}
                    </p>
                }


            </div >

            <div className={open ? 'lg:p-[75px] border-[1px] border-[#CBCAC7] dark:border-[#303030] 2xl:p-[100px]' : 'lg:py-[76px] lg:px-[130px] border-[1px] border-[#CBCAC7] dark:border-[#303030] 2xl:py-[145px] 2xl:px-[165px]'}>

                {/* <div className='relative flex items-center justify-between'>
                    <div>
                        <button onClick={toggleBookmark} className='hidden absolute z-2 top-[-77px] right-[-50px] lg:block'>
                            <img src={isActiveBookmark ? darkSide ? "dark_bookmark_selected.svg" : "bookmark_selected.svg" : darkSide ? "dark_bookmark_outline.svg" : "bookmark_outline.svg"} />
                        </button>
                    </div>
                </div> */}

                <p className='hidden text-justify font-dm_mono leading-9 text-[#242424] text-[18px] font-normal lg:block dark:text-[#FAF8F1]'>
                    {right_replacedText}
                </p>
                <p className='hidden font-dm_mono font-normal text-[14px] text-[#242424] absolute right-14 bottom-10 lg:block dark:text-[#FAF8F1]'>{pg}</p>

            </div >
        </div>
    );
};

export default CharacterView2Page;