import classNames from 'classnames';
import React from 'react';
import { useState } from 'react';

export const VIEWS = {
    horizontal: 'horizontal',
    vertical: 'vertical',
};

const Navbar = ({ active, handleView, darkSide }) => {


    const onClick = (e) => {
        const { view } = e.currentTarget.dataset;
        handleView(view);
    }

    const button1classes = classNames({
        "ml-2 p-4 hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1] ease-linear transition-all duration-150": true,
        "bg-white border-[1px] border-[#242424]": active === VIEWS.vertical,
    });

    const button2classes = classNames({
        "ml-2 p-4 hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1] ease-linear transition-all duration-150": true,
        "bg-white border-[1px] border-[#242424]": active === VIEWS.horizontal,
    });

    return (
        <div className="flex items-center justify-between sticky top-4">

            <div className="flex items-center">
                {/* <p>Reader Mode</p> */}
                <button data-view={VIEWS.horizontal} onClick={onClick} className={button2classes}>
                    <img src={darkSide? active === VIEWS.horizontal ? "view1.svg" : "white_view1.svg" : "view1.svg"} alt="" />
                </button>
                <button data-view={VIEWS.vertical} onClick={onClick} className={button1classes}>
                    <img src={darkSide ? active === VIEWS.vertical ? "view2.svg" : "white_view2.svg" : active === VIEWS.vertical ? "view2.svg" : "view2.svg"} alt="" />
                </button>
            </div>

        </div>
    );
};

export default Navbar;