import React from 'react';
import { Link, useNavigate } from 'react-router-dom';


const WelcomeSide = ({ handleFSizeS, handleFSizeM, handleFSizeL, handleFStyle, toggleDarkMode, darkSide, handleButtonClick }) => {
    return (
        <div className='flex flex-col h-full justify-between'>
            <div className='mt-12 lg:mt-16 lg:ml-12'>
                <p className='hidden font-pusab mt-12 mb-7 text-[#242424] text-[32px] lg:block'>WELCOME</p>
                <p className='hidden mb-14 font-dm_mono font-medium text-[14px] text-[#242424] lg:block'>Welcome to the brand new e-reader where you can explore and learn more about all of the TBAC & Chimpers stories!</p>
                
                <Link to="/stories">
                    <button className=''>
                        <div className='flex items-center gap-8 border-[1px] border-[#242424] px-3 py-6 mb-8 shadow-[0_15px_25px_-24px_rgb(0,0,0,0.3)] hover:shadow-[0_15px_25px_-24px_rgb(0,0,0,0.3)] md:w-[550px] md:mx-auto lg:p-8 lg:pr-20 lg:w-[550px] hover:bg-[#fff] hover:border-[2px] focus:bg-[#fff] focus:border-[2px] transition-all ease-linear duration-150'>
                            <img src='guest-welcome-icon.svg'/>
                            <div>
                                <p className='font-dm_mono font-medium text-[15px] text-[#242424] text-left mb-1 lg:mb-3'>EXPLORE AS GUEST</p>
                                <p className='font-dm_mono font-light text-[14px] text-[#909090] text-left'>Your data won’t be saved, this is just to explore.</p>
                            </div>
                        </div>
                    </button>
                </Link>
               
                <Link to='/login'>
                    <button className=''>
                        <div className='flex items-center gap-8 border-[1px] border-[#242424] px-3 py-6 shadow-[0_15px_25px_-24px_rgb(0,0,0,0.3)] hover:shadow-[0_15px_25px_-24px_rgb(0,0,0,0.3)] md:w-[550px] md:mx-auto lg:p-8 lg:pr-20 lg:w-[550px] hover:bg-[#fff] hover:border-[2px] focus:bg-[#fff] focus:border-[2px] transition-all ease-linear duration-150'>
                            <img src='guest-welcome-icon.svg'/>
                            <div>
                                <p className='font-dm_mono font-medium text-[15px] text-[#242424] text-left mb-1 lg:mb-3'>LOGIN/SIGNUP WITH EMAIL</p>
                                <p className='font-dm_mono font-light text-[14px] text-[#909090] text-left'>Save your data & later login from any device </p>
                            </div>
                        </div>
                    </button>
                </Link>    
                
            </div>

            <div className='hidden mt-20 flex items-end gap-2 md:mt-10 lg:ml-12 lg:items-start lg:flex'>
                <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
            </div>

        </div>
    );
};

export default WelcomeSide;