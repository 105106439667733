import React, { useEffect } from 'react';
import { useState } from 'react';
import CharacterPopup from './character-popup';
import Character1Popper from './character-popup/character1popper';
import reactStringReplace from 'react-string-replace';
import CharAudioBook from './char-audio-book';

/**
 * /books/:bookId/vertical/:123
 */
// const download = async (src) => {
//     const link = document.createElement('a');
//     link.href = src;
//     link.download = 'Download.svg';
//     document.body.appendChild(link);
//     link.click();
//     document.body.removeChild(link);
// }



const CharacterPage = ({ characterdata, pg, fontSize, mobFontSize, fontStyle, lineHeight, handleBookmark, jumpToPage, darkSide }) => {
    useEffect(() => {
        console.log('page props: ', characterdata, pg);
    }, [])
    // const JsonContent = { content }
    // const givenWord = JSON.stringify(JsonContent);


    let replacedText = reactStringReplace(characterdata.story, 'Vyee', (match, i) => (
        <Character1Popper match={match} />
    ));

    replacedText = reactStringReplace(replacedText, 'Betty', (match, i) => (
        <Character1Popper match={match} />
    ));

    replacedText = reactStringReplace(replacedText, 'Bitty', (match, i) => (
        <Character1Popper match={match} />
    ));

    replacedText = reactStringReplace(replacedText, '<br>', () => <br />)


    // CURRENT PAGE
    // const [currentPage, setCurrentPage] = useState(1);

    // const pageHeight = 100;
    // const numberOfPages = Math.ceil(trialData.length / pageHeight);

    // const handlePageChange = (newPage) => {
    //     setCurrentPage(newPage);
    // }

    // useEffect(() => {{handlePageChange(currentPage)}}, [currentPage])




    // const toSearch = "Andeddo"
    // const target = givenWord.indexOf(toSearch);
    // const firstPart = givenWord.substring(12,target);
    // const secondPart = givenWord.substring(target + toSearch.length, givenWord.length-2)

    const handleClickScroll = (pg) => {
        const element = document.getElementById(`main-page-${pg}`);
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    useEffect(() => {
        if (pg === jumpToPage) {
            handleClickScroll(jumpToPage)
        }
    }, [jumpToPage])


    const [isActiveBookmark, setIsActiveBookmark] = useState(false);

    const toggleBookmark = () => {
        handleBookmark(pg, characterdata.charater_name);
        setIsActiveBookmark(!isActiveBookmark);
    }


    const [text, setText] = useState(replacedText);
    const [status, setStatus] = useState("stopped");
    const [utterance, setUtterance] = useState(null);

    const handlePlayClick = () => {
        // Create a new SpeechSynthesisUtterance object
        const utterance = new SpeechSynthesisUtterance(text);
        setUtterance(utterance);
        // Start speaking the text
        window.speechSynthesis.speak(utterance);
        setStatus("playing");
    };

    const handlePauseClick = () => {
        // Pause the speech
        window.speechSynthesis.pause();
        setStatus("paused");
    };

    const handleReplayClick = () => {
        // Stop the speech
        window.speechSynthesis.cancel();
        setStatus("stopped");
        // Start speaking the text again
        handlePlayClick();
    };

    const handleContinueClick = () => {
        // Continue the speech
        window.speechSynthesis.resume();
        setStatus("playing");
    };


    return (

        <div id={`main-page-${pg}`}>

            {characterdata.image ? (
                <div>

                    <div className='hidden relative grid grid-cols-5 gap-1 border-[1px] border-[#CBCAC7] lg:grid lg:mt-0 lg:mb-4 lg:p-4 lg:gap-12 dark:border-[#303030]'>
                        <div className='col-span-1' >
                            <img className='h-full border-[1px] border-[#242424]' src={characterdata.image} />
                        </div>
                        <div className='col-span-4 mt-12 flex flex-col items-start'>
                            <p className='font-pusab font-normal uppercase text-[24px] text-[#242424] dark:text-[#FAF8F1]' >{characterdata.character_name}</p>
                            <a href={characterdata.os_link} target='_blank'>
                                <button className='mt-6 bg-[#eee] text-[#242424] rounded-md py-2 px-14 dark:bg-[#515151] dark:text-[#eee] hover:bg-[#cbcbcb] dark:hover:bg-[#e1e1e1] dark:hover:text-[#242424] ease-linear transition-all duration-150'>
                                    <p className='font-dm_mono font-medium text-[18px] text-[#24242]'>Opensea</p>
                                    {/* <img className='hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:-translate-y-0.5 ease-linear transition-all duration-150' src={darkSide ? "white-opensea.svg" : "opensea.svg"} /> */}
                                </button>
                            </a>
                        </div>
                    </div>


                    {/* mobile */}
                    <div className='relative m-5 pb-6 border-[1px] border-[#CBCAC7] lg:hidden lg:mt-0 lg:mb-4 lg:p-3'>
                        <div className='flex items-center gap-4 px-7 pt-6' >
                            <div>
                                <img className='h-[90px] w-[90px] border-[1px] border-[#242424]' src={characterdata.image} />
                            </div>
                            <div className='flex flex-col gap-4 ml-4'>
                                <p className='font-pusab font-normal uppercase text-[18px] text-[#242424] dark:text-[#FAF8F1]'>{characterdata.character_name}</p>
                                <a href={characterdata.os_link} target='_blank'>
                                <button className='bg-[#eee] text-[#242424] mb-3 rounded-md px-14 py-2 dark:bg-[#515151] dark:text-[#eee]'>
                                    <p className='font-dm_mono font-bold text-[14px] text-[#242424]'>Opensea</p>
                                </button>
                            </a>
                            </div>
                        </div>                        
                    </div>

                </div>

            ) : null
            }

               
            <div className={`mb-12 pb-16 p-5 pt-6 border-b-[1px] border-[#CBCAC7] lg:mt-0 lg:p-24 lg:pt-20 lg:border-[1px] lg:border-[#CBCAC7] dark:border-[#303030] ${characterdata.charater_name}`}>

                <div className='relative flex items-center justify-start gap-2'>

                    {/* {status !== "playing" ?
                        <button onClick={handlePlayClick} disabled={status === "playing"}
                            className=""
                        >
                            <img src="text-to-speech.svg" />
                        </button> : <button onClick={handlePauseClick} disabled={status !== "playing"}
                            className=""
                        >
                            <img src="text-to-speech_pause.svg" />
                        </button>}

                    <button onClick={handleContinueClick} disabled={status !== "paused"}
                        className=""
                    >
                        <img src="text-to-speech.svg" />
                    </button>

                    <button onClick={handleReplayClick} disabled={status === "stopped"}
                        className=""
                    >
                        <img src="text-to-speech_replay.svg" />
                    </button> */}


                    {/* <p>Current Page: {currentPage}</p> */}
                    {/* <div>
                        <button onClick={toggleBookmark} className='hidden absolute z-2 top-[-85px] right-[-70px] lg:block'>
                            <img src={isActiveBookmark ? darkSide ? "dark_bookmark_selected.svg" : "bookmark_selected.svg" : darkSide ? "dark_bookmark_outline.svg" : "bookmark_outline.svg"} />
                        </button>
                    </div> */}
                </div>
                {/* <p style={{fontSize, fontStyle}}>{firstPart}<CharacterPopup />{secondPart}</p> */}
                {/* <p style={{fontSize, fontStyle}} dangerouslySetInnerHTML={{__html:[content]}}></p> */}


                <div className='lg:hidden'>
                    {characterdata.image ? (
                        <CharAudioBook characterdata={characterdata} darkSide={darkSide} />
                    ) : null }
                </div>
            
                <div className=''>
                        <div className='hidden lg:block lg:float-right'>
                            {characterdata.image ? (
                                <CharAudioBook characterdata={characterdata} darkSide={darkSide} />
                            ) : null }
                        </div>
                </div>

                {/* <div className='hidden lg:flex'>
                    {characterdata.image ? (
                        <CharAudioBook characterdata={characterdata} darkSide={darkSide} />
                    ) : null }
                </div> */}


                <p className='hidden text-justify font-dm_mono leading-9 text-[#242424] font-normal lg:block lg:mt-12 dark:text-[#FAF8F1]' style={{ fontSize, lineHeight: '1.8', fontFamily: fontStyle }}>
                    {replacedText}
                </p>

                <p className='hidden font-dm_mono font-normal text-[14px] text-[#242424] absolute right-32 lg:block dark:text-[#FAF8F1] 2xl:right-72'>{pg}</p>


                {/* mobile */}
                <p className='block text-justify font-dm_mono leading-7 text-[#242424] font-normal lg:hidden dark:text-[#FAF8F1]' style={{ fontSize: mobFontSize, lineHeight: '1.8', fontFamily: fontStyle }}>
                    {replacedText}
                </p>

                <p className='font-dm_mono font-normal text-[14px] text-[#242424] absolute right-8 lg:hidden dark:text-[#FAF8F1]'>{pg}</p>


            </div >
        
        </div>
    );
};

export default CharacterPage;