import React from "react";
import { useState } from 'react';
import axios from 'axios'
import Tooltip from '@mui/material/Tooltip';


export default function MobileAddNote({ handleAddNote, darkSide, user, onNoteAdd }) {
    const [showModal, setShowModal] = React.useState(false);
    const [noteText, setNoteText] = useState('');
    // const [message, setMessage] = useState("");
    const [showMessage, setShowMessage] = useState(false);


    const handleChange = (event) => {
        setNoteText(event.target.value);
    }

    const handleSaveClickGuest = () => {
        if (noteText.trim().length > 0) {
            handleAddNote(noteText);
            setNoteText('');
            // setMessage("Note Saved!");
            setShowMessage(true);
            setTimeout(() => setShowMessage(false), 1000);
        }
    }

    const [error, setError] = useState("")
    const [note, setNote] = useState('');


    const handleSaveClick = async (event) => {
        event.preventDefault()
        let tokenStr = window.localStorage.getItem('token');
        try {
        const response = await axios.post('/api/s2-create-note', { note }, {
            headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${tokenStr}`
            }
        })
        onNoteAdd(response.data);
        setNote('');
        // setMessage("Note Saved!");
        setShowMessage(true);
        setTimeout(() => setShowMessage(false), 1000);
        // console.log(response.message);
        } catch (error) {
        if (error.response &&
            error.response.status >= 400 &&
            error.response.status <= 500
        ) {
            setError(error.response.data.message)
        }

        }
    }

    return (
        <>
            <button
                className="rounded-full ease-linear transition-all duration-150"
                type="button"
                onClick={() => setShowModal(true)}
            >

                <img src={darkSide ? "white_mnote.svg" : "mnotesicon.svg"} />
            </button>
            {showModal ? (
                <>
                    <div
                        className="items-end flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div className="relative mb-20 mx-4 w-full lg:mb-52 lg:w-[255px] xl:mx-10">
                            {/*content*/}
                            <div className="rounded-lg relative w-full bg-white border-[1px] border-[#242424] outline-none focus:outline-none dark:bg-[#303030] dark:border-[#515151]">
                                {/*header*/}
                                <div className="p-2 rounded-t">
                                </div>
                                {/*body*/}
                                <div className="relative px-4 flex flex-col border-black">
                                
                                {user ?
                                <form>
                                    <textarea
                                        placeholder="Take your note..."
                                        name="note_content"
                                        value={note}
                                        onChange={(e) => setNote(e.target.value)}
                                        className="font-dm_mono text-[#242424] text-[14px] font-normal w-[225px] h-[120px] focus:outline-none resize-none mb-2 dark:bg-[#303030] dark:text-[#FAF8F1] placeholder-[#909090]"
                                    >
                                    </textarea>
                                    <div className="flex justify-end mb-1 absolute bottom-0 right-1">
                                        <Tooltip title="Save" placement="top-end">
                                        <button onClick={handleSaveClick} >
                                            {showMessage && <p className='mb-1 font-dm_mono font-normal text-[10px] text-[#242424]'>Note Saved!</p>}
                                            {/* {message && <p className='mb-1 font-dm_mono font-normal text-[10px] text-[#242424]'>{message}</p>} */}
                                            <img className="float-right" src={darkSide ? "white_save-icon.svg" : "save-icon.svg"} />
                                        </button>
                                        </Tooltip>
                                    </div>
                                </form>
                                :
                                <div className="relative px-4 flex flex-col border-black">
                                    <textarea className="font-dm_mono text-[#242424] text-[14px] font-normal h-[130px] focus:outline-none resize-none mb-2" placeholder="Take a note..."
                                        value={noteText} onChange={handleChange}
                                    >
                                    </textarea>
                                    <div className="flex justify-end mb-1 absolute bottom-0 right-1">
                                        <button onClick={handleSaveClickGuest} >
                                            {showMessage && <p className='mb-1 font-dm_mono font-normal text-[10px] text-[#242424]'>Note Saved!</p>}
                                            {/* {message && <p className='mb-1 font-dm_mono font-normal text-[10px] text-[#242424]'>{message}</p>} */}
                                            <img className="float-right" src="save-icon.svg" />
                                        </button>
                                    </div>
                                </div> 
                                }

                                </div>
                                {/*footer*/}
                                <div className="flex items-center justify-end rounded-b">
                                    <button
                                        className="text-red-500 background-transparent font-bold uppercase pr-4 absolute top-2 right-[-10px] text-sm outline-none focus:outline-none ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={() => setShowModal(false)}
                                    >
                                        <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                </>
            ) : null}
        </>
    );
}