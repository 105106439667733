import React, { Fragment } from 'react';
import { useState, useEffect } from 'react';
import { nanoid } from 'nanoid';
import classnames from "classnames";
import AddNote from './addnote';
import FontSizeBtn from './font-size';
import FontStyleBtn from './font-style';
// import NoteList from './notelist';
import Note from './note'
import CharacterData from '../characterdata.json';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';
import { VIEWS } from './navbar';
import MusicPlayerSlider from './music-player';
import MusicPopup from './music-popup';
import useSound from 'use-sound';
import boopSfx from '../music.mp3';
import { useTheme } from '@mui/material/styles';
import { DarkModeSwitch } from "react-toggle-dark-mode";
import Tooltip from '@mui/material/Tooltip';
import jwt_decode from 'jwt-decode';
import axios from 'axios'
import { Link } from 'react-router-dom'



const CharacterSidebar = ({ user, activeView, handleFStyle, handleSizeClick, handleStyleClick, handleFSizeS, handleFSizeM, handleFSizeL, bookmark, handleChapterClick, jumpToPage, toggleDarkMode, darkSide }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        const { currentTarget } = event;
        setAnchorEl(prevAnchor => prevAnchor ? null : currentTarget);
        console.log(anchorEl);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    const notelistClose = () => {
        setAnchorEl(open => !open)
    }


    const [profileEl, setProfileEl] = React.useState(null);

    const handleProfileClick = (event) => {
        const { currentTarget } = event;
        setProfileEl(prevProfile => prevProfile ? null : currentTarget);
        console.log(profileEl);
    };

    const openProfile = Boolean(profileEl);
    const Profile_id = openProfile ? 'simple-popper' : undefined;

    const profileClose = () => {
        setProfileEl(openProfilel => !openProfile)
    }

    const handleSaveClick = () => {
        if (noteText.trim().length > 0) {
            addNote(noteText);
            setNoteText('');
        }
    }

    const [addNoteEl, setAddNoteEl] = React.useState(null);

    const handleAddNoteClick = (event) => {
        setAddNoteEl(addNoteEl ? null : event.currentTarget);
    };

    const openAddNote = Boolean(addNoteEl);

    const [noteText, setNoteText] = useState('');

    const handleNoteChange = (event) => {
        setNoteText(event.target.value);
    }

    const addNoteClose = () => {
        setAddNoteEl(openAddNote => !openAddNote)
    }


    
    const [fontSize, setFontSize] = useState(
        localStorage.getItem('fontSize') || '24px'
    );

    const [fontStyle, setFontStyle] = useState(
        localStorage.getItem('fontStyle') || 'DM Mono'
    );




    const [fontStyleEl, setFontStyleEl] = React.useState(null);

    const handleFontStyleClick = (event) => {

        setFontStyleEl(fontStyleEl ? null : event.currentTarget);
    };

    const openFontStyle = Boolean(fontStyleEl);

    const fontStyleClose = () => {
        setFontStyleEl(openAddNote => !openAddNote)
    }


    const [fontSizeEl, setFontSizeEl] = React.useState(null);

    const handleFontSizeClick = (event) => {

        setFontSizeEl(fontSizeEl ? null : event.currentTarget);
    };

    const openFontSize = Boolean(fontSizeEl);

    const fontSizeClose = () => {
        setFontSizeEl(openAddNote => !openAddNote)
    }



    // const [musicEl, setMusicEl] = React.useState(null);

    // const handleMusicClick = (event) => {
    //   setMusicEl(musicEl ? null : event.currentTarget);
    // };

    // const openMusic = Boolean(musicEl);
    // const musicId = openMusic ? 'simple-popper' : undefined;


    const [notes, setNotes] = useState([
        {
            id: nanoid(),
            pagenumber: 'CHI/P1',
            text: 'Lorem ipsum dolor sit amet consectetur. Viverra cursus semper pulvinar at est.',
        },
        {
            id: nanoid(),
            pagenumber: 'CHI/P2',
            text: 'Lorem ipsum consectetur. Viverra cursus semper pulvinar at est.',
        },
        {
            id: nanoid(),
            pagenumber: 'CHI/P3',
            text: 'Lorem ipsum dolor sit amet consectetur. semper pulvinar at est.',
        },
        {
            id: nanoid(),
            pagenumber: 'CHI/P4',
            text: 'Lorem ipsum dolor sit amet consectetur. Viverra cursus semper pulvinar at est.',
        },
        {
            id: nanoid(),
            pagenumber: 'CHI/P5',
            text: 'Lorem ipsum dolor sit amet consectetur. Viverra cursus semper pulvinar at est.',
        },
    ])

    const addNote = (text) => {
        const newNote = {
            id: nanoid(),
            pagenumber: 'CHI/P6',
            text: text
        }
        // const newNotes = [...notes, newNote];
        setNotes(prevNotes => [...prevNotes, newNote]);
    }

    const deleteNote = (id) => {
        // const newNotes = notes.filter((note) => note.id !== id);
        setNotes(prevNotes => prevNotes.filter((note) => note.id !== id));
    }

    const charDetails = []
    const characterSet = new Set()
    CharacterData.forEach(
        (o, i) => {
            if ( o.character_name !== undefined && !characterSet.has(o.character_name)) {
                characterSet.add(o.character_name)
                charDetails.push(
                    {
                        ...(o.character_name && {character_name: o.character_name}),
                        number: o.number,
                        ...(o.image && {image: o.image}),
                        ...(o.page_number && {character_start_page: o.page_number}),
                        id: o.id
                    }
                )
            }
        }
    );


    const buttonClasses = classNames({
        "flex items-center justify-center bg-[#EEEEEE] active:bg-[#242424] rounded-md hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1] ease-linear transition-all duration-150 dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]": true,
        "bg-black dark:bg-[#FAF8F1]": open,
    })

    const addNoteClasses = classNames({
        "flex items-center justify-center bg-[#EEEEEE] w-full rounded-md hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1] ease-linear transition-all duration-150 dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]": true,
        "bg-black dark:bg-[#FAF8F1]": openAddNote,
    })

    const fontStyleClasses = classNames({
        "flex items-center justify-center bg-[#EEEEEE] rounded-md hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1] outline-none ease-linear transition-all duration-150 dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]": true,
        "bg-black dark:bg-[#FAF8F1]": openFontStyle,
    })

    const fontSizeClasses = classNames({
        "flex items-center justify-center bg-[#EEEEEE] rounded-md hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1] outline-none ease-linear transition-all duration-150 dark:bg-[#242424] dark:border-[1px] dark:border-[#303030]": true,
        "bg-black dark:bg-[#FAF8F1]": openFontSize,
    })


    const [play, { stop }] = useSound(boopSfx);
    const [volume, setVolume] = useState(30);

    const handlePlay = () => {
        play();
    }

    const handlePause = () => {
        stop();
    }

    const handleVolumeChange = (event, newValue) => {
        setVolume(newValue);
    };

    const theme = useTheme();
    const duration = 200; // seconds
    const [position, setPosition] = React.useState(32);
    const [paused, setPaused] = React.useState(true);
    // function formatDuration(volume) {
    //     const minute = Math.floor(value / 60);
    //     const secondLeft = value - minute * 60;
    //     return `${minute}:${secondLeft < 10 ? `0${secondLeft}` : secondLeft}`;
    // }
    // const mainIconColor = theme.palette.mode === 'dark' ? '#fff' : '#000';
    const mainIconColor = '#fff';
    const lightIconColor =
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,0.4)' : 'rgba(0,0,0,0.4)';




    const [error, setError] = useState("")
    const [userId, setUserId] = useState('');

    useEffect(() => {
        const token = window.localStorage.getItem('token');
        if (!token) return;
    
        try {
        const decodedToken = jwt_decode(token);
        const userId = decodedToken._id;
        setUserId(userId);
        console.log(userId);
        } catch (err) {
        console.error(err);
        }
    }, []);


    const [userData, setUserData] = useState([])

    useEffect(() => {
        if(userId) {
        const fetchData = async () => {
            let tokenStr = window.localStorage.getItem('token');
            try {
            console.log(userId);
            const response = await axios.get(`/api/users/${userId}`, {
                headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${tokenStr}`
                }
            });
            console.log(response.data);
            setUserData(response.data);
            } catch (error) {
            if (error.response &&
                error.response.status >= 400 &&
                error.response.status <= 500
            ) {
                setError(error.response.data.message)
            }
            }
        };
        fetchData();
        }
    }, [userId])


    const handleLogout = () => {
        localStorage.removeItem('token');
        window.location.reload();
      }


    return (
        <main className="lg:border-[1px] lg:border-[#CBCAC7] lg:p-4 lg:w-72 lg:h-[89vh] lg:mt-4 lg:sticky lg:top-20 dark:border-[#303030]">
            <div className="flex flex-col justify-between lg:h-[85vh]">

                <div>
                    <div className="flex items-center justify-between mb-4 mt-4 lg:px-4">
                        {/* <img src="vector.svg"/> */}
                        <p className='font-pusab font-normal text-[11px] text-[#242424] dark:text-[#FAF8F1]'>CHARACTERS</p>
                    </div>

                    {/* Character Buttons */}
                    {/* <div className='grid grid-cols-3 gap-2 flex-wrap' >
                        {
                            CharacterData.map(characterdata => {
                                return (

                                    <div className='flex flex-col items-center bg-[#eee] rounded-lg p-[7px]'>
                                        <img className='border-[1px] border-[#242424] mb-1 rounded-lg' src={characterdata.image} />
                                        <p className='font-dm_mono font-medium text-[12px] text-[#242424] align-center' >{characterdata.character_name}</p>
                                    </div>

                                );
                            })}
                    </div> */}

                    <div className='grid grid-cols-3 gap-4 flex-wrap lg:gap-2' >
                        {charDetails.map((character, i) => {
                            const isCharacterSelected = character?.character_start_page === jumpToPage;
                            return (
                                <Fragment key={character.character_name}>
                                    <button id={character.id} className={classNames({
                                        "flex flex-col items-center rounded-lg p-[10px] bg-[#eee] lg:p-[7px] lg:border-none dark:bg-[#303030] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:bg-[#cbcbcb] hover:dark:bg-[#515151]": !isCharacterSelected,
                                        "flex flex-col items-center bg-[#242424] rounded-lg p-[10px] dark:bg-[#515151]": isCharacterSelected
                                    })} onClick={() => { handleChapterClick(character?.character_start_page) }}>

                                        <img className='border-[1px] border-[#242424] mb-1 rounded-lg h-[82px] lg:h-full' src={character.image} />
                                        <p className={classNames({
                                            "font-dm_mono font-medium text-[12px] text-[#eee] align-center dark:text-[#FAF8F1]": isCharacterSelected,
                                            "font-dm_mono font-medium text-[12px] text-[#242424] align-center dark:text-[#FAF8F1]": !isCharacterSelected
                                        })} >
                                            {character.character_name}
                                        </p>
                                    </button>

                                    {/* <div className='flex items-center flex-wrap'>
                                    {isCharacterSelected && bookmark.map((b) => {
                                        if (b.chapter_number === character.chapter_number) {
                                            return (
                                                <div className='flex items-center border-[1px] border-[#CBCAC7] rounded p-1 pr-2 mt-2 mr-2'>
                                                    <img className='ml-2' src='bookmark.svg' />
                                                    <p className='ml-2 text-[11px] font-normal font-dm_mono text-black opacity-50'>Page {b.page_number}</p>
                                                </div>
                                            )
                                        }
                                        return null;
                                    })}
                                </div> */}
                                </Fragment>
                            )
                        })}
                    </div>

                </div>

                <div className='hidden lg:grid'>

                    {/* <div className='grid grid-cols-4 gap-2 px-2'>
                        <div className='col-span-3 flex items-center justify-between grow'>
                            <button
                                className={addNoteClasses}
                                type="button"
                                onClick={handleAddNoteClick}
                            >

                                <p className="font-dm_mono font-medium text-[14px] text-[#909090] py-[8px]">Write a note</p>

                            </button>

                            <Popper open={openAddNote} anchorEl={addNoteEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute', left: '30px !important', width: 'auto' }}>
                                <Box className='px-4 py-4 mb-5 rounded-lg shadow-lg relative bg-white border-[1px] border-[#242424] dark:bg-[#282828] dark:border-[#FAF8F1]' >
                                    <div >
                                        <textarea className="font-dm_mono text-[#242424] text-[14px] font-normal w-[225px] h-[120px] focus:outline-none resize-none mb-2 dark:bg-[#282828] dark:text-[#FAF8F1]" placeholder="Take a note..."
                                            value={noteText} onChange={handleNoteChange}
                                        >
                                        </textarea>
                                        <div className="flex justify-end mb-1 absolute bottom-0 right-1">
                                            <Tooltip title="Save" placement="top-end">
                                                <button onClick={handleSaveClick} >
                                                    <img src={darkSide ? "white_save-icon.svg" : "save-icon.svg"} />
                                                </button>
                                            </Tooltip>
                                        </div>

                                        <button
                                            className="absolute top-2 right-2 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={addNoteClose}
                                        >
                                            <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                                        </button>

                                    </div>
                                </Box>
                            </Popper>
                        </div>

                        <button
                            className={buttonClasses}
                            type="button" onClick={handleClick}
                        >
                            <img className="h-5" src={open ? darkSide ? "search-note.svg" : "white_notelist.svg" : darkSide ? "white_notelist.svg" : "search-note.svg"} />
                        </button>

                        <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute', left: '0 !important', right: '0 !important', marginLeft: 'auto !important', marginRight: 'auto !important', width: '988px' }}>
                            <Box className='pt-8 pb-10 mb-5 rounded-lg shadow-lg relative w-full bg-white border-[1px] border-[#242424] dark:bg-[#282828] dark:border-[#FAF8F1]' >
                                <p className="font-dm_mono text-[16px] text-[#242424] font-medium px-6 mb-4 dark:text-[#FAF8F1]">
                                    Notes Directory
                                </p>
                                <div className="relative px-6 flex-auto">
                                    <div className='grid grid-cols-3 gap-4'>
                                        {notes.map((note) => <Note key={note.id} id={note.id} pg={note.pagenumber} text={note.text} handleDeleteNote={deleteNote} darkSide={darkSide} />)}
                                    </div>
                                </div>
                                <button
                                    className="absolute top-2 right-2 ease-linear transition-all duration-150"
                                    type="button"
                                    onClick={notelistClose}
                                >
                                    <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                                </button>
                            </Box>
                        </Popper>
                    </div> */}

                    {/* functionality icons  */}
                    <div className="mt-4 grid grid-cols-4 gap-2 px-2">
                        <div className='flex items-center justify-center bg-[#eee] p-2 rounded-md dark:bg-[#242424] dark:border-[1px] dark:border-[#303030] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1]' >
                            <DarkModeSwitch
                                checked={darkSide}
                                onChange={toggleDarkMode}
                            >
                                {/* <img className="h-4" src="dark-mode.svg" /> */}
                            </DarkModeSwitch>
                        </div>



                        {/* <img className="px-4 py-3" src="music.svg" alt="" /> */}
                        {/* <MusicPopup /> */}

                        <button className='flex items-center justify-center bg-[#eeeeee] rounded-md dark:bg-[#242424] dark:border-[1px] dark:border-[#303030] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:border-[1px] hover:border-[#515151] hover:dark:border-[#b1b1b1]'
                            aria-label={paused ? 'play' : 'pause'}
                            onClick={() => setPaused(!paused)}
                        >
                            {paused ? (
                                <div onClick={handlePlay} className='cursor-pointer' >
                                    <img src={darkSide ? "white_music_pause.svg" : "music_pause.svg"} />
                                </div>
                            ) : (
                                // <PauseRounded onClick={handlePause} sx={{ fontSize: '1.5rem' }} htmlColor={mainIconColor} />
                                <div onClick={handlePause} className='cursor-pointer' >
                                    <img src={darkSide ? "white_music_play.svg" : "music_play.svg"} />
                                </div>
                            )}
                        </button>

                        <button
                            className={fontStyleClasses}
                            type="button"
                            disabled={activeView === VIEWS.horizontal}
                            onClick={handleFontStyleClick}
                        >
                            {activeView === VIEWS.horizontal ? <img src={darkSide ? "black_blocked_fontstyle.svg" : "blocked_fontstyle.svg"} /> :
                                <img className="h-[13px]" src={openFontStyle ? darkSide ? "fontstyle.svg" : "white_fontstyle.svg" : darkSide ? "white_fontstyle.svg" : "fontstyle.svg"} />
                            }
                        </button>

                        <Popper open={openFontStyle} anchorEl={fontStyleEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute', left: '-40px !important', width: 'auto' }}>
                            <Box >
                                <div className="relative mb-6 w-[265px] lg:ml-8 lg:mr-4 lg:w-[265px] xl:ml-8 xl:mr-4">
                                    {/*content*/}
                                    <div className="rounded-lg shadow-lg relative w-full bg-white border-[1px] border-[#242424] outline-none focus:outline-none dark:bg-[#303030] dark:border-[#515151]">
                                        {/*header*/}
                                        <div className="px-5 pt-5 pb-3 rounded-t">
                                            <p className="font-dm_mono text-[14px] text-[#242424] font-medium dark:text-[#FAF8F1]">
                                                Change Font Style
                                            </p>
                                        </div>
                                        {/*body*/}
                                        <div className="relative px-5 pb-5 flex-auto">
                                        <div className='flex items-center justify-between'>
                                            <button
                                                onClick={() => handleStyleClick('DM Mono')}
                                                // className={classnames('bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md', fontSize==='DM Mono' && 'bg-[#242424] text-[#eee]' )}
                                                className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center mr-2 rounded py-1 grow dark:bg-[#242424] dark:border-[#303030] dark:text-[#FAF8F1] active:text-[#eee] active:bg-[#000] dark:active:text-[#242424] dark:active:bg-[#FAF8F1] focus:text-[#eee] focus:bg-[#000] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:bg-[#cbcbcb] hover:dark:bg-[#515151]'
                                                >
                                            FS 1
                                            </button>
                                            <button
                                                onClick={() => handleStyleClick('Merriweather')}
                                                className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center mr-2 rounded py-1 grow dark:bg-[#242424] dark:border-[#303030] dark:text-[#FAF8F1] active:text-[#eee] active:bg-[#000] dark:active:text-[#242424] dark:active:bg-[#FAF8F1] focus:text-[#eee] focus:bg-[#000] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:bg-[#cbcbcb] hover:dark:bg-[#515151]'
                                                >
                                                FS 2
                                            </button>
                                            <button
                                                onClick={() => handleStyleClick('Work Sans')}
                                                className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center rounded py-1 grow dark:bg-[#242424] dark:border-[#303030] dark:text-[#FAF8F1] active:text-[#eee] active:bg-[#000] dark:active:text-[#242424] dark:active:bg-[#FAF8F1] focus:text-[#eee] focus:bg-[#000] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:bg-[#cbcbcb] hover:dark:bg-[#515151]'
                                                >
                                                FS 3
                                            </button>
                                        </div>
                                        </div>
                                        {/*footer*/}
                                        <button
                                            className="absolute top-2 right-2 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={fontStyleClose}
                                        >
                                            <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                                        </button>
                                    </div>
                                </div>
                            </Box>
                        </Popper>


                        <button
                            className={fontSizeClasses}
                            type="button"
                            disabled={activeView === VIEWS.horizontal}
                            onClick={handleFontSizeClick}
                        >
                            {activeView === VIEWS.horizontal ? <img src={darkSide ? "black_blocked_fontsize.svg" : "blocked_fontsize.svg"} /> :
                                <img className="h-[13px]" src={openFontSize ? darkSide ? "font-size.svg" : "white_fontsize.svg" : darkSide ? "white_fontsize.svg" : "font-size.svg"} />
                            }                        </button>

                        <Popper open={openFontSize} anchorEl={fontSizeEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, position: 'absolute', left: '-100px !important', width: 'auto' }}>
                            <Box >
                                <div className="relative mb-6 w-[265px] lg:ml-8 lg:mr-4 lg:w-[265px] xl:ml-8 xl:mr-4">
                                    {/*content*/}
                                    <div className="rounded-lg shadow-lg relative w-full bg-white border-[1px] border-[#242424] outline-none focus:outline-none dark:bg-[#303030] dark:border-[#515151]">
                                        {/*header*/}
                                        <div className="px-5 pt-5 pb-3 rounded-t">
                                            <p className="font-dm_mono text-[14px] text-[#242424] font-medium dark:text-[#FAF8F1]">
                                                Change Font Size
                                            </p>
                                        </div>
                                        {/*body*/}
                                        <div className="relative px-5 pb-5 flex-auto">
                                        <div className='flex items-center justify-between'>
                                            <button
                                                onClick={() => handleSizeClick('20px')}
                                                // className={classnames('bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md', fontSize==='20px' && 'bg-[#242424] text-[#eee]' )}
                                                className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center mr-2 rounded py-1 grow dark:bg-[#242424] dark:border-[#303030] dark:text-[#FAF8F1] active:text-[#eee] active:bg-[#000] dark:active:text-[#242424] dark:active:bg-[#FAF8F1] focus:text-[#eee] focus:bg-[#000] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:bg-[#cbcbcb] hover:dark:bg-[#515151]'
                                                >
                                                S
                                            </button>
                                            <button
                                                onClick={() => handleSizeClick('24px')}
                                                className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center mr-2 rounded py-1 grow dark:bg-[#242424] dark:border-[#303030] dark:text-[#FAF8F1] active:text-[#eee] active:bg-[#000] dark:active:text-[#242424] dark:active:bg-[#FAF8F1] focus:text-[#eee] focus:bg-[#000] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:bg-[#cbcbcb] hover:dark:bg-[#515151]'
                                                >
                                                M
                                            </button>
                                            <button
                                                onClick={() => handleSizeClick('28px')}
                                                className='font-dm_mono text-[16px] text-[#242424] font-medium bg-[#eee] border-[1px] text-center rounded py-1 grow dark:bg-[#242424] dark:border-[#303030] dark:text-[#FAF8F1] active:text-[#eee] active:bg-[#000] dark:active:text-[#242424] dark:active:bg-[#FAF8F1] focus:text-[#eee] focus:bg-[#000] dark:focus:text-[#242424] dark:focus:bg-[#FAF8F1] hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:bg-[#cbcbcb] hover:dark:bg-[#515151]'
                                                >
                                                L
                                            </button>
                                        </div>
                                        </div>
                                        {/*footer*/}
                                        <button
                                            className="absolute top-2 right-2 ease-linear transition-all duration-150"
                                            type="button"
                                            onClick={fontSizeClose}
                                        >
                                            <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                                        </button>
                                    </div>
                                </div>
                            </Box>
                        </Popper>


                        {/* <FontStyleBtn handleFStyle={handleFStyle} disabled={activeView === VIEWS.horizontal} darkSide={darkSide} /> */}

                        {/* <FontSizeBtn handleFSizeS={handleFSizeS} handleFSizeM={handleFSizeM} handleFSizeL={handleFSizeL} disabled={activeView === VIEWS.horizontal} darkSide={darkSide} /> */}
                    </div>


                    {/* user profile */}
                    <div className="flex items-center justify-between mt-4 px-2">
                        <div className="flex items-center">
                            <div className="rounded-full">
                                <img src='userprofile.png' />
                            </div>
                            {user ? 
                            <p className="capitalize font-medium font-dm_mono text-[#242424] text-[15px] ml-4 dark:text-[#FAF8F1]">{userData.name}</p>
                            :   
                            <p className="capitalize font-medium font-dm_mono text-[#242424] text-[15px] ml-4 dark:text-[#FAF8F1]">Guest User</p> 
                            }

                        </div>

                        <div>
                        <button className='h-[30px] w-[30px]' type="button" onClick={handleProfileClick}>
                            <img className='h-[5px]' src={darkSide ? "dark_union.svg" : "Union.svg"} />
                        </button>

                            <Popper open={openProfile} anchorEl={profileEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1, left: '-113px !important', marginBottom: '14px !important', width: 'auto' }}>
                                {/* <ClickAwayListener onClickAway={() => setAnchorEl(false)}> */}
                                <Box className='px-6 pt-8 pb-4 mb-5 rounded-lg shadow-lg relative w-full bg-white border-[1px] border-[#242424] dark:bg-[#303030] dark:border-[#515151]' >
                                    {user ?
                                        <button className='flex w-full items-center px-6 py-2 bg-[#eeeeee] rounded-md dark:bg-[#242424] hover:bg-[#cbcbcb] hover:dark:bg-[#515151] ease-linear transition-all duration-150' >
                                            <img src={darkSide ? "white_signout.svg" : "signout.svg"} />
                                            <p onClick={handleLogout} className='font-dm_mono font-medium text-[14px] text-[#242424] ml-4 mr-14 dark:text-[#FAF8F1]' >Sign Out</p>
                                        </button> :
                                        <Link to='/login'>
                                            <button className='flex items-center px-6 py-2 bg-[#eeeeee] rounded-md dark:bg-[#242424] hover:bg-[#cbcbcb] hover:dark:bg-[#515151] ease-linear transition-all duration-150' >
                                            <img src={darkSide ? "white_login.svg" : "login.svg"} />
                                                <p className='font-dm_mono font-medium text-[14px] text-[#242424] ml-4 dark:text-[#FAF8F1]' >Login with email</p>
                                            </button>
                                        </Link>
                                    
                                    }
                                    <button className='w-full'>
                                        <p className='font-dm_mono font-medium text-[11px] text-[#909090] mx-auto underline text-center mt-4' >Terms and Privacy</p>
                                    </button>
                                    <button
                                        className="absolute top-2 right-2 ease-linear transition-all duration-150"
                                        type="button"
                                        onClick={profileClose}
                                    >
                                        <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                                    </button>
                                </Box>
                                {/* </ClickAwayListener> */}
                            </Popper>
                        </div>
                    </div>
                </div>

            </div>
        </main>
    );
};

export default CharacterSidebar;