import React from 'react';
import MNavbar from './components/setting/main-navbar.jsx';
import SignUpSide from './components/loginPage/signup.jsx';
import ExplorerDrawer from './components/mobile/explorer-drawer.jsx';

const SignupPage = () => {

    const [isExploreOpen, setIsExploreOpen] = React.useState(false)

    const toggleExploreDrawer = () => {
        setIsExploreOpen((prevState) => !prevState)
    }
    return (
        <div className='bg-[#faf8f1] h-full bg-cover lg:h-[100vh]' style={ { backgroundImage: "url('main-bg-img2.png')" } }>
            <div className='h-full flex flex-col justify-between max-w-[1600px] lg:h-[100vh] lg:pt-4 lg:w-[97%] lg:mx-auto'>
                <div>
                    <MNavbar toggleExploreDrawer={ toggleExploreDrawer } />
                    <ExplorerDrawer open={ isExploreOpen } onClose={ toggleExploreDrawer } />

                    {/* <Drawer open={ isExploreOpen } onClose={ toggleExploreDrawer } direction='top' size='600' className='z-[11] border-b-[2px] border-[#242424] !bg-[#FAF8F1]'>
                        <div className='w-full h-[1px] bg-[#E0DED8] mt-16'></div>
                        <Box className="grid grid-cols-2 gap-4 bg-[#FAF8F1] px-4 py-8">
                            <a href='https://medium.com/@ChimpersNFT' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="news.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424]" >NEWS/INFO</p>
                                    </div>
                                </button>
                            </a>
                            <a href='https://marketplace.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="marketplace.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >MARKETPLACE</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://dojo.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="dojo.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >DOJO</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://www.chimpers.xyz/leaderboard' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="leaderboard.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >LEADERBOARD</p>

                                    </div>
                                </button>
                            </a>
                            <a href='https://arcade.chimpers.xyz/' target='_blank'>
                                <button className="w-full flex bg-white border-[1px] border-[#cbcbcb] rounded-md overflow-hidden" >
                                    <img className="h-[45px] p-2" src="game.png" />
                                    <div className="pl-3 pt-4 pb-3 h-full border-l-[1px] border-[#cbcbcb] focus:border-l-[1px] focus:border-[#242424]" >
                                        <p className="font-dm_mono font-medium text-[14px] text-[#242424] mb-1" >GAMES</p>

                                    </div>
                                </button>
                            </a>
                        </Box>

                        <div className='grid grid-cols-3 border-t-[1px] border-[#E0DED8]' >
                            <a className='mx-auto' href='https://opensea.io/collection/chimpersnft' target='_blank'>
                                <button className='py-4 mx-auto'>
                                    <img className='' src="char_opensea.svg" />
                                </button>
                            </a>
                            <a href='https://discord.com/invite/chimpers' target='_blank'>
                                <button className='h-full w-full py-4 border-l-[1px] border-r-[1px] border-[#E0DED8]'>
                                    <img className='mx-auto' src="char_discord.svg" />
                                </button>
                            </a>
                            <a className='mx-auto' href='https://twitter.com/ChimpersNFT' target='_blank'>
                                <button className='py-4 mx-auto'>
                                    <img className='' src="char_twitter.svg" />
                                </button>
                            </a>
                        </div>
                    </Drawer> */}

                    <div className='hidden w-[96%] mx-auto grid grid-cols-2 gap-28 lg:grid'>
                        <div>
                            <SignUpSide />
                        </div>
                        <div className='relative overflow-hidden border-[1px] border-[#242424]'>
                            <img className='object-cover h-full w-full shadow-[0_15px_45px_-24px_rgb(0,0,0,0.3)] hover:scale-110 ease-linear transition-all duration-150' src='login-img.png' />
                        </div>
                    </div>


                    {/* mobile */ }
                    <div className='ml-3 mr-3 flex flex-col md:ml-5 md:mr-5 lg:hidden'>
                        <div>
                            <img className='object-cover h-full w-full md:h-[60vh] lg:h-full 2xl:h-[95%]' src='login-img.png' />
                        </div>
                        <div>
                            <SignUpSide />
                        </div>
                    </div>
                </div>


                <div className='w-[97%] pt-8 mx-auto pb-6 flex items-end gap-2 md:mt-10 lg:hidden'>
                    <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
                    <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
                </div>
            </div>
        </div>
    );
};

export default SignupPage;