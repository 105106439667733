import React, { useState, useEffect } from "react";
import axios from 'axios';
import classnames from "classnames";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { Link, useNavigate } from 'react-router-dom';



const Settings = ({ handleFSizeS, handleFSizeM, handleFSizeL, handleFStyle, toggleDarkMode, darkSide, handleButtonClick, handleClick, handleStyleClick, handleMobSizeClick }) => {


    const [fontSize, setFontSize] = useState(
        localStorage.getItem('fontSize') || '24px'
    );

    const [mobFontSize, setMobFontSize] = useState(
        localStorage.getItem('mobile-fontSize') || '20px'
    );

    const [fontStyle, setFontStyle] = useState(
        localStorage.getItem('fontStyle') || 'DM Mono'
    );
    
      

    // const [fontSize, setFontSize] = useState('24');
    const [error, setError] = useState("")


    // const handleFontChange = async (fontSize) => {
    //     let tokenStr = window.localStorage.getItem('token');
    //     try {
    //         const response = await axios.post('/api/fontsize', { fontSize }, {
    //         headers: {
    //             "Content-Type": "application/json",
    //             "Authorization": `Bearer ${tokenStr}`
    //         }
    //         })
    //         setFontSize('');
    //         console.log(response.message);
    //     } catch (error) {
    //         if (error.response &&
    //         error.response.status >= 400 &&
    //         error.response.status <= 500
    //         ) {
    //         setError(error.response.data.message)
    //         }
    //     }
    // }

    //   const selected = classnames({'border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md bg-[#242424] text-[#eee]': })

    return (
        <div className='flex flex-col h-full justify-between'>
            <div className='mt-12 lg:mt-16 lg:ml-12'>
                <Link to='/login'>
                    <div className="hover:-translate-x-2 transition-all duration-150 ease-linear">
                        <button>
                            <img className='hidden absolute -mt-16 -ml-12 lg:block' src="back-btn.svg" />
                        </button>
                    </div>
                </Link>   

                <p className='hidden font-pusab mt-12 mb-7 text-[#242424] text-[32px] lg:block'>SET YOUR VIBE</p>
                <p className='hidden mb-14 font-dm_mono font-medium text-[14px] text-[#242424] lg:block'>Choose your preferred reading styles below!</p>

                <div className='hidden flex items-center flex-wrap lg:flex'>
                    <div className='border-[1px] border-[#242424] bg-white p-4 lg:p-8 lg:w-60 lg:pl-10 lg:pr-10'>
                        <p className='mb-4 font-dm_mono font-medium text-[15px] text-[#242424]'>FONT SIZE</p>
                        <div className='grid grid-cols-3 gap-2'>

                        {/* {Object.keys(FONT_SIZES).map(font => (
                            <button
                            key={font}
                            onClick={() => setSelectedFont(font)}
                            style={{ fontSize: font }}
                            className='border-[#e2e2e2] border-[1px] bg-[#fbfbfb] font-dm_mono font-medium text-[15px] text-[#242424] text-center pt-3 pb-3 pl-4 pr-4 rounded-md focus:bg-[#242424] focus:text-[#eee]'
                            >
                            {font}
                            </button>
                        ))} */}


                            <button
                                onClick={() => handleClick('20px')}
                                // className={classnames('bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md', fontSize==='20px' && 'bg-[#242424] text-[#eee]' )}
                                className={fontSize === '20px' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md hover:bg-[#e1e1e1]'} 
                            >
                                S
                            </button>
                            <button
                                onClick={() => handleClick('24px')}
                                className={fontSize === '24px' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md hover:bg-[#e1e1e1]'} 
                            >
                                M
                            </button>
                            <button
                                onClick={() => handleClick('28px')}
                                className={fontSize === '28px' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md hover:bg-[#e1e1e1]'} 
                            >
                                L
                            </button>

                        </div>
                    </div>
                    <div className='border-[1px] border-[#242424] border-l-0 bg-white p-4 lg:p-8 lg:w-60 lg:pl-10 lg:pr-10'>
                        <p className='mb-4 font-dm_mono font-medium text-[15px] text-[#242424]'>FONT STYLE</p>
                        <div className='grid grid-cols-3 gap-2'>

                             <button
                                onClick={() => handleStyleClick('DM Mono')}
                                // className={classnames('bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md', fontSize==='DM Mono' && 'bg-[#242424] text-[#eee]' )}
                                className={fontStyle === 'DM Mono' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md hover:bg-[#e1e1e1]'} 
                            >
                               1
                            </button>
                            <button
                                onClick={() => handleStyleClick('Merriweather')}
                                className={fontStyle === 'Merriweather' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md hover:bg-[#e1e1e1]'} 
                            >
                                2
                            </button>
                            <button
                                onClick={() => handleStyleClick('Work Sans')}
                                className={fontStyle === 'Work Sans' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md hover:bg-[#e1e1e1]'} 
                            >
                                3
                            </button>

                        </div>
                    </div>
                    <div className='border-[1px] border-[#242424] border-l-0 bg-white p-4 lg:p-8 lg:w-60 lg:pl-10 lg:pr-10'>
                        <p className='mb-4 font-dm_mono font-medium text-[15px] text-[#242424]'>THEME</p>
                        <div className='flex items-center justify-between w-full border-[#e2e2e2] border-[1px] bg-[#fbfbfb] font-dm_mono font-medium text-[15px] text-[#242424] pt-3 pb-3 pl-3 pr-3 rounded-md dark:bg-[#242424] dark:border-[1px] dark:border-[#303030] dark:text-[#FAF8F1] hover:bg-[#e1e1e1]'>
                            <p>Light/Dark</p>
                            <DarkModeSwitch
                                checked={darkSide}
                                onChange={toggleDarkMode}
                            >
                            </DarkModeSwitch>
                        </div>
                    </div>
                </div>
                

                {/* mobile */}
                <div className='-mt-4 grid grid-cols-2 lg:hidden'>
                    <div className='border-[1px] border-[#242424] bg-white p-4'>
                        <p className='mb-4 font-dm_mono font-medium text-[15px] text-[#242424]'>FONT SIZE</p>
                        <div className='grid grid-cols-3 gap-2'>
                            <button
                                onClick={() => handleMobSizeClick('16px')}
                                // className={classnames('bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md', mobFontSize==='16px' && 'bg-[#242424] text-[#eee]' )}
                                className={mobFontSize === '16px' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md'} 
                            >
                                S
                            </button>
                            <button
                                onClick={() => handleMobSizeClick('20px')}
                                className={mobFontSize === '20px' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md'} 
                            >
                                M
                            </button>
                            <button
                                onClick={() => handleMobSizeClick('24px')}
                                className={mobFontSize === '24px' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md'} 
                            >
                                L
                            </button>
                        </div>
                    </div>
                    <div className='border-[1px] border-[#242424] border-l-0 bg-white p-4'>
                        <p className='mb-4 font-dm_mono font-medium text-[15px] text-[#242424]'>FONT STYLE</p>
                        <div className='grid grid-cols-3 gap-2'>
                        <button
                                onClick={() => handleStyleClick('DM Mono')}
                                // className={classnames('bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md', fontSize==='DM Mono' && 'bg-[#242424] text-[#eee]' )}
                                className={fontStyle === 'DM Mono' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md'} 
                            >
                               1
                            </button>
                            <button
                                onClick={() => handleStyleClick('Merriweather')}
                                className={fontStyle === 'Merriweather' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md'} 
                            >
                                2
                            </button>
                            <button
                                onClick={() => handleStyleClick('Work Sans')}
                                className={fontStyle === 'Work Sans' ? 'bg-[#242424] text-[#eee] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md' : 'bg-[#fbfbfb] text-[#242424] border-[#e2e2e2] border-[1px] font-dm_mono font-medium text-[15px] text-center pt-3 pb-3 pl-4 pr-4 rounded-md'} 
                            >
                                3
                            </button>
                        </div>
                    </div>
                    <div className='border-[1px] border-[#242424] border-t-0 bg-white p-4'>
                        <p className='mb-4 font-dm_mono font-medium text-[15px] text-[#242424]'>THEME</p>
                        <div className='flex items-center justify-between w-full border-[#e2e2e2] border-[1px] bg-[#fbfbfb] font-dm_mono font-medium text-[15px] text-[#242424] pt-3 pb-3 pl-3 pr-3 rounded-md dark:bg-[#242424] dark:border-[1px] dark:border-[#303030] dark:text-[#FAF8F1]  '>
                            <p>Light/Dark</p>
                            <DarkModeSwitch
                                checked={darkSide}
                                onChange={toggleDarkMode}
                            >
                            </DarkModeSwitch>
                        </div>
                    </div>
                </div>

                <Link to='/stories'>
                    <button className='py-3 px-9 bg-[#242424] mt-14 block text-center lg:inline-block hover:scale-x-125 transition-all duration-150 ease-linear'>
                        <p className='font-dm_mono font-medium text-[15px] text-white'>NEXT</p>
                    </button>
                </Link>
            </div>

            <div className='hidden mt-20 flex items-end gap-2 md:mt-10 lg:ml-12 lg:items-start lg:flex'>
                <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#242424] rounded-md lg:w-[110px]'></div>
                <div className='h-[4px] w-full bg-[#e2e2e2] rounded-md lg:w-[110px]'></div>
            </div>

        </div>
    );
};

export default Settings;