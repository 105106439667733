import * as React from 'react';
import s2characterData from '../../s2-characterdata.json';
import Box from '@mui/material/Box';
import Popper from '@mui/material/Popper';

export default function Characer1Popper({ match, darkSide }) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popper' : undefined;

    // const characterName = 'Yasuke'
    const charData = s2characterData.filter(el => match === el.character_name)

    const characterPopperClose = () => {
        setAnchorEl(open => !open)
    }

    return (
        <>
            <button className='cursor-pointer' >
                <span onClick={handleClick} className="underline">{match}</span>
            </button>


            <Popper open={open} anchorEl={anchorEl} sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}>
                <Box className="relative border-[1px] border-[#242424] rounded-lg bg-white my-[15px] mx-4 dark:bg-[#303030] dark:border-[#FAF8F1]">
                    {
                        charData.map(cd => {
                            return (
                                <>
                                    <div className="hidden w-[650px] relative py-6 px-6 flex-auto lg:block">
                                        <div className='grid grid-cols-7 gap-7'>
                                            <div className="col-span-3">
                                                <img className="h-full border-[1px] border-[#242424] rounded-md" src={cd.image} />
                                            </div>
                                            <div className="mt-5 col-span-4 flex flex-col justify-between">
                                                <div>
                                                    <div className='flex items-center justify-between'>
                                                        <p className="mb-2 font-pusab uppercase text-[#242424] text-[20px] dark:text-[#FAF8F1]">{cd.character_name}</p>
                                                        <a href={cd.os_link} target='_blank'>
                                                        <button className='border-[1px] border-[#e1e1e1] rounded-md p-3 dark:border-[#515151] hover:-translate-y-1 hover:border-[#242424] dark:hover:border-[#FAF8F1] transition-all ease-linear duration-150'>
                                                            <img className='hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:-translate-y-0.5 ease-linear transition-all duration-150' src={darkSide ? "white-opensea.svg" : "opensea.svg"} />
                                                        </button>
                                                        </a>
                                                    </div>
                                                    {/* <p className='font-medium font-dm_mono text-[16px] text-[#242424] dark:text-[#FAF8F1]' >Chimpers Genesis #{cd.number}</p> */}
                                                    <p className="mt-4 font-dm_mono font-normal text-[14px] text-[#242424] dark:text-[#FAF8F1]">{cd.description}</p>
                                                </div>
                                                {/* <div className="flex items-end gap-5">
                                                    <button>
                                                        <img className='hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:-translate-y-0.5 ease-linear transition-all duration-150' src={darkSide ? "white-discord.svg" : "discord.svg"} />
                                                    </button>
                                                    <button>
                                                        <img className='hover:drop-shadow-[0_10px_24px_rgba(0,0,0,0.03)] hover:-translate-y-0.5 ease-linear transition-all duration-150' src={darkSide ? "white-twitter.svg" : "twitter.svg"} />
                                                    </button>
                                                    <a href=''><img className=" mr-5" src={darkSide ? "white-opensea.svg" : "opensea.svg"} /></a>
                                                    <a href=''><img className=" mr-5" src={darkSide ? "white-discord.svg" : "discord.svg"} /></a>
                                                    <a href=''><img className="" src={darkSide ? "white-twitter.svg" : "twitter.svg"} /></a>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>


                                    {/* Mobile View */}
                                    <div className='w-full overflow-hidden lg:hidden dark:bg-[#282828]'>
                                        <div className='flex items-center gap-4 px-7 pt-6' >
                                            <div>
                                                <img className='h-[80px] w-[80px] border-[1px] border-[#242424] rounded-md' src={cd.image} />
                                            </div>
                                            <div className='flex items-center'>
                                                <p className='mb-1 font-pusab font-normal uppercase text-[20px] text-[#242424] dark:text-[#FAF8F1]' >{cd.character_name}</p>
                                                {/* <button className='ml-6 border-[1px] border-[#e1e1e1] rounded-md p-3 dark:border-[#515151]'> */}
                                                    {/* <img className='ml-8' src={darkSide ? "white_char_opensea.svg" : "char_opensea.svg"} /> */}
                                                {/* </button> */}
                                                {/* <p className='font-dm_mono font-medium text-[16px] text-[#242424] dark:text-[#FAF8F1]' >Chimpers Genesis #{cd.number}</p> */}
                                            </div>
                                        </div>
                                        <div className='border-b-[1px] border-[#CBCAC7] mt-5 mb-6' ></div>
                                        <div className='px-7 pb-6' >
                                            <p className='font-dm_mono font-light text-[16px] text-[#242424] leading-5 dark:text-[#FAF8F1]' >{cd.description}</p>
                                        </div>
                                        {/* <div className='border-b-[1px] border-[#CBCAC7] mt-6 mb-4' ></div> */}
                                        <a href={cd.os_link} target='_blank'>
                                            <div className='flex items-center justify-between px-7 mb-4'>
                                                <button className='bg-[#eee] mb-3 rounded-md px-14 py-2 dark:bg-[#515151]'>
                                                    <p className='font-dm_mono font-bold text-[14px] text-[#242424]'>Opensea</p>
                                                </button>
                                            </div>
                                        </a>
                                    </div>
                                </>
                            )
                        })
                    }

                    <button
                        className="absolute top-2 right-2 ease-linear transition-all duration-150"
                        type="button"
                        onClick={characterPopperClose}
                    >
                        <img src={darkSide ? "white_close-btn.svg" : "close-btn.svg"} />
                    </button>
                </Box>
            </Popper>
        </>
    );
}