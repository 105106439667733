import React from 'react'
import { Route, Routes, Navigate } from 'react-router-dom'
import Reader from './Reader'
import Login from './loginPage'
import SignUp from './SignupPage'
import ForgotPassword from './forgot-pwd-page'
import PasswordReset from './pwd-reset-page'
import Character from './Characters'
import Settings from './Setting'
import GuestLogin from './guest-login'
import Welcome from './welcomepage'
import Stories from './Stories'
import S2Reader from './s2-reader'

const App = () => {
  const user = localStorage.getItem("token");

  return (
    <Routes>
      <Route path='/reader' exact element={<Reader user={user} />} />
      <Route path='/s2-reader' exact element={<S2Reader user={user} />} />
      <Route path='/signup' exact element={<SignUp />} />
      <Route path='/login' exact element={<Login />} />
      <Route path='/forgot-password' exact element={<ForgotPassword />} />
      <Route path='/password-reset' exact element={<PasswordReset />} />
      <Route path='/' exact element={<Navigate replace to="/welcome" />} />
      <Route path='/characters' exact element={<Character user={user} />} />
      {user && <Route path='/Setting' exact element={<Settings />} />}

      <Route path='/guest-login' exact element={<GuestLogin />} />
      <Route path='/welcome' exact element={<Welcome />} />
      <Route path='/stories' exact element={<Stories />} />
    </Routes>
  )
}

export default App;